
export const CURRENT_VERSION = '1.10';

export const TOAST_TYPES = {
    DANGER: 'danger',
    WARN: 'warning',
    SUCCESS: 'success',
    INFO: 'info'
};

export const LOCAL_STORAGE_KEYS = {
    auth_token: 'dq_seal_cl_auth_token',
    auth_user: 'dq_seal_cl_auth_user',
    refresh_token: 'dq_seal_cl_refresh_token',
    user_email: 'dq_seal_cl_user_email'
}

export const USER_ACCESS_LEVEL = {
    NOT_APPLICABLE: 0,
    NO_ACCESS: 1,
    PARTIAL_ACCESS_ADMIN_PORTAL: 2,
    FULL_ACCESS_ADMIN_PORTAL: 3,
    FULL_ACCESS_ANALYTICS_PORTAL: 4,
    PARTIAL_ACCESS_ANALYTICS_PORTAL: 5,
};

export const ACL_ENABLE_ENDPOINTS = []
export const URL_PERMS_MAP = {}


export const APP_STATUS = {
    DRAFT: 0,
    WITHDRAWN: 1,
    REJECTED: 2,
    PENDING_PAYMENT: 10,
    PENDING_APPROVAL: 20,
    IN_PROGRESS_COLLECT_DATA: 30,
    HALTED_COLLECT_DATA: 40,
    COLLECTED_DATA: 45,
    IN_PROGRESS_PROCESS_DATA: 50,
    HALTED_PROCESS_DATA: 60,
    PENDING_CLEANING: 65,
    IN_PROGRESS_LEVEL_MAPPING: 67,
    PENDING_REVIEW_MAPPING: 70,
    PENDING_ANALYSIS: 75,
    PENDING_REVIEW_REPORT: 80,
    HALTED_REPORT: 90,
    COMPLETED: 100,

    READY_TO_CERTIFY: 105,
    ADD_ASSESS_REQ_RECEIVED: 110,
    REQ_REVIEWED_ON_HOLD: 115,
    REQ_REVIEWED_REJECTED: 116,
    REQ_REVIEWED_PAYMENT_PENDING: 120,
    PAYMENT_RECEIVED_VER_PENDING: 130, // assessments & licenses cost.
    PAYMENT_DECLINE_ON_HOLD: 135,
    PAYMENT_DECLINE_REJECTED: 136,
    PAYMENT_CONFIRMED: 138,
    PENDING_AUTO_ASSESS_GENERATION: 140,
    AUTO_ASSESS_GENERATION_IN_PROGRESS: 145,
    AUTO_ASSESS_GENERATION_HALTED: 150,
    AUTO_ASSESS_GENERATION_COMPLETED: 160, // in-progress review assessments
    PENDING_ASSESS_GENERATED_APPROVE: 162,
    PENDING_APPROVE_MODULES: 165,
    PENDING_PUBLISH_MODULES: 167,
    ASSESSMENTS_READY: 200,
}

