
// Function to group data by dqc_no
function groupByDqcNo(data) {
    return data.reduce((acc, item) => {
        const { dqc_no } = item;
        if (!acc.has(dqc_no)) {
            acc.set(dqc_no, []);
        }
        acc.get(dqc_no).push(item);
        return acc;
    }, new Map());
}


function groupByGsmNo(data) {
    return data.reduce((acc, item) => {
        const {gsm_no} = item;
        if (!acc.has(gsm_no))
        {
            acc.set(gsm_no, item);
    }
        return acc;
    }, new Map());
}

const data = [
    {
        "gsm_no": "DQ1.1",
        "dqc_no": "DQC01",
        "full_name": "Online Personas",
        "definition": "Develop and manage digital identities effectively to shape online and offline reputation responsibly.",
        "curricula_full_name": "Digital Citizen ID",
        "target_audience": "All"
    },
    {
        "gsm_no": "DQ1.2",
        "dqc_no": "DQC01",
        "full_name": "Digital Integrity",
        "definition": "Cultivate a healthy digital self-image and maintain congruent online and offline identities with integrity.",
        "curricula_full_name": "Digital Wellbeing",
        "target_audience": "All"
    },
    {
        "gsm_no": "DQ1.3",
        "dqc_no": "DQC01",
        "full_name": "Global Digital Citizenship",
        "definition": "Demonstrate global awareness and netiquette by adapting online communications with cultural sensitivity.",
        "curricula_full_name": "Digital Citizen ID",
        "target_audience": "All"
    },
    {
        "gsm_no": "DQ1.4",
        "dqc_no": "DQC01",
        "full_name": "Demystifying AI Tools",
        "definition": "Simplify AI concepts and address misconceptions by understanding its strengths and limitations.",
        "curricula_full_name": "AI Literacy",
        "target_audience": "Teenagers / Workforce"
    },
    {
        "gsm_no": "DQ2.1",
        "dqc_no": "DQC02",
        "full_name": "Health Check - Digital Use",
        "definition": "Maintain healthy digital habits by avoiding excessive screen time and illegal access to digital media.",
        "curricula_full_name": "Digital Citizen ID",
        "target_audience": "All"
    },
    {
        "gsm_no": "DQ2.2",
        "dqc_no": "DQC02",
        "full_name": "Health Check - Tech Addiction",
        "definition": "Recognize and prevent symptoms of technology addiction to maintain balanced digital use.",
        "curricula_full_name": "Digital Citizen ID",
        "target_audience": "All"
    },
    {
        "gsm_no": "DQ2.3",
        "dqc_no": "DQC02",
        "full_name": "Digital Use Effects",
        "definition": "Understand and mitigate the harmful effects of unbalanced digital use on health, relationships, and work performance.",
        "curricula_full_name": "Digital Citizen ID",
        "target_audience": "All"
    },
    {
        "gsm_no": "DQ2.4",
        "dqc_no": "DQC02",
        "full_name": "Knowledge of Multitasking",
        "definition": "Analyze how digital multitasking impacts mental and physical health and overall well-being.",
        "curricula_full_name": "Digital Citizen ID",
        "target_audience": "Teenagers / Workforce"
    },
    {
        "gsm_no": "DQ2.5",
        "dqc_no": "DQC02",
        "full_name": "Self-Regulated Time Management",
        "definition": "Apply self-regulation strategies to balance online and offline time for well-being.",
        "curricula_full_name": "Digital Citizen ID",
        "target_audience": "All"
    },
    {
        "gsm_no": "DQ2.6",
        "dqc_no": "DQC02",
        "full_name": "Attention and Technology",
        "definition": "Evaluate how technology companies exploit user attention and develop strategies to protect personal well-being.",
        "curricula_full_name": "Digital Citizen ID",
        "target_audience": "Teenagers / Workforce"
    },
    {
        "gsm_no": "DQ2.7",
        "dqc_no": "DQC02",
        "full_name": "Balanced Use of AI Tools",
        "definition": "Use AI tools in a balanced manner to avoid negative effects like addiction or inappropriate intimacy.",
        "curricula_full_name": "AI Literacy",
        "target_audience": "Teenagers / Workforce"
    },
    {
        "gsm_no": "DQ3.1",
        "dqc_no": "DQC03",
        "full_name": "Knowledge of Behavioral Cyber-Risks",
        "definition": "Identify and understand various behavioral cyber-risks, including their origins and impact.",
        "curricula_full_name": "Digital Citizen ID",
        "target_audience": "All"
    },
    {
        "gsm_no": "DQ3.2",
        "dqc_no": "DQC03",
        "full_name": "Skills to Deal with Behavioral Cyber-Risks",
        "definition": "Develop and apply skills to manage and resolve incidents of cyberbullying and other behavioral cyber-risks.",
        "curricula_full_name": "Digital Citizen ID",
        "target_audience": "All"
    },
    {
        "gsm_no": "DQ3.3",
        "dqc_no": "DQC03",
        "full_name": "Attitude Relating to Behavioral Cyber-Risks",
        "definition": "Foster a zero-tolerance attitude towards cyberbullying and other behavioral cyber-risks in all online interactions.",
        "curricula_full_name": "Digital Citizen ID",
        "target_audience": "All"
    },
    {
        "gsm_no": "DQ3.4",
        "dqc_no": "DQC03",
        "full_name": "Health Check - Behavioral Cyber-Risks",
        "definition": "Regularly assess and ensure non-involvement in harmful online behaviors like cyberbullying or harassment.",
        "curricula_full_name": "Digital Citizen ID",
        "target_audience": "All"
    },
    {
        "gsm_no": "DQ3.5",
        "dqc_no": "DQC03",
        "full_name": "Upstanding",
        "definition": "Promote kindness and upstanding behavior in online interactions to create a positive online culture.",
        "curricula_full_name": "Digital Citizen ID",
        "target_audience": "Teenagers / Workforce"
    },
    {
        "gsm_no": "DQ3.6",
        "dqc_no": "DQC03",
        "full_name": "Responsibility of Stakeholders Against Behavioral Cyber-Risks",
        "definition": "Understand and advocate for stakeholder responsibility in mitigating behavioral cyber-risks.",
        "curricula_full_name": "Digital Citizen ID",
        "target_audience": "Teenagers / Workforce"
    },
    {
        "gsm_no": "DQ3.7",
        "dqc_no": "DQC03",
        "full_name": "Understand Risk and Ethics of AI",
        "definition": "Identify and address ethical risks in AI usage, ensuring transparency and human oversight.",
        "curricula_full_name": "AI Literacy",
        "target_audience": "Teenagers / Workforce"
    },
    {
        "gsm_no": "DQ4.1",
        "dqc_no": "DQC04",
        "full_name": "Basic Personal Security",
        "definition": "Recognize and protect against cyber threats by applying security strategies to safeguard personal devices and data.",
        "curricula_full_name": "Cybersecurity 101",
        "target_audience": "All"
    },
    {
        "gsm_no": "DQ4.2",
        "dqc_no": "DQC04",
        "full_name": "Dealing with Cyber-Threats",
        "definition": "Develop skills to identify, avoid, and mitigate personal cyber-threats through effective strategies and tools.",
        "curricula_full_name": "Cybersecurity 101",
        "target_audience": "All"
    },
    {
        "gsm_no": "DQ4.3",
        "dqc_no": "DQC04",
        "full_name": "Health Check – Cyber Threats",
        "definition": "Maintain good digital practices and behaviors to protect against personal cyber-threats.",
        "curricula_full_name": "Cybersecurity 101",
        "target_audience": "All"
    },
    {
        "gsm_no": "DQ4.4",
        "dqc_no": "DQC04",
        "full_name": "Understanding IoT Risks",
        "definition": "Gain a comprehensive understanding of IoT devices and associated risks to ensure safe usage.",
        "curricula_full_name": "Cybersecurity 101",
        "target_audience": "Teenagers / Workforce"
    },
    {
        "gsm_no": "DQ4.5",
        "dqc_no": "DQC04",
        "full_name": "Dealing with IoT Risks",
        "definition": "Implement best practices to mitigate risks associated with IoT devices.",
        "curricula_full_name": "Cybersecurity 101",
        "target_audience": "Teenagers / Workforce"
    },
    {
        "gsm_no": "DQ5.1",
        "dqc_no": "DQC05",
        "full_name": "Online Empathy",
        "definition": "Demonstrate empathy in online interactions by being sensitive to others' feelings and needs.",
        "curricula_full_name": "Digital Wellbeing",
        "target_audience": "All"
    },
    {
        "gsm_no": "DQ5.3",
        "dqc_no": "DQC05",
        "full_name": "Perspective Taking",
        "definition": "Apply perspective-taking skills to foster understanding and respect in online interactions.",
        "curricula_full_name": "Digital Wellbeing",
        "target_audience": "All"
    },
    {
        "gsm_no": "DQ5.4",
        "dqc_no": "DQC05",
        "full_name": "Online Kindness",
        "definition": "Promote kindness and support for others in online environments while managing potential cyber-risks.",
        "curricula_full_name": "Digital Wellbeing",
        "target_audience": "All"
    },
    {
        "gsm_no": "DQ6.1",
        "dqc_no": "DQC06",
        "full_name": "Basic Digital Footprints",
        "definition": "Understand the creation, impact, and management of digital footprints in shaping online identity.",
        "curricula_full_name": "Digital Citizen ID",
        "target_audience": "All"
    },
    {
        "gsm_no": "DQ6.2",
        "dqc_no": "DQC06",
        "full_name": "Digital Footprints Impact",
        "definition": "Recognize the permanence and potential consequences of digital footprints on personal reputation.",
        "curricula_full_name": "Digital Citizen ID",
        "target_audience": "All"
    },
    {
        "gsm_no": "DQ6.3",
        "dqc_no": "DQC06",
        "full_name": "Controlling Digital Footprints",
        "definition": "Develop strategies to manage and improve digital footprints, avoiding negative emotional reactions online.",
        "curricula_full_name": "Digital Citizen ID",
        "target_audience": "Teenagers / Workforce"
    },
    {
        "gsm_no": "DQ6.4",
        "dqc_no": "DQC06",
        "full_name": "Caring About Digital Reputation",
        "definition": "Prioritize the cultivation of positive digital footprints to build a strong online reputation.",
        "curricula_full_name": "Digital Citizen ID",
        "target_audience": "Teenagers / Workforce"
    },
    {
        "gsm_no": "DQ6.5",
        "dqc_no": "DQC06",
        "full_name": "Digital Footprints Impact (Company)",
        "definition": "Understand the impact of digital activities on a company’s reputation and avoid risky online behavior.",
        "curricula_full_name": "Digital Citizen ID",
        "target_audience": "Workforce"
    },
    {
        "gsm_no": "DQ6.6",
        "dqc_no": "DQC06",
        "full_name": "Mitigating Social Media Crisis (Company)",
        "definition": "Identify and follow best practices to mitigate social media crises and protect company reputation.",
        "curricula_full_name": "Digital Citizen ID",
        "target_audience": "Workforce"
    },
    {
        "gsm_no": "DQ6.7",
        "dqc_no": "DQC06",
        "full_name": "Positive Digital Reputation (Company)",
        "definition": "Actively manage and support your company’s digital reputation, especially in challenging situations.",
        "curricula_full_name": "Digital Citizen ID",
        "target_audience": "Workforce"
    },
    {
        "gsm_no": "DQ7.1",
        "dqc_no": "DQC07",
        "full_name": "Searching Information",
        "definition": "Develop and apply effective search strategies to locate and retrieve relevant information online.",
        "curricula_full_name": "AI Literacy",
        "target_audience": "Teenagers / Workforce"
    },
    {
        "gsm_no": "DQ7.2",
        "dqc_no": "DQC07",
        "full_name": "Evaluating Information",
        "definition": "Critically evaluate the credibility, reliability, and relevance of digital information to discern bias and misinformation.",
        "curricula_full_name": "AI Literacy",
        "target_audience": "Teenagers / Workforce"
    },
    {
        "gsm_no": "DQ7.3",
        "dqc_no": "DQC07",
        "full_name": "Influence of Digital Information",
        "definition": "Understand how digital information shapes knowledge and public opinion, and prevent the spread of misinformation.",
        "curricula_full_name": "AI Literacy",
        "target_audience": "Teenagers / Workforce"
    },
    {
        "gsm_no": "DQ7.4",
        "dqc_no": "DQC07",
        "full_name": "Business Models of Digital Platforms",
        "definition": "Analyze the business models of digital platforms and their psychological impact on users.",
        "curricula_full_name": "AI Literacy",
        "target_audience": "Teenagers / Workforce"
    },
    {
        "gsm_no": "DQ8.1",
        "dqc_no": "DQC08",
        "full_name": "Personal Information",
        "definition": "Understand and protect personal information as a fundamental human right in digital environments.",
        "curricula_full_name": "Cybersecurity 101",
        "target_audience": "All"
    },
    {
        "gsm_no": "DQ8.2",
        "dqc_no": "DQC08",
        "full_name": "Privacy Protection",
        "definition": "Apply strategies and tools to safeguard personal and others' privacy in online interactions.",
        "curricula_full_name": "Cybersecurity 101",
        "target_audience": "All"
    },
    {
        "gsm_no": "DQ8.3",
        "dqc_no": "DQC08",
        "full_name": "Privacy Attitude",
        "definition": "Develop a proactive approach to protecting personal and others’ privacy, evaluating technology platforms critically.",
        "curricula_full_name": "Cybersecurity 101",
        "target_audience": "All"
    },
    {
        "gsm_no": "DQ8.4",
        "dqc_no": "DQC08",
        "full_name": "Health Check: Privacy",
        "definition": "Regularly assess and ensure the protection of personal information online while minimizing risky exposure.",
        "curricula_full_name": "Cybersecurity 101",
        "target_audience": "All"
    },
    {
        "gsm_no": "DQ8.5",
        "dqc_no": "DQC08",
        "full_name": "Confidential Information (Company)",
        "definition": "Understand and adhere to company policies regarding the handling and protection of confidential information.",
        "curricula_full_name": "Cybersecurity 101",
        "target_audience": "Workforce"
    },
    {
        "gsm_no": "DQ8.6",
        "dqc_no": "DQC08",
        "full_name": "Privacy and Society",
        "definition": "Analyze the trade-offs between public and private information and explain privacy concerns related to data collection.",
        "curricula_full_name": "Cybersecurity 101",
        "target_audience": "Teenagers / Workforce"
    },
    {
        "gsm_no": "DQ9.1",
        "dqc_no": "DQC09",
        "full_name": "Technology Trends and Adoption",
        "definition": "Stay informed about technological advancements and integrate them discerningly into daily life.",
        "curricula_full_name": "",
        "target_audience": ""
    },
    {
        "gsm_no": "DQ9.2",
        "dqc_no": "DQC09",
        "full_name": "Cognitive and Creative Processing",
        "definition": "Use digital tools to create knowledge and innovate, applying cognitive processing to resolve digital problems.",
        "curricula_full_name": "",
        "target_audience": ""
    },
    {
        "gsm_no": "DQ9.3",
        "dqc_no": "DQC09",
        "full_name": "Solving Real-World Problems",
        "definition": "Identify and solve complex real-world problems using computational methods and digital technologies.",
        "curricula_full_name": "",
        "target_audience": ""
    },
    {
        "gsm_no": "DQ9.4",
        "dqc_no": "DQC09",
        "full_name": "Life-Long Digital Learning",
        "definition": "Embrace continuous learning and self-motivation to solve problems and achieve goals in professional life.",
        "curricula_full_name": "",
        "target_audience": ""
    },
    {
        "gsm_no": "DQ9.5",
        "dqc_no": "DQC09",
        "full_name": "Research & Development",
        "definition": "Engage in research and development for technological advancement with appropriate investment and support.",
        "curricula_full_name": "",
        "target_audience": ""
    },
    {
        "gsm_no": "DQ10.1",
        "dqc_no": "DQC10",
        "full_name": "Technology and Well-Being",
        "definition": "Understand the impact of digital consumption on physical and mental health to ensure better well-being.",
        "curricula_full_name": "Digital Wellbeing",
        "target_audience": "All"
    },
    {
        "gsm_no": "DQ10.2",
        "dqc_no": "DQC10",
        "full_name": "Ergonomic Use of Technology",
        "definition": "Apply ergonomic principles to technology use for safe and comfortable work processes.",
        "curricula_full_name": "Digital Wellbeing",
        "target_audience": "All"
    },
    {
        "gsm_no": "DQ10.3",
        "dqc_no": "DQC10",
        "full_name": "Valuing Health Through Digital Use",
        "definition": "Actively self-regulate technology use to prioritize mental and physical health.",
        "curricula_full_name": "Digital Wellbeing",
        "target_audience": "All"
    },
    {
        "gsm_no": "DQ10.4",
        "dqc_no": "DQC10",
        "full_name": "Healthcare and social wellbeing",
        "definition": "Promote healthy digital practices to improve individual and social well-being.",
        "curricula_full_name": "Digital Wellbeing",
        "target_audience": "All"
    },
    {
        "gsm_no": "DQ10.5",
        "dqc_no": "DQC10",
        "full_name": "Mindful Digital Use",
        "definition": "Use technology mindfully and purposefully while prioritizing health, relationships, and real-life activities.",
        "curricula_full_name": "Digital Wellbeing",
        "target_audience": "All"
    },
    {
        "gsm_no": "DQ11.1",
        "dqc_no": "DQC11",
        "full_name": "Understanding Contact Cyber-Risks",
        "definition": "Identify and understand risky online contacts and their potential impact on well-being.",
        "curricula_full_name": "Digital Citizen ID",
        "target_audience": "All"
    },
    {
        "gsm_no": "DQ11.2",
        "dqc_no": "DQC11",
        "full_name": "Dealing with Risky Contact",
        "definition": "Develop strategies to manage and mitigate the impact of risky online contacts on well-being and safety.",
        "curricula_full_name": "Digital Citizen ID",
        "target_audience": "All"
    },
    {
        "gsm_no": "DQ11.3",
        "dqc_no": "DQC11",
        "full_name": "Health Check – Risky Contact",
        "definition": "Ensure non-involvement with risky online contacts such as grooming or extremism.",
        "curricula_full_name": "Digital Citizen ID",
        "target_audience": "All"
    },
    {
        "gsm_no": "DQ12.1",
        "dqc_no": "DQC12",
        "full_name": "Basic Network Security Technology",
        "definition": "Explain and apply encryption and secure protocols to protect digital networks and data.",
        "curricula_full_name": "",
        "target_audience": ""
    },
    {
        "gsm_no": "DQ12.2",
        "dqc_no": "DQC12",
        "full_name": "Secure Internet Protocols",
        "definition": "Understand and implement secure internet protocols to ensure the safety of digital communications.",
        "curricula_full_name": "",
        "target_audience": ""
    },
    {
        "gsm_no": "DQ12.3",
        "dqc_no": "DQC12",
        "full_name": "Human-Tech System",
        "definition": "Analyze the interaction between humans and computing devices, considering usability, security, and accessibility.",
        "curricula_full_name": "",
        "target_audience": ""
    },
    {
        "gsm_no": "DQ12.4",
        "dqc_no": "DQC12",
        "full_name": "Network Security",
        "definition": "Apply network security practices to protect data and systems from unauthorized access and cyber threats.",
        "curricula_full_name": "",
        "target_audience": ""
    },
    {
        "gsm_no": "DQ12.5",
        "dqc_no": "DQC12",
        "full_name": "Identifying Network Weaknesses",
        "definition": "Predict and mitigate network vulnerabilities to enhance security and ensure productivity.",
        "curricula_full_name": "",
        "target_audience": ""
    },
    {
        "gsm_no": "DQ13.1",
        "dqc_no": "DQC13",
        "full_name": "Technology and Emotion",
        "definition": "Understand the emotional impact of technology on users and its influence on behavior and values.",
        "curricula_full_name": "Digital Wellbeing",
        "target_audience": "All"
    },
    {
        "gsm_no": "DQ13.2",
        "dqc_no": "DQC13",
        "full_name": "Technology and Emotional Regulation",
        "definition": "Develop strategies to manage emotions and impulses in digital interactions.",
        "curricula_full_name": "Digital Wellbeing",
        "target_audience": "All"
    },
    {
        "gsm_no": "DQ13.3",
        "dqc_no": "DQC13",
        "full_name": "Reflecting on Feelings and Values",
        "definition": "Reflect on how personal values and emotions are influenced by digital experiences.",
        "curricula_full_name": "Digital Wellbeing",
        "target_audience": "All"
    },
    {
        "gsm_no": "DQ13.4",
        "dqc_no": "DQC13",
        "full_name": "Goals and Emotional Management",
        "definition": "Manage emotions effectively to foster cooperation and achieve personal and organizational goals.",
        "curricula_full_name": "Digital Wellbeing",
        "target_audience": "All"
    },
    {
        "gsm_no": "DQ13.5",
        "dqc_no": "DQC13",
        "full_name": "Understanding Online Interaction",
        "definition": "Navigate social interactions in online communities to achieve mutual consensus and outcomes.",
        "curricula_full_name": "Digital Wellbeing",
        "target_audience": "All"
    },
    {
        "gsm_no": "DQ13.6",
        "dqc_no": "DQC13",
        "full_name": "Managing Online Interaction",
        "definition": "Build and maintain relationships in online communities to meet individual or organizational objectives.",
        "curricula_full_name": "Digital Wellbeing",
        "target_audience": "All"
    },
    {
        "gsm_no": "DQ13.7",
        "dqc_no": "DQC13",
        "full_name": "Online Interaction Culture",
        "definition": "Promote an inclusive and positive online culture through diplomacy and respect for diverse opinions.",
        "curricula_full_name": "Digital Wellbeing",
        "target_audience": "All"
    },
    {
        "gsm_no": "DQ13.8",
        "dqc_no": "DQC13",
        "full_name": "Solitary and relationship management",
        "definition": "Develop genuine online relationships and bridge differences to create a sense of belonging.",
        "curricula_full_name": "Digital Wellbeing",
        "target_audience": "All"
    },
    {
        "gsm_no": "DQ14.1",
        "dqc_no": "DQC14",
        "full_name": "Co-Construction",
        "definition": "Collaborate using digital tools to co-create resources and knowledge for meaningful purposes.",
        "curricula_full_name": "",
        "target_audience": ""
    },
    {
        "gsm_no": "DQ14.2",
        "dqc_no": "DQC14",
        "full_name": "Collaboration",
        "definition": "Apply socio-emotional and cognitive skills to support collaborative efforts in online communities.",
        "curricula_full_name": "",
        "target_audience": ""
    },
    {
        "gsm_no": "DQ14.3",
        "dqc_no": "DQC14",
        "full_name": "Teamwork",
        "definition": "Leverage technical skills to collaborate effectively across diverse teams and communication channels.",
        "curricula_full_name": "",
        "target_audience": ""
    },
    {
        "gsm_no": "DQ14.4",
        "dqc_no": "DQC14",
        "full_name": "Collaboration Tools",
        "definition": "Use appropriate collaboration tools to interact and work effectively with others in various digital tasks.",
        "curricula_full_name": "",
        "target_audience": ""
    },
    {
        "gsm_no": "DQ14.5",
        "dqc_no": "DQC14",
        "full_name": "Multi-stakeholder Collaboration",
        "definition": "Foster collaboration across sectors to share ideas and achieve common goals.",
        "curricula_full_name": "",
        "target_audience": ""
    },
    {
        "gsm_no": "DQ15.1",
        "dqc_no": "DQC15",
        "full_name": "Designing Algorithm Fitting to Problems",
        "definition": "Plan and develop algorithms to solve specific problems or tasks using computing systems",
        "curricula_full_name": "",
        "target_audience": ""
    },
    {
        "gsm_no": "DQ15.10",
        "dqc_no": "DQC15",
        "full_name": "Data Presentation and Interpretation",
        "definition": "Transform and present large data sets in various formats to influence interpretation, using visualization and mathematical operations to communicate insights effectively.",
        "curricula_full_name": "AI Literacy",
        "target_audience": "Teenagers / Workforce"
    },
    {
        "gsm_no": "DQ15.11",
        "dqc_no": "DQC15",
        "full_name": "Content Creation Skills",
        "definition": "Develop digital content by executing creative plans, engaging in problem-solving through innovative solutions, and expressing identity through creative means.",
        "curricula_full_name": "AI Literacy",
        "target_audience": "Teenagers / Workforce"
    },
    {
        "gsm_no": "DQ15.12",
        "dqc_no": "DQC15",
        "full_name": "Using Personalized AI to Enhance Learning",
        "definition": "Enhance learning by utilizing AI-based personalized learning paths tailored to individual proficiency and progress.",
        "curricula_full_name": "AI Literacy",
        "target_audience": "Teenagers / Workforce"
    },
    {
        "gsm_no": "DQ15.13",
        "dqc_no": "DQC15",
        "full_name": "Using Generative AI to Enhance Creativity",
        "definition": "Use generative AI tools to create original content, facilitate idea generation, and enhance collaboration among users.",
        "curricula_full_name": "AI Literacy",
        "target_audience": "Teenagers / Workforce"
    },
    {
        "gsm_no": "DQ15.14",
        "dqc_no": "DQC15",
        "full_name": "Evaluating and Refining Generative AI Products to Improve Learning",
        "definition": "Evaluate AI products by assessing the credibility of sources and refine them through critical and creative thinking.",
        "curricula_full_name": "AI Literacy",
        "target_audience": "Teenagers / Workforce"
    },
    {
        "gsm_no": "DQ15.2",
        "dqc_no": "DQC15",
        "full_name": "Project Process and Management",
        "definition": "Describe, justify, and document computational processes, using decomposition and abstraction to break down tasks and manage timelines effectively in collaborative environments.",
        "curricula_full_name": "",
        "target_audience": ""
    },
    {
        "gsm_no": "DQ15.3",
        "dqc_no": "DQC15",
        "full_name": "Software Design and Development",
        "definition": "Create simple websites and digital systems tailored to user needs using appropriate design principles and tools like HTML and website builders.",
        "curricula_full_name": "",
        "target_audience": ""
    },
    {
        "gsm_no": "DQ15.4",
        "dqc_no": "DQC15",
        "full_name": "Computing and Networking System",
        "definition": "Understand and apply the interaction between hardware and software in computing systems, including the use of appropriate terminology and design trade-offs.",
        "curricula_full_name": "",
        "target_audience": ""
    },
    {
        "gsm_no": "DQ15.5",
        "dqc_no": "DQC15",
        "full_name": "Solving Technical Problems",
        "definition": "Identify and systematically solve technical problems in computing devices and digital environments, using appropriate terminology and knowledge of system interactions.",
        "curricula_full_name": "",
        "target_audience": ""
    },
    {
        "gsm_no": "DQ15.6",
        "dqc_no": "DQC15",
        "full_name": "Data Collection",
        "definition": "Collect and manage data using digital tools and algorithms, understanding static and dynamic data structures for effective data manipulation and storage.",
        "curricula_full_name": "",
        "target_audience": ""
    },
    {
        "gsm_no": "DQ15.7",
        "dqc_no": "DQC15",
        "full_name": "Data Storage and Management",
        "definition": "Organize and store data efficiently by choosing appropriate data structures and storage methods, understanding the trade-offs involved.",
        "curricula_full_name": "",
        "target_audience": ""
    },
    {
        "gsm_no": "DQ15.8",
        "dqc_no": "DQC15",
        "full_name": "Data Analysis and Visualization",
        "definition": "Analyze and present data visually to highlight relationships and support claims, using statistical methods and AI-related concepts to make predictions.",
        "curricula_full_name": "",
        "target_audience": ""
    },
    {
        "gsm_no": "DQ15.9",
        "dqc_no": "DQC15",
        "full_name": "Simulation and Inference",
        "definition": "Use computer models to simulate events and make inferences or predictions, understanding the importance of data quality and diversity in model accuracy.",
        "curricula_full_name": "",
        "target_audience": ""
    },
    {
        "gsm_no": "DQ16.1",
        "dqc_no": "DQC16",
        "full_name": "Knowledge of Copyright",
        "definition": "Understand and explain the principles of copyright and licenses, and how they apply to digital content and software.",
        "curricula_full_name": "Digital Citizen ID",
        "target_audience": "Teenagers / Workforce"
    },
    {
        "gsm_no": "DQ16.2",
        "dqc_no": "DQC16",
        "full_name": "Protecting Intellectual Property",
        "definition": "Protect your own and others’ digital creations using strategies like trademarks, Creative Commons, and copyright, and learn proper citation conventions.",
        "curricula_full_name": "Digital Citizen ID",
        "target_audience": "Teenagers / Workforce"
    },
    {
        "gsm_no": "DQ16.3",
        "dqc_no": "DQC16",
        "full_name": "IP and Innovation",
        "definition": "Explain the beneficial and harmful effects that intellectual property laws can have on innovation.",
        "curricula_full_name": "Digital Citizen ID",
        "target_audience": "Teenagers / Workforce"
    },
    {
        "gsm_no": "DQ17.1",
        "dqc_no": "DQC17",
        "full_name": "Technology at Work",
        "definition": "Understand how technology creates new jobs and changes the workplace, identifying how technology is used in various industries.",
        "curricula_full_name": "",
        "target_audience": ""
    },
    {
        "gsm_no": "DQ17.2",
        "dqc_no": "DQC17",
        "full_name": "Digital ESG",
        "definition": "Recognize the opportunities for entrepreneurship in the digital economy by understanding the interaction between the digital and physical worlds and applying cross-economy concepts.",
        "curricula_full_name": "",
        "target_audience": ""
    },
    {
        "gsm_no": "DQ17.3",
        "dqc_no": "DQC17",
        "full_name": "Impacts of Computing on Life in regards to diversity accessibility and inequality",
        "definition": "Understand how computing technology affects life and work, and how to design for diverse end users to address accessibility and usability.",
        "curricula_full_name": "",
        "target_audience": ""
    },
    {
        "gsm_no": "DQ17.4",
        "dqc_no": "DQC17",
        "full_name": "Autonomy and data management",
        "definition": "Manage identity and preference data in digital markets to assess and achieve subjective well-being.",
        "curricula_full_name": "",
        "target_audience": ""
    },
    {
        "gsm_no": "DQ17.5",
        "dqc_no": "DQC17",
        "full_name": "Career identity",
        "definition": "Develop a career identity through exploring and contributing to the digital economy.",
        "curricula_full_name": "",
        "target_audience": ""
    },
    {
        "gsm_no": "DQ17.6",
        "dqc_no": "DQC17",
        "full_name": "Understanding Digital-ESG Criteria for Maximizing Opportunities",
        "definition": "Understand and apply Digital-ESG criteria to maximize digital-related opportunities through technology innovation while ensuring sustainable and ethical practices.",
        "curricula_full_name": "",
        "target_audience": ""
    },
    {
        "gsm_no": "DQ17.7",
        "dqc_no": "DQC17",
        "full_name": "Understanding Digital-ESG Criteria for Minimizing Risks",
        "definition": "Understand and apply Digital-ESG criteria to minimize digital-related risks and develop products and services that benefit society at large.",
        "curricula_full_name": "",
        "target_audience": ""
    },
    {
        "gsm_no": "DQ18.1",
        "dqc_no": "DQC18",
        "full_name": "Community Engagement and Values",
        "definition": "Understand the importance of community engagement and civic participation, aligning standards with community and organizational values.",
        "curricula_full_name": "Digital Wellbeing",
        "target_audience": "Workforce"
    },
    {
        "gsm_no": "DQ18.10",
        "dqc_no": "DQC18",
        "full_name": "Digital Transaction Literacy",
        "definition": "Understand online purchasing issues such as value, integrity, and impact, and be savvy in online purchasing decisions.",
        "curricula_full_name": "Digital Wellbeing",
        "target_audience": "Workforce"
    },
    {
        "gsm_no": "DQ18.2",
        "dqc_no": "DQC18",
        "full_name": "Online Community Organising",
        "definition": "Organize and engage online communities for effecting change, participating in discussions, and creating shared values.",
        "curricula_full_name": "Digital Wellbeing",
        "target_audience": "Workforce"
    },
    {
        "gsm_no": "DQ18.3",
        "dqc_no": "DQC18",
        "full_name": "Civic and Community Impact",
        "definition": "Influence communities through appropriate technologies, demonstrating respect for civic engagement and a willingness to contribute to societal betterment.",
        "curricula_full_name": "Digital Wellbeing",
        "target_audience": "Workforce"
    },
    {
        "gsm_no": "DQ18.4",
        "dqc_no": "DQC18",
        "full_name": "Digital Political Literacy",
        "definition": "Politically recognize, use, and evaluate digital platforms, tools, and content.",
        "curricula_full_name": "Digital Wellbeing",
        "target_audience": "Workforce"
    },
    {
        "gsm_no": "DQ18.5",
        "dqc_no": "DQC18",
        "full_name": "Digital Political Identity",
        "definition": "Develop a sense of political identity through exploring and contributing to social movements online.",
        "curricula_full_name": "Digital Wellbeing",
        "target_audience": "Workforce"
    },
    {
        "gsm_no": "DQ18.6",
        "dqc_no": "DQC18",
        "full_name": "Digital Political Activism",
        "definition": "Engage in politically motivated activities through online consumption, participation, fundraising, and hacking.",
        "curricula_full_name": "Digital Wellbeing",
        "target_audience": "Workforce"
    },
    {
        "gsm_no": "DQ18.7",
        "dqc_no": "DQC18",
        "full_name": "Digital Fundraising",
        "definition": "Develop a sense of agency by setting goals to support meaningful visions and understanding resources for digital fundraising.",
        "curricula_full_name": "",
        "target_audience": ""
    },
    {
        "gsm_no": "DQ18.8",
        "dqc_no": "DQC18",
        "full_name": "Creating and Managing Digital Initiatives",
        "definition": "Develop ideas for digital initiatives, create a value proposition, and manage these initiatives effectively.",
        "curricula_full_name": "",
        "target_audience": ""
    },
    {
        "gsm_no": "DQ18.9",
        "dqc_no": "DQC18",
        "full_name": "Digital Government Literacy",
        "definition": "Understand and engage with E-government services, appreciating their value and the digital literacy required.",
        "curricula_full_name": "",
        "target_audience": ""
    },
    {
        "gsm_no": "DQ19.1",
        "dqc_no": "DQC19",
        "full_name": "Commercial and Community Cyber-Risk Awareness",
        "definition": "Understand different types of commercial and community cyber-risks, such as embedded marketing and online propaganda.",
        "curricula_full_name": "Digital Citizen ID",
        "target_audience": "Teenagers / Workforce"
    },
    {
        "gsm_no": "DQ19.2",
        "dqc_no": "DQC19",
        "full_name": "Dealing With Community and Commercial Cyber-Risks",
        "definition": "Identify and develop strategies to manage community and commercial cyber-risks, including using tools like ad blockers.",
        "curricula_full_name": "Digital Citizen ID",
        "target_audience": "Teenagers / Workforce"
    },
    {
        "gsm_no": "DQ19.3",
        "dqc_no": "DQC19",
        "full_name": "Preventing Community and Commercial Cyber-Risks",
        "definition": "Proactively manage risks to prevent problems and eliminate recurring issues in community and commercial contexts.",
        "curricula_full_name": "Digital Citizen ID",
        "target_audience": "Teenagers / Workforce"
    },
    {
        "gsm_no": "DQ19.4",
        "dqc_no": "DQC19",
        "full_name": "Safe Online Participation",
        "definition": "Work towards a safe and secure digital environment by developing protocols and promoting best practices.",
        "curricula_full_name": "Digital Citizen ID",
        "target_audience": "Teenagers / Workforce"
    },
    {
        "gsm_no": "DQ19.5",
        "dqc_no": "DQC19",
        "full_name": "Understanding Digital-related Risks",
        "definition": "Understand and prioritize digital-related risks in business practices to protect company operations and reputation.",
        "curricula_full_name": "Digital Citizen ID",
        "target_audience": "Teenagers / Workforce"
    },
    {
        "gsm_no": "DQ19.7",
        "dqc_no": "DQC19",
        "full_name": "Adhering to Digital Compliance and Regulations",
        "definition": "Stay updated with digital regulations and compliance requirements, ensuring adherence to legal and industry standards.",
        "curricula_full_name": "Digital Citizen ID",
        "target_audience": "Workforce"
    },
    {
        "gsm_no": "DQ20.1",
        "dqc_no": "DQC20",
        "full_name": "Understanding Organizational Cyber Security Management",
        "definition": "Understand and manage organizational cyber security through policies, practices, and procedures, ensuring data and system protection.",
        "curricula_full_name": "",
        "target_audience": ""
    },
    {
        "gsm_no": "DQ20.2",
        "dqc_no": "DQC20",
        "full_name": "Organizational Cyber Security Skills",
        "definition": "Improve organizational cyber security systems by forecasting risks, developing intervention strategies, and ensuring prompt recovery after a crisis.",
        "curricula_full_name": "",
        "target_audience": ""
    },
    {
        "gsm_no": "DQ20.3",
        "dqc_no": "DQC20",
        "full_name": "Organizational Cyber Security Frameworks",
        "definition": "Develop and disseminate corporate security policies and frameworks to protect against risks, threats, and vulnerabilities.",
        "curricula_full_name": "",
        "target_audience": ""
    },
    {
        "gsm_no": "DQ20.4",
        "dqc_no": "DQC20",
        "full_name": "Preventative Cyber Security",
        "definition": "Proactively advocate for cyber security within the organization by providing guidance on risks and best practices.",
        "curricula_full_name": "",
        "target_audience": ""
    },
    {
        "gsm_no": "DQ20.5",
        "dqc_no": "DQC20",
        "full_name": "National Cyber Security",
        "definition": "Coordinate cyber security at the national level through synchronized efforts across government, private sector, and civil society.",
        "curricula_full_name": "",
        "target_audience": ""
    },
    {
        "gsm_no": "DQ22.1",
        "dqc_no": "DQC22",
        "full_name": "Understanding Pubic and Mass Communication",
        "definition": "Understand how online platforms and digital environments influence the dissemination of ideas and messages, shaped by ethical and legal considerations.",
        "curricula_full_name": "",
        "target_audience": ""
    },
    {
        "gsm_no": "DQ22.2",
        "dqc_no": "DQC22",
        "full_name": "Articulating a Compelling Story",
        "definition": "Effectively articulate and optimize content delivery to resonate with the target audience, using digital storyboards and storytelling techniques.",
        "curricula_full_name": "",
        "target_audience": ""
    },
    {
        "gsm_no": "DQ22.3",
        "dqc_no": "DQC22",
        "full_name": "SEO and Digital Marketing",
        "definition": "Engage audiences using SEO and digital marketing strategies, monitoring data analytics to boost engagement and co-create an organization’s brand.",
        "curricula_full_name": "",
        "target_audience": ""
    },
    {
        "gsm_no": "DQ22.4",
        "dqc_no": "DQC22",
        "full_name": "Ethical and Principled Communication",
        "definition": "Exhibit ethical and principled use of technology to engage in productive discourse with digital communities, while responding to environmental impacts.",
        "curricula_full_name": "",
        "target_audience": ""
    },
    {
        "gsm_no": "DQ22.5",
        "dqc_no": "DQC22",
        "full_name": "Sharing the Story through Digital Platforms",
        "definition": "Share stories through appropriate digital platforms, developing dissemination strategies to reach a broader audience.",
        "curricula_full_name": "",
        "target_audience": ""
    },
    {
        "gsm_no": "DQ22.6",
        "dqc_no": "DQC22",
        "full_name": "Building a Digital Community",
        "definition": "Build and inspire a digital community by communicating ideas and participating in online movements or campaigns, considering diverse audiences.",
        "curricula_full_name": "",
        "target_audience": ""
    },
    {
        "gsm_no": "DQ22.7",
        "dqc_no": "DQC22",
        "full_name": "Tracking Performance and Impact",
        "definition": "Track performance and measure the impact of digital influence using data analytics to make informed decisions.",
        "curricula_full_name": "",
        "target_audience": ""
    },
    {
        "gsm_no": "DQ23.1",
        "dqc_no": "DQC23",
        "full_name": "Advanced Technology",
        "definition": "Understand the benefits, limits, and risks of advanced technology like big data, AI, IoT, and virtual reality.",
        "curricula_full_name": "",
        "target_audience": ""
    },
    {
        "gsm_no": "DQ23.2",
        "dqc_no": "DQC23",
        "full_name": "Advanced Tech and Scientific Research Skills",
        "definition": "Utilize technology to conduct research, apply scientific methods, and develop advanced digital skills for sound conclusions.",
        "curricula_full_name": "",
        "target_audience": ""
    },
    {
        "gsm_no": "DQ23.3",
        "dqc_no": "DQC23",
        "full_name": "Inclusive Technology",
        "definition": "Address accessibility and usability in technology design by responding to the needs of diverse end users and advocating for improvements.",
        "curricula_full_name": "",
        "target_audience": ""
    },
    {
        "gsm_no": "DQ23.4",
        "dqc_no": "DQC23",
        "full_name": "Understanding Digital Entrepreneurship",
        "definition": "Identify business opportunities in the digital world by understanding gaps in existing services and how they interact with the physical world.",
        "curricula_full_name": "",
        "target_audience": ""
    },
    {
        "gsm_no": "DQ23.5",
        "dqc_no": "DQC23",
        "full_name": "Developing Ideas and Value Propositions",
        "definition": "Conceptualize ideas for digital initiatives by recognizing problems and risks as opportunities for entrepreneurship, creating value propositions with positive impact.",
        "curricula_full_name": "",
        "target_audience": ""
    },
    {
        "gsm_no": "DQ23.6",
        "dqc_no": "DQC23",
        "full_name": "Managing Digital Initiatives",
        "definition": "Effectively manage digital initiatives by planning, executing, and adapting to ensure successful outcomes.",
        "curricula_full_name": "",
        "target_audience": ""
    },
    {
        "gsm_no": "DQ23.7",
        "dqc_no": "DQC23",
        "full_name": "Innovation and entrepreneurship",
        "definition": "Participate in the digital ecosystem to innovate and transform the global economy, creating sustainable employment and entrepreneurship opportunities.",
        "curricula_full_name": "",
        "target_audience": ""
    },
    {
        "gsm_no": "DQ24.1",
        "dqc_no": "DQC24",
        "full_name": "Understanding Digital Rights",
        "definition": "Understand digital rights as a citizen and consumer, recognizing how risks and opportunities for online participation are distributed across social groups.",
        "curricula_full_name": "",
        "target_audience": ""
    },
    {
        "gsm_no": "DQ24.2",
        "dqc_no": "DQC24",
        "full_name": "Protecting Digital Rights",
        "definition": "Develop skills to uphold and respect digital rights, applying system-level thinking to monitor and improve online participation.",
        "curricula_full_name": "",
        "target_audience": ""
    },
    {
        "gsm_no": "DQ24.3",
        "dqc_no": "DQC24",
        "full_name": "Proactive Digital Rights",
        "definition": "Exhibit proactive thinking grounded in democratic ideals and human rights, taking responsibility for managing technology for the public good.",
        "curricula_full_name": "",
        "target_audience": ""
    },
    {
        "gsm_no": "DQ25.1",
        "dqc_no": "DQC25",
        "full_name": "Digital Confidence",
        "definition": "Be confident in using digital technology and media, understanding your abilities and identifying ways to improve digital competence.",
        "curricula_full_name": "Digital Wellbeing",
        "target_audience": "All"
    },
    {
        "gsm_no": "DQ25.2",
        "dqc_no": "DQC25",
        "full_name": "ICT Knowledge",
        "definition": "Understand how the digital world is structured and utilize technology effectively.",
        "curricula_full_name": "Digital Wellbeing",
        "target_audience": "All"
    },
    {
        "gsm_no": "DQ26.1",
        "dqc_no": "DQC26",
        "full_name": "Digital Presence",
        "definition": "Ensure meaningful access to the Internet through proper devices and active involvement in various digital activities.",
        "curricula_full_name": "Digital Citizen ID",
        "target_audience": "All"
    },
    {
        "gsm_no": "DQ27.1",
        "dqc_no": "DQC27",
        "full_name": "Understanding Content Cyber-Risks",
        "definition": "Understand and identify risky online content such as violence, lewd material, racist, hateful, or discriminatory content, and image-based abuse.",
        "curricula_full_name": "Digital Citizen ID",
        "target_audience": "All"
    },
    {
        "gsm_no": "DQ27.2",
        "dqc_no": "DQC27",
        "full_name": "Dealing with Content Cyber-Risks",
        "definition": "Execute strategies to handle content cyber-risks, develop resilience, and use conflict management techniques to mitigate harmful online content.",
        "curricula_full_name": "Digital Citizen ID",
        "target_audience": "All"
    },
    {
        "gsm_no": "DQ27.3",
        "dqc_no": "DQC27",
        "full_name": "Health Check - Risky Content",
        "definition": "Ensure that you have not been exposed to risky online content, including violent, sexual, racist, or discriminatory material.",
        "curricula_full_name": "Digital Citizen ID",
        "target_audience": "All"
    },
    {
        "gsm_no": "DQ28.1",
        "dqc_no": "DQC28",
        "full_name": "Managing Passwords",
        "definition": "Secure your digital accounts with strong passwords and utilize tactics to keep them confidential.",
        "curricula_full_name": "Cybersecurity 101",
        "target_audience": "All"
    },
    {
        "gsm_no": "DQ28.2",
        "dqc_no": "DQC28",
        "full_name": "Mobile Security",
        "definition": "Understand mobile security practices and develop good habits, such as using lock screens and disabling Bluetooth in public places.",
        "curricula_full_name": "Cybersecurity 101",
        "target_audience": "All"
    },
    {
        "gsm_no": "DQ29.1",
        "dqc_no": "DQC29",
        "full_name": "Self-Awareness",
        "definition": "Be sensitive to and aware of your emotions when interacting with or exposed to online content and interactions.",
        "curricula_full_name": "Digital Wellbeing",
        "target_audience": "All"
    },
    {
        "gsm_no": "DQ29.2",
        "dqc_no": "DQC29",
        "full_name": "Emotional literacy",
        "definition": "Use and comprehend digital emotional cues, such as emoticons, to identify and understand emotional situations online.",
        "curricula_full_name": "Digital Wellbeing",
        "target_audience": "All"
    },
    {
        "gsm_no": "DQ30.1",
        "dqc_no": "DQC30",
        "full_name": "Communication Skills",
        "definition": "Apply socio-emotional, interpersonal, cognitive, and technical skills to support effective online communication and collaboration.",
        "curricula_full_name": "",
        "target_audience": ""
    },
    {
        "gsm_no": "DQ30.2",
        "dqc_no": "DQC30",
        "full_name": "Communicative Literacy",
        "definition": "Collaborate effectively through virtual communication by selecting appropriate platforms based on audience and context.",
        "curricula_full_name": "",
        "target_audience": ""
    },
    {
        "gsm_no": "DQ30.3",
        "dqc_no": "DQC30",
        "full_name": "Communication Awareness",
        "definition": "Be aware of the diverse aspects of online communication, including types, norms, and platforms, to interact responsibly and effectively.",
        "curricula_full_name": "",
        "target_audience": ""
    },
    {
        "gsm_no": "DQ30.4",
        "dqc_no": "DQC30",
        "full_name": "Communication Power",
        "definition": "Leverage respectful online communication and strategic technology choices to empower community engagement and drive effective knowledge sharing.",
        "curricula_full_name": "",
        "target_audience": ""
    },
    {
        "gsm_no": "DQ31.1",
        "dqc_no": "DQC31",
        "full_name": "Basic Hardware and Software Operations",
        "definition": "Distinguish between types of electronic computers, understand their purposes, and develop basic skills to operate software tools and technologies.",
        "curricula_full_name": "",
        "target_audience": ""
    },
    {
        "gsm_no": "DQ31.2",
        "dqc_no": "DQC31",
        "full_name": "Internet Operations",
        "definition": "Understand the basic concepts of internet and computer networks, and effectively use Internet tools such as browsers, social media apps, and email.",
        "curricula_full_name": "",
        "target_audience": ""
    },
    {
        "gsm_no": "DQ31.3",
        "dqc_no": "DQC31",
        "full_name": "Organizing and Processing Information",
        "definition": "Organize, store, retrieve, and manage data, information, and content in digital environments using computing devices.",
        "curricula_full_name": "",
        "target_audience": ""
    },
    {
        "gsm_no": "DQ31.4",
        "dqc_no": "DQC31",
        "full_name": "Productivity Software Skills",
        "definition": "Create and edit digital content using word processors, spreadsheets, and graphic editors, applying basic formatting and editing techniques.",
        "curricula_full_name": "",
        "target_audience": ""
    },
    {
        "gsm_no": "DQ31.5",
        "dqc_no": "DQC31",
        "full_name": "Social Media Skills",
        "definition": "Use, recognize, and understand digital and creative tools and social media platforms to communicate and collaborate effectively.",
        "curricula_full_name": "",
        "target_audience": ""
    },
    {
        "gsm_no": "DQ31.6",
        "dqc_no": "DQC31",
        "full_name": "Internet Operations (Proportion of Individuals)",
        "definition": "Measure the proportion of individuals who can execute basic internet operations effectively.",
        "curricula_full_name": "",
        "target_audience": ""
    },
    {
        "gsm_no": "DQ32.1",
        "dqc_no": "DQC32",
        "full_name": "Digital Consumer Rights Management",
        "definition": "Exercise fair, informed, and equitable choices in the online market while being protected by consumer rights.",
        "curricula_full_name": "Digital Wellbeing",
        "target_audience": "All"
    }
];

// Group data by dqc_no
export const ALL_GSM_BY_DQC = groupByDqcNo(data);
export const ALL_GSM_BY_GSM = groupByGsmNo(data);