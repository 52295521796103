import React, {useEffect, useState} from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Container, Typography, Box, Tooltip } from '@material-ui/core';
import RemoveIcon from '@material-ui/icons/Remove';
import IconButton from '@material-ui/core/IconButton';
import TextField from "@material-ui/core/TextField";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import {useHistory} from "react-router-dom";
import {Autocomplete} from "@mui/material";
import Loader from "../../components/Loader/Loader.comp";
import RaazImage from  "../../assets/images/Razz_Img.png"
import {getAllDigitalSkills} from "../../services/pbdq_v2.service";
import {ALL_DIGITAL_SKILLS} from "../../data/digital_skills.data";

const useStyles = makeStyles((theme) => ({
    parentContainer: {
        display: 'flex',
        minHeight: '95vh',
        backgroundColor: '#1b3867',
        padding: '30px',
        marginTop:'40px',
    },
    titleContainer: {
        textAlign: 'center',
    },
    leftContainer: {
        flex: 1,
        padding: theme.spacing(2),
        marginRight: theme.spacing(2),
        borderRadius: '8px',
        backgroundColor: 'transparent',
        display: 'flex',
        flexDirection:'column',
        justifyContent: 'start',
        alignItems: 'center',
    },
    rightContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        padding: theme.spacing(2),
        backgroundColor: '#1b3867',
        borderRadius: '8px',
    },
    skillRow: {
        display: 'flex',
        alignItems: 'left',
        minWidth:'620px',
        marginBottom: theme.spacing(4),
        color: '#fff',
    },
    skillIconContainer: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: theme.spacing(1),
        backgroundColor: '#0097a7',
        borderRadius: '50%',
        width: '30px',
        height: '30px',
    },
    skillIcon: {
        position: 'absolute',
        color: '#fff',
    },
    addSkillRow: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
        width: '97%',
    },
    searchField: {
        padding:'10px',
        flexGrow: 1,
        marginLeft: theme.spacing(2),
        backgroundColor: '#fff',
        borderRadius: '4px',
        '& input': {
            color: '#000',
        },
    },
    section: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#fff',
        padding: theme.spacing(2),
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
        borderRadius: '8px',
        marginBottom: theme.spacing(2),
    },
    leftSection: {
        alignItems:'center',
        textAlign:'center',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#1b3867',
        color:'#fff',
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    heading: {
        marginBottom: theme.spacing(2),
        fontWeight: 'bold',
        textAlign:'center',
    },
    subBoxContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    subBox: {
        backgroundColor: '#fff',
        textAlign:'center',
        border: '1px solid #ddd',
        borderRadius: '4px',
        padding: theme.spacing(1),
        margin: theme.spacing(1),
        flex: '1 1 20%',
        boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
    },
    tooltip: {
        backgroundColor: 'teal',
        color: '#fff',
        border: '1px solid #fff',
        borderRadius: '4px',
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(1),
    },
    tooltipImage: {
        width: '100px',
        height: '100px',
        marginRight: theme.spacing(1),
    },
    tooltipText: {
        fontSize: '14px',
    },
    tooltipContent: {
        display:'flex',
        flexDirection: 'row',
    },
    submitButton: {
        marginTop: theme.spacing(2),
        padding: '10px 30px',
        fontSize: '16px',
        border: '2px solid #ee6628',
        color: '#ee6628',
        backgroundColor: '#fff',
        '&:hover': {
            backgroundColor: '#ee6628',
            color: '#fff',
        },
        '&.Mui-disabled': {
            backgroundColor: '#D3D3D3',
            color: 'white',
            border: '2px solid #D3D3D3',
        },
    },
}));

const CustomTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#0097a7',
        color: '#fff',
        fontSize: '16px',
        borderRadius:'15px',
        padding:'15px',
    },
    arrow: {
        color: '#0097a7',
    },
}))(Tooltip);

const  ProgramSelection = () => {
    const classes = useStyles();
    const [skillOptions, setSkillOptions] = useState([]);
    const [digitalSkills, setDigitalSkills] = useState([]);
    const [newSkill, setNewSkill] = useState('');
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const popularDigitalSkillsTopicsTooltips = {
        'A': 'Definition of each topic when hovered over A',
        'B': 'Definition of each topic when hovered over B',
        'C': 'Definition of each topic when hovered over C',
        'D': 'Definition of each topic when hovered over D',
        'E': 'Definition of each topic when hovered over E',
        'F': 'Definition of each topic when hovered over F',
        'G': 'Definition of each topic when hovered over G',
        'H': 'Definition of each topic when hovered over H',
    };

    const relatedDigitalSkillsTopicsTooltips = {
        'A': 'Definition of each topic when hovered over A',
        'B': 'Definition of each topic when hovered over B',
        'C': 'Definition of each topic when hovered over C',
        'D': 'Definition of each topic when hovered over D',
        'E': 'Definition of each topic when hovered over E',
        'F': 'Definition of each topic when hovered over F',
        'G': 'Definition of each topic when hovered over G',
        'H': 'Definition of each topic when hovered over H',
    };

    useEffect(() => {
        const fetchAndMapSkills = async () => {
            setLoading(true);
            try {
                const result = await getAllDigitalSkills();
                const skillsData = result.data;
                const mappedSkills = skillsData.map(skill => ({
                    title: skill.full_name,
                    dqc_no: skill.dqc_no
                }));
                // TODO:NAV:LOG needs to preserve the complete object here, as dqc_no is need to complete the further functionality.
                setSkillOptions(mappedSkills);
            } catch (e) {
                console.error('Error fetching digital skills:', e);
            } finally {
                setLoading(false);
            }
        };
        fetchAndMapSkills();
    }, []);

    const handleAddSkill = () => {
        if (newSkill.trim() !== '' && digitalSkills.length < 6) {
            setDigitalSkills([...digitalSkills, newSkill]);
            setNewSkill('');
        }
    };

    const handleRemoveSkill = (skill) => {
        setDigitalSkills(digitalSkills.filter(s => s !== skill));
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleAddSkill();
        }
    };
    const handleSubmit = () => {
        if (digitalSkills.length > 0) {
            setLoading(true);
            console.log('Selected Skills:', digitalSkills);

            const selectedDqcNos = digitalSkills.map(skillTitle => {
                const skill = skillOptions.find(option => option.title === skillTitle);
                return skill ? skill.dqc_no : null;
            }).filter(dqcNo => dqcNo !== null);

            console.log('Selected DQC Nos:', selectedDqcNos);
            const curriculumCode = digitalSkills ? digitalSkills.dqc_no : null;
            setTimeout(() => {
                history.push({
                    pathname: '/suggested_course',
                    state: { selectedSkills: digitalSkills, curriculumCode: selectedDqcNos }
                });
            }, 2000);

            console.log(curriculumCode, "###########");
        } else {
            alert('Please add at least one digital skill.');
        }
    };

    if (loading) {
        return (
            <Box className={classes.loaderContainer}>
                <Loader />
            </Box>
        );
    }

    const searchTooltipContent = (
        <div className={classes.tooltipContent}>
            <img src={RaazImage} alt="Image" className={classes.tooltipImage} />
            <Typography variant="body2" className={classes.tooltipText}>
                Search for the digital skills you want to teach. We’ve also put related topics as you start searching to ensure you teach what you want!
            </Typography>
        </div>
    );



    return(
        <div style={{ backgroundColor: '#1b3867',}}>
            <Box className={classes.titleContainer}>
                <Typography variant="h4" style={{color:'#fff',  marginTop:'67px', fontWeight:'400',}}>
                    Search the digital skills you want to teach.
                </Typography>
            </Box>
            <Box className={classes.parentContainer}>
                <Box className={classes.leftContainer}>
                    <Box className={classes.leftSection}>
                        <Typography variant="h5" className={classes.heading}>DQ Databank</Typography>
                        <Typography variant="body1" style={{ fontStyle: 'italic' }}>Search the digital skills you want to teach.</Typography>
                    </Box>
                    <Box style={{width:'97%'}}>
                    {digitalSkills.map((skill, idx) => (
                        <Box className={classes.skillRow} key={idx}>
                            <Box className={classes.skillIconContainer} >
                                <IconButton onClick={() => handleRemoveSkill(skill)}>
                                    <RemoveIcon style={{ color: '#fff' }} className={classes.skillIcon} />
                                </IconButton>
                            </Box>
                            <Typography style={{ marginLeft:'15px'}}>{skill}</Typography>
                        </Box>
                    ))}
                    </Box>
                    <Box className={classes.addSkillRow}>
                        <Box className={classes.skillIconContainer}>
                            <IconButton onClick={handleAddSkill}>
                                <AddIcon style={{ color: '#fff' }} className={classes.skillIcon}/>
                            </IconButton>
                        </Box>
                        <CustomTooltip title={searchTooltipContent} arrow>
                        <Autocomplete
                            className={classes.searchField}
                            freeSolo
                            options={skillOptions.map(option => option.title)}
                            value={newSkill}
                            onChange={(event, newValue) => setNewSkill(newValue)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder="Type Something..."
                                    onKeyPress={handleKeyPress}
                                    InputProps={{ ...params.InputProps, disableUnderline: true }}
                                />
                            )}
                        />
                        </CustomTooltip>
                    </Box>
                    <Button
                        className={classes.submitButton}
                        onClick={handleSubmit}
                        disabled={loading || digitalSkills.length === 0}
                    >
                        Submit
                    </Button>
                </Box>
                <Box className={classes.rightContainer}>
                    <Box className={classes.section}>
                        <Typography variant="h5" className={classes.heading}>Popular Digital Skills Topics</Typography>
                        <Box className={classes.subBoxContainer}>
                            {Object.keys(popularDigitalSkillsTopicsTooltips).map((text, idx) => (
                                <CustomTooltip key={idx} title={popularDigitalSkillsTopicsTooltips[text]} arrow
                                               placement="top">
                                    <Box className={classes.subBox}>{text}</Box>
                                </CustomTooltip>
                            ))}
                        </Box>
                    </Box>

                    <Box className={classes.section}>
                        <Typography variant="h5" className={classes.heading}>Related Digital Skills Topics</Typography>
                        <Box className={classes.subBoxContainer}>
                            {Object.keys(relatedDigitalSkillsTopicsTooltips).map((text, idx) => (
                                <CustomTooltip key={idx} title={relatedDigitalSkillsTopicsTooltips[text]} arrow
                                               placement="top">
                                    <Box className={classes.subBox}>{text}</Box>
                                </CustomTooltip>
                            ))}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </div>
    )
}

export default ProgramSelection;