import React from 'react';
import { Box, Typography, Button, Grid, Card, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Icon1 from '../../assets/images/Icon1.png';
import  Icon2  from '../../assets/images/Icon2.png';
import  Icon3  from '../../assets/images/Icon3.png';
import  Icon4  from '../../assets/images/Icon4.png';
import  Icon5  from '../../assets/images/Icon5.png';
import PptxGenJS from "pptxgenjs";

const useStyles = makeStyles((theme) => ({
    container: {
        textAlign: 'center',
        padding: theme.spacing(4),
    },
    title: {
        marginBottom: theme.spacing(4),
        fontWeight: 'bold',
        marginTop: '50px',
        fontSize: '42px',
    },
    card: {
        position:'relative',
        border: '1px solid #E0E0E0',
        borderRadius: '8px',
        height:'500px',
        padding: theme.spacing(2),
        textAlign: 'center',
        backgroundColor: '#F9F9F9',
    },
    icon: {
        width: '70px',
        color: theme.palette.primary.main,
    },
    programName: {
        color: '#ef6528',
        fontWeight: 'normal',
        marginTop: theme.spacing(2),
    },
    docType: {
        color: '#0097a7',
        fontWeight: '700',
        fontSize:'20px',
        marginTop: theme.spacing(1),
    },
    description: {
        color: '#000',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    button: {
        color: '#ef6528',
        borderColor: '#ef6528',
        marginTop: theme.spacing(1),
    },
    buttonContainer: {
        width:'130px',
        left:'53px',
        position:'absolute',
        bottom:'20px',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
    },
}));

const AccessProgram = () => {
    const classes = useStyles();

    const prepareAndDownloadPPT = () => {
        console.log('prepareAndDownloadPPT called!');

        const data = {
            title: "3 Steps to Manage Online Reputation",
            content: [
                {
                    heading: "Monitor Regularly",
                    body: "Keep track of your online presence through tools like Google Alerts and social media monitoring.",
                },
                {
                    heading: "Engage Positively",
                    body: "Respond promptly and professionally to comments and reviews, addressing any negative feedback constructively.",
                },
                {
                    heading: "Curate Content",
                    body: "Consistently post quality content that reflects your values and expertise, reinforcing a positive image.",
                },
            ],
        };

        let pptx = new PptxGenJS();

        let slide = pptx.addSlide();

        slide.background = { color: "FF6F3F" }; // Orange background similar to your provided image

        slide.addText(data.title, {
            x: 1.0, // X offset from left
            y: 1.5, // Y offset from top
            w: 2.6, // 35% of slide width (~10 inches wide)
            h: 4.0, // Title height, adjust as needed
            fontSize: 36,
            bold: true,
            color: "#ffffff",
            align: "left",
            valign: "top", // Vertical alignment at the top
            margin: 0.1,
            fill: { color: "#FF6F3F" }, // Optional to ensure consistency in color
        });

        const contentWidth = 5.4; // 65% of the slide width (~10 inches wide)
        const yStart = 0.8; // Starting Y for top margin

        const contentHeight = 4.5; // Space for the content
        data.content.forEach((item, index) => {
            const yOffset = yStart + (contentHeight / data.content.length) * index;

            slide.addText(`${item.heading}:`, {
                x: 4.0,
                y: yOffset,
                w: contentWidth,
                fontSize: 22,
                bold: true,
                color: "#FFEB3B",
                align: "left",
                valign: "top",
            });

            slide.addText(item.body, {
                x: 4.0, // Align with the heading
                y: yOffset + 0.3, // Offset the body text slightly below the heading
                w: contentWidth, // 65% of the slide width
                h: 1.0, // Adjust height to fit text wrapping
                fontSize: 18,
                color: "#ffffff",
                align: "left",
                valign: "top",
                // margin: 0.1,
            });
        });


        pptx.writeFile({ fileName: "Online_Reputation_Steps.pptx" });
    }

    return (
        <Box className={classes.container}>
            <Typography className={classes.title}>Now, it’s just plug and play!</Typography>
            <Grid container spacing={3} justifyContent="center">
                    <Grid item xs={12} sm={6} md={2} >
                        <Card className={classes.card}>
                            <CardContent>
                                <img src={Icon1} className={classes.icon} />
                                <Typography variant="h6" className={classes.programName} style={{marginTop:'35px'}}>
                                    DigiCitizen for Success
                                </Typography>
                                <Typography variant="body2" className={classes.docType}>
                                    PDF Document <br/><br/>
                                </Typography>
                                <Typography variant="body2" className={classes.description}>
                                    Get a PDF version of the program’s content
                                </Typography>
                                <div className={classes.buttonContainer}>
                                        <Button
                                            variant="outlined"
                                            className={classes.button}
                                            fullWidth
                                        >
                                            Download
                                        </Button>
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                <Card className={classes.card}>
                    <CardContent>
                        <img src={Icon2} className={classes.icon} style={{width:'80px', marginTop:'10px'}}/>
                        <Typography variant="h6" className={classes.programName} style={{marginTop:'33px', }}>
                            DigiCitizen for Success
                        </Typography>
                        <Typography variant="body2" className={classes.docType}>
                            Power Point <br/><br/>
                        </Typography>
                        <Typography variant="body2" className={classes.description}>
                            Get a PPT version of the program’s content
                        </Typography>
                        <div className={classes.buttonContainer}>
                                <Button
                                    onClick={prepareAndDownloadPPT}
                                    variant="outlined"
                                    className={classes.button}
                                    fullWidth
                                >
                                    Download
                                </Button>
                        </div>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
                <Card className={classes.card}>
                    <CardContent>
                        <img src={Icon3} className={classes.icon} style={{marginTop:'15px'}}/>
                        <Typography variant="h6" className={classes.programName} style={{marginTop:'48px'}}>
                            DigiCitizen for Success
                        </Typography>
                        <Typography variant="body2" className={classes.docType}>
                            Online Program <br/><br/>
                        </Typography>
                        <Typography variant="body2" className={classes.description}>
                            Access your ready-to-go online program that can be used as-is or linked to your LMS via API.
                        </Typography>
                        <div className={classes.buttonContainer}>
                                <Button
                                    variant="outlined"
                                    className={classes.button}
                                    fullWidth
                                >
                                    Access
                                </Button>
                                <Button
                                    variant="outlined"
                                    className={classes.button}
                                    fullWidth
                                >
                                    Link API
                                </Button>
                        </div>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
                <Card className={classes.card}>
                    <CardContent>
                        <img src={Icon4} className={classes.icon} style={{width:'60px'}}/>
                        <Typography variant="h6" className={classes.programName} style={{marginTop:'40px'}}>
                            DigiCitizen for Success
                        </Typography>
                        <Typography variant="body2" className={classes.docType}>
                            Assessment<br/><br/>
                        </Typography>
                        <Typography variant="body2" className={classes.description}>
                            Access your curated assessment or link to your LMS via API
                        </Typography>
                        <div className={classes.buttonContainer}>
                            <Button
                                variant="outlined"
                                className={classes.button}
                                fullWidth
                            >
                                Access
                            </Button>
                            <Button
                                variant="outlined"
                                className={classes.button}
                                fullWidth
                            >
                                Link API
                            </Button>
                        </div>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
                <Card className={classes.card}>
                    <CardContent>
                        <img src={Icon5} className={classes.icon} style={{marginTop:'5px'}}/>
                        <Typography variant="h6" className={classes.programName} style={{marginTop:'35px'}}>
                            DigiCitizen for Success
                        </Typography>
                        <Typography variant="body2" className={classes.docType}>
                            Analytics Dashboard
                        </Typography>
                        <Typography variant="body2" className={classes.description}>
                            Monitor and visualize your program’s impact
                        </Typography>
                        <div className={classes.buttonContainer}>
                                <Button
                                    variant="outlined"
                                    className={classes.button}
                                    fullWidth
                                >
                                   Access
                                </Button>
                        </div>
                    </CardContent>
                </Card>
            </Grid>
            </Grid>
        </Box>
    );
};

export default AccessProgram;
