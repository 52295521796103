
export const ALL_CURRICULA = [
    {
        "code": "dq_cm_1",
        "full_name": "Digital Citizen ID",
        "definition": "Essential digital skills required to use digital technology safely, responsibly, and ethically."
    },
    {
        "code": "dq_cm_2",
        "full_name": "Cyber Security 101",
        "definition": "Must-have digital skills required to safeguard personal and confidential data and devices against cyber threats and vulnerabilites"
    },
    {
        "code": "dq_cm_3",
        "full_name": "AI Literacy",
        "definition": "Digital skills required to effectively use artificial intelligence (AI).to enhance personal and professional capability while mitigating associated risks"
    },
    {
        "code": "dq_cm_4",
        "full_name": "Digital Wellbeing Skills",
        "definition": "Digital skills required to proactively manage and control factors contribuing to digital wellbeing, focusing on the balanced and civic use of digital technology"
    }
]

const dcid_data = [
    {
        unit_no: 1,
        unit_name: "Unit 1: Aware Digital Citizens",
        gsm_list: [
            "DQ1.1",
            "DQ26.1",
            "DQ1.3",
            "DQ6.1",
            "DQ6.2"
        ]
    },
    {
        unit_no: 2,
        unit_name: "Unit 2: Balanced Use of Technology",
        gsm_list: [
            "DQ2.1",
            "DQ2.2",
            "DQ2.3",
            "DQ2.5"
        ]
    },
    {
        unit_no: 3,
        unit_name: "Unit 3: Conduct Cyber-Risks Management",
        gsm_list: [
            "DQ3.4",
            "DQ3.1",
            "DQ3.2",
            "DQ3.3"
        ]
    },
    {
        unit_no: 4,
        unit_name: "Unit 4: Content and Contact Cyber-Risks Management",
        gsm_list: [
            "DQ11.3",
            "DQ27.3",
            "DQ11.1",
            "DQ11.2",
            "DQ27.1",
            "DQ27.2"
        ]
    },
]

export const CURRICULUM_SKILLS_BY_CODE = {
    dq_cm_1: dcid_data,
    dq_cm_2: dcid_data,
    dq_cm_3: dcid_data,
    dq_cm_4: dcid_data
}