

const LEARNING_GOALS_BY_GSM = {
    "DQ1.1": [
        {
            "id": 1,
            "title": "Summary Point",
            "description": "Digital identities are complex and can vary based on the intended audience, influencing how others perceive us."
        },
        {
            "id": 2,
            "title": "Summary Point",
            "description": "Managing online presence with integrity and understanding privacy is essential for building a positive digital reputation."
        }
    ],
    "DQ26.1": [
        {
            "id": 1,
            "title": "Summary Point",
            "description": "The internet offers a variety of activities that foster communication, learning, and entertainment."
        },
        {
            "id": 2,
            "title": "Summary Point",
            "description": "Staying aware of privacy settings and online safety can enhance your digital experience while protecting you from potential risks."
        }
    ],
    "DQ1.3": [
        {
            "id": 1,
            "title": "Summary Point",
            "description": "Good digital citizenship requires applying real-world values, like respect and kindness, to our online behavior."
        },
        {
            "id": 2,
            "title": "Summary Point",
            "description": "Recognizing and addressing negative behaviors online is essential for building a safer digital community."
        }
    ],
    "DQ6.1": [
        {
            "id": 1,
            "title": "Summary Point",
            "description": "A digital footprint encompasses all online activities, both intentional and unintentional, shaping your online identity."
        },
        {
            "id": 2,
            "title": "Summary Point",
            "description": "Being mindful of your digital footprint is essential for protecting your reputation and future opportunities."
        }
    ],
    "DQ6.2": [
        {
            "id": 1,
            "title": "Summary Point",
            "description": "A digital footprint consists of traces of our online activities and can impact our reputation and future opportunities."
        },
        {
            "id": 2,
            "title": "Summary Point",
            "description": "It is essential to manage your digital footprint carefully by sharing wisely, understanding privacy settings, and considering the permanence of your online actions."
        }
    ],
    "DQ2.1": [
        {
            "id": 1,
            "title": "Summary Point",
            "description": "Healthy digital habits involve managing screen time wisely for balanced living and overall well-being."
        },
        {
            "id": 2,
            "title": "Summary Point",
            "description": "Quality of screen time matters: engaging with meaningful content enhances learning and development."
        }
    ],
    "DQ2.2": [
        {
            "id": 1,
            "title": "Summary Point",
            "description": "Understanding technology addiction and its symptoms is vital for maintaining a balanced digital lifestyle."
        },
        {
            "id": 2,
            "title": "Summary Point",
            "description": "Creating a healthy routine involves mindfulness about screen time, engaging in self-reflection, and seeking communal support for improvement."
        }
    ],
    "DQ2.3": [
        {
            "id": 1,
            "title": "Summary Point",
            "description": "Developing a balanced approach to digital device usage is essential for overall well-being and positive mental health."
        },
        {
            "id": 2,
            "title": "Summary Point",
            "description": "Involving caregivers and establishing healthy routines fosters positive relationships and enables children to make informed choices about technology."
        }
    ],
    "DQ2.5": [
        {
            "id": 1,
            "title": "Summary Point",
            "description": "Balancing online and offline activities is essential for well-being and leads to healthier behaviors and relationships."
        },
        {
            "id": 2,
            "title": "Summary Point",
            "description": "Open communication with family about screen time rules fosters trust and responsible digital citizenship."
        }
    ],
    "DQ3.4": [
        {
            "id": 1,
            "title": "Summary Point",
            "description": "Cyberbullying is a serious issue that occurs through digital platforms, and it can have significant emotional effects on individuals."
        },
        {
            "id": 2,
            "title": "Summary Point",
            "description": "Taking action by reporting, supporting victims, and promoting kindness can contribute to a safer online community."
        }
    ],
    "DQ3.1": [
        {
            "id": 1,
            "title": "Summary Point",
            "description": "Cyberbullying is a harmful behavior using technology to bully others, leading to severe emotional and social consequences."
        },
        {
            "id": 2,
            "title": "Summary Point",
            "description": "Empowering individuals to recognize, report, and combat cyberbullying fosters a supportive and resilient online environment."
        }
    ],
    "DQ3.2": [
        {
            "id": 1,
            "title": "Summary Point",
            "description": "Cyberbullying can have serious effects, but reporting it and seeking help can contribute to safety and support."
        },
        {
            "id": 2,
            "title": "Summary Point",
            "description": "Making thoughtful decisions online, respecting others, and maintaining privacy are crucial for a positive digital experience."
        }
    ],
    "DQ3.3": [
        {
            "id": 1,
            "title": "Summary Point",
            "description": "Cyberbullying is a serious issue that can cause emotional harm and social isolation for victims while bystanders play a crucial role in its prevention."
        },
        {
            "id": 2,
            "title": "Summary Point",
            "description": "Creating a supportive online environment and understanding the consequences of cyberbullying can help foster kindness and respect in digital spaces."
        }
    ],
    "DQ11.3": [
        {
            "id": 1,
            "title": "Summary Point",
            "description": "Online grooming and unwanted contact pose significant risks to youth, highlighting the importance of online safety awareness."
        },
        {
            "id": 2,
            "title": "Summary Point",
            "description": "Establishing boundaries, recognizing warning signs, and knowing how to report problems are crucial for maintaining a safe online environment."
        }
    ],
    "DQ27.3": [
        {
            "id": 1,
            "title": "Summary Point",
            "description": "Understanding and recognizing online risks, including inappropriate content and dangerous interactions, is essential for safe internet use."
        },
        {
            "id": 2,
            "title": "Summary Point",
            "description": "Open conversations and parental controls can help manage digital experiences effectively and create a positive online environment for all."
        }
    ],
    "DQ11.1": [
        {
            "id": 1,
            "title": "Summary Point",
            "description": "Risky online contacts are individuals who might pose dangers to your safety and well-being, often displaying concerning behaviors."
        },
        {
            "id": 2,
            "title": "Summary Point",
            "description": "Protecting yourself involves recognizing red flags, maintaining privacy, and building healthy online relationships to support your emotional well-being."
        }
    ],
    "DQ11.2": [
        {
            "id": 1,
            "title": "Summary Point",
            "description": "Online friend requests can include both known friends and potential threats; always verify before accepting."
        },
        {
            "id": 2,
            "title": "Summary Point",
            "description": "Maintain privacy by adjusting settings, sharing selectively, and discussing online interactions with trusted adults."
        }
    ],
    "DQ27.1": [
        {
            "id": 1,
            "title": "Summary Point",
            "description": "Understanding the types and impacts of risky online content is essential for navigating the digital world safely."
        },
        {
            "id": 2,
            "title": "Summary Point",
            "description": "Discussing content age ratings and establishing communication about online experiences ensures better decision-making and safety among young individuals."
        }
    ],
    "DQ27.2": [
        {
            "id": 1,
            "title": "Summary Point",
            "description": "Understanding and identifying inappropriate content is key to staying safe online and seeking help when needed."
        },
        {
            "id": 2,
            "title": "Summary Point",
            "description": "Being an active digital citizen involves responsibly reporting issues and supporting others to maintain a positive online community."
        }
    ],
    "DQ0.0": [
        {
            "id": 1,
            "title": "Identify the importance of digital citizenship",
            "description": "Identify the importance of digital citizenship"
        },
        {
            "id": 2,
            "title": "Identify the importance of digital citizenship",
            "description": "Identify the importance of digital citizenship"
        },
    ]
}

export const getLearningGoalsByGSM = (gsm_no) => {
    return LEARNING_GOALS_BY_GSM[gsm_no] || LEARNING_GOALS_BY_GSM["DQ0.0"];
}