import React,{ useState} from 'react'
import {Box, Card, CardContent, Typography} from "@mui/material";
import Button from "@material-ui/core/Button";
import { makeStyles } from '@material-ui/core/styles';
import {Dialog, DialogTitle, DialogContent, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
const useStyles = makeStyles((theme) => ({
    Title: {
        width: '100%',
        padding: '4px',
        borderRadius:'10px',
        marginBottom: '16px',
        textAlign: 'center',
        backgroundColor: '#F17632',
        color: '#fff',
    },
    objectiveCard: {
        marginTop: theme.spacing(3),
        boxShadow:'none !important',
        borderRadius:'0px !important',
    },
    objectiveContent: {
        padding: theme.spacing(2),
    },
    sectionTitle: {
        color: '#ef6528',
        marginBottom: '10px !important',
    },
    list: {
        listStyleType: 'none',
        marginTop:'10px',
        paddingLeft: theme.spacing(4),
        '& li': {
            marginBottom: theme.spacing(1),
        },
    },
    tag:{
        position: 'absolute',
        top: '-2px',
        left: '-2px',
        backgroundColor: '#06215B',
        color: '#fff',
        padding: '5px 10px',
        borderRadius: '5px',
        fontSize: '18px',
    },
    perspectiveCard:{
        border: '2px dotted #4294A5',
        boxShadow:'none !important',
        borderRadius:'15px !important',
        position: 'relative',
        marginTop: '20px',
    },
    viewButton: {
        color: '#ef6528',
        border: '1px solid #ef6528',
    },
    popupTitle: {
        color: '#06215B',
        textAlign:'center',
    },
    bulletTitle: {
        fontWeight: 'bold',
    },
    accordionTitle: {
        fontWeight: 'bold',
    },
    accordionDetails: {
        color: '#06215B',
    },
    dialogContent: {
        display: 'flex',
        justifyContent: 'stretch',
        alignItems: 'center',
        flexDirection: 'column',
    },
    accordionContainer: {
        width: '80%',
        maxWidth: '800px',
        marginTop: theme.spacing(2),
    },
    customDialog: {
        maxWidth: 'none !important',
        maxHeight: '600px !important',
        padding: 'none !important',
        width: '900px',

    },
}));
const ApplicationActivity = ({ selectedLesson}) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    if (!selectedLesson) return null;
    return (
        <>
            <Box style={{width: '100%'}}>
                <Typography variant="h5" className={classes.Title}>
                    {selectedLesson?.verbose_name}
                </Typography>

                {/* 1st Card */}
                <Card className={classes.objectiveCard}>
                    <CardContent className={classes.objectiveContent}>
                        <Typography variant="h6" className={classes.sectionTitle}>
                            Learning Objectives:
                        </Typography>
                        <ul className={classes.list}>
                            <li>
                                <Typography variant="body2">objective......</Typography>
                            </li>
                        </ul>
                    </CardContent>
                </Card>

                {/* 2nd Card */}
                <Card className={classes.perspectiveCard}>
                    <Box className={classes.tag}>Taking Perspective</Box>
                    <CardContent>
                        <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="20px"
                             marginTop="30px">
                            <div style={{display: 'flex', flexDirection: 'column', width: '75%'}}>
                                <Typography variant="h6" style={{color: '#06215B', textAlign: 'center'}}>
                                    Debate
                                </Typography>
                                <Typography variant="body2" style={{color: '#06215B'}}>
                                    They are typically pros versus cons regarding a proposition “School should allow
                                    digital devices at classes”.
                                </Typography>
                            </div>
                            <Box display="flex" flexDirection="column" marginTop="10px" marginRight="10px">
                                <Button
                                    variant="outlined"
                                    style={{color: '#ef6528', border: '1px solid #ef6528', marginBottom: '10px'}}
                                    onClick={handleClickOpen}
                                >
                                    View Sample
                                </Button>
                                <Button variant="outlined" style={{color: '#ef6528', border: '1px solid #ef6528'}}>
                                    Remove
                                </Button>
                            </Box>
                        </Box>

                        <Dialog open={open} onClose={handleClose} classes={{paper: classes.customDialog}}>
                            <DialogTitle className={classes.popupTitle}>Analyzing and Evaluating:</DialogTitle>
                            <DialogContent className={classes.dialogContent}>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography className={classes.accordionTitle}>Analyze and evaluate a video, story, artistic work, or
                                            other creation:</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography className={classes.accordionDetails}>
                                            This exercise is the backbone of many business educational programs. The instructor previously assigned the attached case [ATTACH], which learners have already read. For example, the case might document why a
                                            business failed, illustrate a program that successfully addresses an environmental problem, or provide insights into some other area that bears on business. The learners now analyze the case
                                            to help them achieve the learning objective. Learners explain how the case bears on the learning objective.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                    >
                                        <Typography className={classes.accordionTitle}>Analyze and evaluate by annotating:</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography className={classes.accordionDetails}>
                                            This exercise is the backbone of many business educational programs. The instructor previously assigned the attached case [ATTACH], which learners have already read. For example, the case might document why a
                                            business failed, illustrate a program that successfully addresses an environmental problem, or provide insights into some other area that bears on business. The learners now analyze the case
                                            to help them achieve the learning objective. Learners explain how the case bears on the learning objective.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel3a-content"
                                        id="panel3a-header"
                                    >
                                        <Typography className={classes.accordionTitle}>Analyze and evaluate a case study:</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography className={classes.accordionDetails}>
                                            This exercise is the backbone of many business educational programs. The instructor previously assigned the attached case [ATTACH], which learners have already read. For example, the case might document why a
                                            business failed, illustrate a program that successfully addresses an environmental problem, or provide insights into some other area that bears on business. The learners now analyze the case
                                            to help them achieve the learning objective. Learners explain how the case bears on the learning objective.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel4a-content"
                                        id="panel4a-header"
                                    >
                                        <Typography className={classes.accordionTitle}>Analyze and define a problem:</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography className={classes.accordionDetails}>
                                            This exercise is the backbone of many business educational programs. The instructor previously assigned the attached case [ATTACH], which learners have already read. For example, the case might document why a
                                            business failed, illustrate a program that successfully addresses an environmental problem, or provide insights into some other area that bears on business. The learners now analyze the case
                                            to help them achieve the learning objective. Learners explain how the case bears on the learning objective.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </DialogContent>
                        </Dialog>

                        <Box display="flex" justifyContent="space-between" alignItems="center" marginTop="30px">
                            <div style={{display: 'flex', flexDirection: 'column', width: '75%'}}>
                                <Typography variant="h6" style={{color: '#06215B', textAlign: 'center'}}>
                                    Role-Playing
                                </Typography>
                                <Typography variant="body2" style={{color: '#06215B'}}>
                                    This is a two-person role-playing game and addresses the current learning
                                    objectives.
                                </Typography>
                            </div>
                            <Box display="flex" flexDirection="column" marginTop="10px" marginRight="10px">
                                <Button variant="outlined"
                                        style={{color: '#ef6528', border: '1px solid #ef6528', marginBottom: '10px'}}>
                                    View Sample
                                </Button>
                                <Button variant="outlined" style={{color: '#ef6528', border: '1px solid #ef6528'}}>
                                    Select
                                </Button>
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </>
    );
}

export default ApplicationActivity;