
function groupByGSMNo(data) {
    return data.reduce((acc, item) => {
        const { gsm_no } = item;
        if (!acc.has(gsm_no)) {
            acc.set(gsm_no, []);
        }
        acc.get(gsm_no).push(item);
        return acc;
    }, new Map());
}

const data = [
    {
        "gsm_no": "DQ1.1",
        "code": "ConstOnlinePersona",
        "full_name": "Understanding the Construction of Online Personas",
        "student_outcomes": "Students demonstrate a clear understanding of how their self-image (or persona) is constructed and represented in digital environments.",
        "duration_minuites": 10
    },
    {
        "gsm_no": "DQ1.1",
        "code": "MultiOnlinePersona",
        "full_name": "Creating Multiple Personas Based on Needs",
        "student_outcomes": "Students effectively create and manage multiple online identities or personas, tailored to different contexts such as personal and professional lives.",
        "duration_minuites": 10
    },
    {
        "gsm_no": "DQ1.1",
        "code": "ImpactOnlinePersona",
        "full_name": "Understanding the Impact of Technology on Self-Image and Values",
        "student_outcomes": "Students critically analyze and understand the impact of technology on their self-image and values, including influences from digital media like video games, advertising, and systemic stereotypes.",
        "duration_minuites": 10
    },
    {
        "gsm_no": "DQ26.1",
        "code": "InternetAct",
        "full_name": "General Internet Activities",
        "student_outcomes": "Students are able to apply general use of the internet for a broad range of activities (e.g., visiting websites, online shopping, sending emails).",
        "duration_minuites": 10
    },
    {
        "gsm_no": "DQ26.1",
        "code": "DMBeh",
        "full_name": "Digital Media Behavior",
        "student_outcomes": "Students reflect on their general digital media usage behaviors such as playing computer games and using social media.",
        "duration_minuites": 10
    },
    {
        "gsm_no": "DQ1.3",
        "code": "AttGlobal",
        "full_name": "Attitude Toward Global Digital Interactions",
        "student_outcomes": "Students have a positive attitude toward global digital interactions.",
        "duration_minuites": 10
    },
    {
        "gsm_no": "DQ1.3",
        "code": "GlobalCitizen",
        "full_name": "Global Citizenship Ability",
        "student_outcomes": "Students can effectively control and shape their digital identity by creating and curating their online presence to tell their own stories, engaging with people from diverse cultures, and demonstrating global awareness in a way that is non-discriminatory and culturally sensitive.",
        "duration_minuites": 10
    },
    {
        "gsm_no": "DQ1.3",
        "code": "Netiquette",
        "full_name": "Able to Be Ethical and Considerate Online",
        "student_outcomes": "Students are able to demonstrate ethical and considerate behavior, as well as proper netiquette, when using technology across various audiences.",
        "duration_minuites": 10
    },
    {
        "gsm_no": "DQ6.1",
        "code": "DefDF",
        "full_name": "Understand the Definition of Digital Footprints",
        "student_outcomes": "Students demonstrate an understanding of the definition of digital footprints.",
        "duration_minuites": 10
    },
    {
        "gsm_no": "DQ6.1",
        "code": "ShapNatDF",
        "full_name": "Understand the Shapeable Nature of Digital Footprints",
        "student_outcomes": "Students understand that their digital footprint can be shaped by others and recognize how this can happen, such as through other people's posts that could negatively impact their digital presence.",
        "duration_minuites": 10
    },
    {
        "gsm_no": "DQ6.1",
        "code": "TypeDF",
        "full_name": "Understand the Types of Digital Footprints",
        "student_outcomes": "Students distinguish between active and passive digital footprints, understanding that active footprints are created by willingly sharing data, while passive footprints are generated when data is collected without their knowledge.",
        "duration_minuites": 10
    },
    {
        "gsm_no": "DQ6.2",
        "code": "NatureDF",
        "full_name": "Understanding the Nature of Online Activity",
        "student_outcomes": "Students recognize that their online activities can be traced, understanding that these traces are often permanent and can potentially go viral.",
        "duration_minuites": 10
    },
    {
        "gsm_no": "DQ6.2",
        "code": "DFRepLife",
        "full_name": "Understand the Connection Between Digital Footprints and Reputation (Life)",
        "student_outcomes": "Students understand that their digital footprint represents them and can influence their reputation and life outcomes.",
        "duration_minuites": 10
    },
    {
        "gsm_no": "DQ2.1",
        "code": "UnderageSMUse",
        "full_name": "Under-age social media use",
        "student_outcomes": "Students understand the percentage of children under 13 who actively use social media.",
        "duration_minuites": 10
    },
    {
        "gsm_no": "DQ2.1",
        "code": "HighGA",
        "full_name": "High Gaming Activity",
        "student_outcomes": "Students recognize the percentage of children (8-18 years old) who engage in high levels of gaming activity.",
        "duration_minuites": 10
    },
    {
        "gsm_no": "DQ2.1",
        "code": "MobileOwn",
        "full_name": "Mobile Ownership for Children",
        "student_outcomes": "Students understand the ownership rates of mobile devices (smartphones, tablets, or other mobile devices) with internet access.",
        "duration_minuites": 10
    },
    {
        "gsm_no": "DQ2.1",
        "code": "STEntPerc",
        "full_name": "Excessive screen time",
        "student_outcomes": "Students recognize the percentage of people who have extensive screen time (30 or more hours per week for entertainment use).",
        "duration_minuites": 10
    },
    {
        "gsm_no": "DQ2.1",
        "code": "STEnt",
        "full_name": "Weekly Screen Time",
        "student_outcomes": "Students understand the extent of a person’s weekly screen time for entertainment purposes compared to educational or work purposes.",
        "duration_minuites": 10
    },
    {
        "gsm_no": "DQ2.2",
        "code": "AwareSTHabits",
        "full_name": "Awareness of Screen Time Habits",
        "student_outcomes": "Students demonstrate self-awareness of their screen time habits.",
        "duration_minuites": 10
    },
    {
        "gsm_no": "DQ2.2",
        "code": "GAddict",
        "full_name": "Game Addiction",
        "student_outcomes": "Students recognize and understand symptoms of gaming disorder.",
        "duration_minuites": 10
    },
    {
        "gsm_no": "DQ2.2",
        "code": "SMAddict",
        "full_name": "Social Media Addiction",
        "student_outcomes": "Students recognize and understand symptoms of social media addiction.",
        "duration_minuites": 10
    },
    {
        "gsm_no": "DQ2.2",
        "code": "DMAddict",
        "full_name": "Digital Media Addiction",
        "student_outcomes": "Students recognize and understand symptoms of technology addiction, including gaming, video viewing, and social media use for entertainment purposes.",
        "duration_minuites": 10
    },
    {
        "gsm_no": "DQ2.2",
        "code": "DiagTechAddict",
        "full_name": "Technology Addiction",
        "student_outcomes": "Students demonstrate understanding of technology disorder based on DSM-V diagnostic criteria.",
        "duration_minuites": 10
    },
    {
        "gsm_no": "DQ2.3",
        "code": "KnowDepthUnbalWell",
        "full_name": "Impact of Unbalanced Digital Use (Advanced)",
        "student_outcomes": "Students have an in-depth understanding of how unbalanced tech use are able to affect well-being.",
        "duration_minuites": 10
    },
    {
        "gsm_no": "DQ2.3",
        "code": "KnowExcSTWell",
        "full_name": "Impact of Excessive Screen Time",
        "student_outcomes": "Students demonstrate an understanding of how excessive screen use are able to negatively affect well-being.",
        "duration_minuites": 10
    },
    {
        "gsm_no": "DQ2.3",
        "code": "KnowUnbalDigitalUse",
        "full_name": "Understanding Unbalanced Digital Use",
        "student_outcomes": "Students demonstrate an understanding of what unbalanced digital use is.",
        "duration_minuites": 10
    },
    {
        "gsm_no": "DQ2.5",
        "code": "Devicefree",
        "full_name": "Device-free zones",
        "student_outcomes": "Students favor device-free times and places, such as device free family meals or agreements to keep bedrooms device free.",
        "duration_minuites": 10
    },
    {
        "gsm_no": "DQ2.5",
        "code": "LimitST",
        "full_name": "Limiting Screen Time",
        "student_outcomes": "Students favor and have exhibited behaviors of limiting screen time and multitasking using various tools and strategies.",
        "duration_minuites": 10
    },
    {
        "gsm_no": "DQ2.5",
        "code": "RuleST",
        "full_name": "Screen Time Rule",
        "student_outcomes": "Students favor and have exhibited behaviors of developing and following the screen time rules.",
        "duration_minuites": 10
    },
    {
        "gsm_no": "DQ3.4",
        "code": "BehCB",
        "full_name": "Past Behavior - Cyber Bullying Incidences",
        "student_outcomes": "Students recognize their involvement in cyberbullying, if applicable.",
        "duration_minuites": 10
    },
    {
        "gsm_no": "DQ3.4",
        "code": "BehCV",
        "full_name": "Past Behavior - Cyber Victimization Incidences",
        "student_outcomes": "Students recognize their experience of being cyberbullied, if applicable.",
        "duration_minuites": 10
    },
    {
        "gsm_no": "DQ3.4",
        "code": "BehOtherBehCR",
        "full_name": "Past Behavior - Other Behavioral Cyber Risk Incidences",
        "student_outcomes": "Students understand their exposure to other cyber risks, such as doxing, trolling, and online harassment, if applicable.",
        "duration_minuites": 10
    },
    {
        "gsm_no": "DQ3.1",
        "code": "EffectsCB",
        "full_name": "Impact of Behavioral Cyber Risks",
        "student_outcomes": "Students demonstrate an understanding of how behavioral cyber-risks might affect the victims.",
        "duration_minuites": 10
    },
    {
        "gsm_no": "DQ3.1",
        "code": "NatureCB",
        "full_name": "Nature of Behavioral Cyber Risks",
        "student_outcomes": "Students demonstrate an understanding of the nature of cyberbullying (definition of cyberbullying, different actors of cyberbullying), and they understand how these risks are able to be started and escalated.",
        "duration_minuites": 10
    },
    {
        "gsm_no": "DQ3.2",
        "code": "CommSkillsCB",
        "full_name": "Communicative Skills - Behavioral Cyber Risks",
        "student_outcomes": "Students possess communicative skills to address behavioral cyber-risk incidents as they occur as a victim.",
        "duration_minuites": 10
    },
    {
        "gsm_no": "DQ3.2",
        "code": "CopeNegCB",
        "full_name": "Coping with Negative Cyber Experiences",
        "student_outcomes": "Students are able to socially and emotionally cope with negative online experiences such as cyberbullying when he/she witnesses them without escalating the situation.",
        "duration_minuites": 10
    },
    {
        "gsm_no": "DQ3.2",
        "code": "DealCB",
        "full_name": "Socio-Cognitive Skills - Behavioral Cyber Risks",
        "student_outcomes": "Students know how to formulate strategies for dealing with behavioral cyber-risks and have socio-cognitive skills to address incidents as they occur a victim.",
        "duration_minuites": 10
    },
    {
        "gsm_no": "DQ3.2",
        "code": "TechnicalSkillsCB",
        "full_name": "Technical Skills - Behavioral Cyber Risks",
        "student_outcomes": "Students hold technical skills to address behavioral cyber-risk incidents as they occur as a victim.",
        "duration_minuites": 10
    },
    {
        "gsm_no": "DQ3.3",
        "code": "AttComfCB",
        "full_name": "Comfort - Behavioral Cyber Risks",
        "student_outcomes": "Students are comfortable with cyberbullying because of anonymity, distance, or the fact that it allows for retaliation even if a person is not physically strong.",
        "duration_minuites": 10
    },
    {
        "gsm_no": "DQ3.3",
        "code": "AttSocialNormCB",
        "full_name": "Social Norm - Behavioral Cyber Risks",
        "student_outcomes": "Students believe that people in their social circle (e.g., friends, family) have a positive or negative attitude toward cyberbullying.",
        "duration_minuites": 10
    },
    {
        "gsm_no": "DQ11.3",
        "code": "InformContactRisk",
        "full_name": "Informed Others About Contact with People Met Online",
        "student_outcomes": "Students understand the importance of informing others, such as friends or family, about offline contact with someone they first met online.",
        "duration_minuites": 10
    },
    {
        "gsm_no": "DQ11.3",
        "code": "SexualConvo",
        "full_name": "Involvement with Sexual Conversation",
        "student_outcomes": "Students recognize and understand the potential risks of exposure to sexual conversations online.",
        "duration_minuites": 10
    },
    {
        "gsm_no": "DQ11.3",
        "code": "StrangerRisk",
        "full_name": "Contact with People Met Online",
        "student_outcomes": "Students know not to engage in online or offline contact with someone they first met online and understand the implications of doing so.",
        "duration_minuites": 10
    },
    {
        "gsm_no": "DQ11.3",
        "code": "UnwantedContactRisk",
        "full_name": "Unwanted Contact Online",
        "student_outcomes": "Students identify and manage unwanted online contact, including unwanted sexual contact and inappropriate interactions, especially when involving children.",
        "duration_minuites": 10
    },
    {
        "gsm_no": "DQ27.3",
        "code": "ExpContRisk",
        "full_name": "Exposure to Content Risks",
        "student_outcomes": "Students recognize exposure to content risks, including hateful, racist, discriminatory content, or image-based abuse.",
        "duration_minuites": 10
    },
    {
        "gsm_no": "DQ27.3",
        "code": "GameViolenceRisk",
        "full_name": "Exposure to Violence in Video Games",
        "student_outcomes": "Students understand their exposure to media violence through playing video games.",
        "duration_minuites": 10
    },
    {
        "gsm_no": "DQ27.3",
        "code": "ViolentContentRisk",
        "full_name": "Exposure to Violent Content",
        "student_outcomes": "Students identify encounters with violent content online.",
        "duration_minuites": 10
    },
    {
        "gsm_no": "DQ27.3",
        "code": "SexualContentRisk",
        "full_name": "Exposure to Inappropriate Content",
        "student_outcomes": "Students recognize encounters with inappropriate adult content online.",
        "duration_minuites": 10
    },
    {
        "gsm_no": "DQ27.3",
        "code": "WatchingViolenceRisk",
        "full_name": "Exposure to Violence in Videos, Shows, and Movies",
        "student_outcomes": "Students understand their exposure to media violence through watching videos, shows, or movies.",
        "duration_minuites": 10
    },
    {
        "gsm_no": "DQ11.1",
        "code": "ImpactRiskContact",
        "full_name": "Know Impact of Risky Contacts",
        "student_outcomes": "Students know the impact of risky contacts .",
        "duration_minuites": 10
    },
    {
        "gsm_no": "DQ11.1",
        "code": "NatureRiskContact",
        "full_name": "Know Nature of Risky Contacts",
        "student_outcomes": "Students know the nature of risky contacts including the definition and different types.",
        "duration_minuites": 10
    },
    {
        "gsm_no": "DQ11.2",
        "code": "AvoidRiskContact",
        "full_name": "Know How to Avoid Risky Contacts",
        "student_outcomes": "Students knows how to avoid risky contact (unwanted sexual contact, or online grooming, etc.). They use cognitive, social communicative strategies and tactics to avoid and manage them.",
        "duration_minuites": 10
    },
    {
        "gsm_no": "DQ11.2",
        "code": "IdentifyRiskContact",
        "full_name": "Know How to Identify Risky Contact",
        "student_outcomes": "Students knows how to identify risky contact (unwanted sexual contact, or online grooming, etc.).",
        "duration_minuites": 10
    },
    {
        "gsm_no": "DQ11.2",
        "code": "TechBlockCC",
        "full_name": "Ability to Block Unwanted Contact",
        "student_outcomes": "Students are able to use technical skills to block unwanted contacts and content in online spaces.",
        "duration_minuites": 10
    },
    {
        "gsm_no": "DQ27.1",
        "code": "BasicImpactContRisk",
        "full_name": "Impact of Content Risks",
        "student_outcomes": "Students have basic knowledge on the impact of content risks.",
        "duration_minuites": 10
    },
    {
        "gsm_no": "DQ27.1",
        "code": "BasicKnowContRisk",
        "full_name": "Basic Knowledge on Content Risks",
        "student_outcomes": "Students demonstrate an understanding of the basic concept of content risks including the definition and different types.",
        "duration_minuites": 10
    },
    {
        "gsm_no": "DQ27.1",
        "code": "ImpactRiskContent",
        "full_name": "Know Impact of Risky Content",
        "student_outcomes": "Students know the impact of risky content on mental health and well-being.",
        "duration_minuites": 10
    },
    {
        "gsm_no": "DQ27.1",
        "code": "NatureRiskContent",
        "full_name": "Know Nature of Risky Content",
        "student_outcomes": "Students know the nature of risky content including the definition and different types.",
        "duration_minuites": 10
    },
    {
        "gsm_no": "DQ27.2",
        "code": "ContConsent",
        "full_name": "Content Consent",
        "student_outcomes": "Students knows the importance of consent and how to be consensual.",
        "duration_minuites": 10
    },
    {
        "gsm_no": "DQ27.2",
        "code": "HelpContRisk",
        "full_name": "Seeking Help - Content Risks",
        "student_outcomes": "Students have relevant skills in seeking help when becoming the victim of content risks.",
        "duration_minuites": 10
    },
    {
        "gsm_no": "DQ27.2",
        "code": "RemContRisk",
        "full_name": "Removing Content Risks",
        "student_outcomes": "Students knows the importance of removing harmful content and how to do so.",
        "duration_minuites": 10
    },
    {
        "gsm_no": "DQ27.2",
        "code": "RepContRisk",
        "full_name": "Reporting Content Risks",
        "student_outcomes": "Students know about reporting harmful content and how to do so.",
        "duration_minuites": 10
    },
    {
        "gsm_no": "DQ27.2",
        "code": "AvoidRiskContent",
        "full_name": "Know How to Avoid Risky Content",
        "student_outcomes": "Students knows how to avoid risky content (such as violent content or sexual content intended for adults). They use cognitive, social communicative strategies and tactics to avoid and manage them.",
        "duration_minuites": 10
    },
    {
        "gsm_no": "DQ27.2",
        "code": "IdentifyRiskContent",
        "full_name": "Know How to Identify Risky Content",
        "student_outcomes": "Students knows how to identify risky content (such as violent content or sexual content intended for adults).",
        "duration_minuites": 10
    },
    {
        "gsm_no": "DQ0.0",
        "code": "Default",
        "full_name": "Place Holder Outcome",
        "student_outcomes": "This the Default outcome for GSM, which have missing data in system",
        "duration_minuites": 5
    }
]

const final_data = groupByGSMNo(data);

export const getOutcomeByGSMNo = (gsm_no) => {
    return final_data.get(gsm_no) || final_data.get("DQ0.0");
}