
const LEARNING_SLIDES_BY_GSM = {
    "DQ1.1": [
        {
            "id": 1,
            "main_heading": "Understanding Digital Identities",
            "body": [
                {
                    "title": "What is a Digital Identity?",
                    "content": "A digital identity comprises the personal details and personas you create online. This includes social media profiles, usernames, and the content you share across various platforms."
                },
                {
                    "title": "Types of Digital Identities",
                    "content": "Individuals can have multiple digital identities, crafted for different audiences, such as one for family and another for friends. This allows for varied self-expression depending on the context."
                },
                {
                    "title": "The Impact of Online Representation",
                    "content": "How you present yourself online can influence people's perceptions of you. It's essential to be aware that your online actions contribute significantly to your overall reputation."
                }
            ]
        },
        {
            "id": 2,
            "main_heading": "The Fluidity of Identity",
            "body": [
                {
                    "title": "The Concept of Identity Play",
                    "content": "Identity play refers to the experimentation with different versions of ourselves online. This can help individuals understand their identities and explore new aspects of their personality."
                },
                {
                    "title": "Contextual Identity Presentation",
                    "content": "Just as we behave differently with family and friends, our online personas may vary depending on the audience. It's crucial to adapt while maintaining core values."
                },
                {
                    "title": "Benefits and Risks of Identity Play",
                    "content": "While identity play encourages self-exploration, it can also lead to misunderstandings or misrepresentation online. Balancing authenticity and exploration is key to a healthy digital identity."
                }
            ]
        },
        {
            "id": 3,
            "main_heading": "Managing Online Presence",
            "body": [
                {
                    "title": "Building a Positive Online Reputation",
                    "content": "Consistently sharing content that reflects your true interests and values helps build a positive digital identity. Think about what message you want to convey to others."
                },
                {
                    "title": "Privacy Management Online",
                    "content": "Understanding privacy settings is vital. It's important to know which details to share publicly and which to keep private, ensuring safety in your digital interactions."
                },
                {
                    "title": "Consequences of Online Actions",
                    "content": "Posts and interactions can have long-lasting effects, impacting your relationships and opportunities. Always consider the potential repercussions before engaging online."
                }
            ]
        },
        {
            "id": 4,
            "main_heading": "Ethics and Integrity in Digital Spaces",
            "body": [
                {
                    "title": "The Importance of Online Integrity",
                    "content": "Upholding honesty and transparency in your online interactions is essential. A trustworthy digital identity fosters strong connections and positive interactions."
                },
                {
                    "title": "Respecting Others' Identities",
                    "content": "Acknowledging the various aspects of others' digital identities and respecting their anonymity or preferences fosters a supportive online community. Empathy is crucial in digital interactions."
                },
                {
                    "title": "Critical Evaluation of Information",
                    "content": "Learning to critically assess the veracity and implications of online content is a vital skill. This helps avoid misinformation and supports informed digital citizenship."
                }
            ]
        }
    ],
    "DQ26.1": [
        {
            "id": 1,
            "main_heading": "Exploring the Internet and Digital Activities",
            "body": [
                {
                    "title": "What Can We Do Online?",
                    "content": "The internet offers a wide range of activities, such as chatting with friends, shopping, and sharing photos. It also provides access to information, entertainment, and educational resources to learn new skills or hobbies."
                },
                {
                    "title": "Why Use Social Media?",
                    "content": "Social media platforms help connect people across the globe. They allow us to share experiences, stay updated on news, and even form communities around shared interests with friends and family."
                },
                {
                    "title": "Staying Safe Online",
                    "content": "It's essential to be cautious while using the internet. Always think about your privacy, choose strong passwords, and discuss with trusted adults to ensure a safe online experience."
                }
            ]
        },
        {
            "id": 2,
            "main_heading": "Using Devices for Digital Connection",
            "body": [
                {
                    "title": "Types of Devices",
                    "content": "Various devices like smartphones, tablets, and laptops enable us to communicate effectively online. Choosing the right device based on your needs can enhance your experience and accessibility of information."
                },
                {
                    "title": "Communicating with Friends",
                    "content": "Whether through text, video calls, or social media, technology allows us to stay connected with friends and family easily. We can share updates, have fun conversations, or even engage in multiplayer games."
                },
                {
                    "title": "Learning New Skills Online",
                    "content": "The internet provides numerous resources for learning, such as online courses or tutorials. Platforms like Khan Academy and YouTube can help you explore topics of interest at your own pace."
                }
            ]
        },
        {
            "id": 3,
            "main_heading": "Creating Online Profiles and Privacy",
            "body": [
                {
                    "title": "Importance of Profiles",
                    "content": "Creating online profiles helps you express your personality and connect with others. Choosing an appropriate username and sharing suitable information is crucial for making positive impressions online."
                },
                {
                    "title": "Managing Privacy Settings",
                    "content": "Understanding privacy settings on social media and other platforms helps you control who can see your posts. Review and adjust these settings to keep your personal information safe and secure."
                },
                {
                    "title": "Identifying Risks",
                    "content": "While the internet is fun, it also poses risks like cyberbullying or sharing too much information. Always be mindful of what you share and discuss any concerns with trusted adults."
                }
            ]
        },
        {
            "id": 4,
            "main_heading": "Fun Activities Online",
            "body": [
                {
                    "title": "Games and Entertainment",
                    "content": "Online games are a popular way to engage with others and enjoy leisure time. Always check age ratings and community rules while playing multiplayer games to ensure a positive experience."
                },
                {
                    "title": "Exploring Content Creation",
                    "content": "Creating videos, music, or blog content can be fulfilling. Platforms like TikTok or YouTube encourage creativity and allow users to share their work with an audience."
                },
                {
                    "title": "Virtual Communities",
                    "content": "Many online spaces foster community interaction, such as forums or social media groups. Joining these can help you connect with others who share your interests and learn from one another."
                }
            ]
        }
    ],
    "DQ1.3": [
        {
            "id": 1,
            "main_heading": "Understanding Good Digital Citizenship",
            "body": [
                {
                    "title": "What is Good Citizenship?",
                    "content": "Good citizenship includes respecting laws, being compassionate, and helping your community. In the real world, these values foster a safe, supportive society for everyone."
                },
                {
                    "title": "Characteristics of a Good Citizen",
                    "content": "Traits like helpfulness, honesty, and respect are essential. Good citizens volunteer, obey rules, and treat everyone with dignity and kindness."
                },
                {
                    "title": "Transferring Values to the Online World",
                    "content": "The values of good citizenship should apply online, where actions can impact digital identities and relationships. Respectful behavior fosters a positive online community."
                }
            ]
        },
        {
            "id": 2,
            "main_heading": "The Importance of Digital Citizenship",
            "body": [
                {
                    "title": "Why Be a Good Digital Citizen?",
                    "content": "Good digital citizenship ensures our online behavior is respectful and safe, nurturing healthy interactions with others in digital spaces."
                },
                {
                    "title": "Impact on Community",
                    "content": "Our behavior online affects the larger community, creating a safer and more inclusive environment for everyone. We all contribute to this collective space."
                },
                {
                    "title": "Legal Consequences of Online Actions",
                    "content": "Misconduct online, like harassment, can lead to serious legal implications. It’s crucial to act responsibly to avoid these consequences."
                }
            ]
        },
        {
            "id": 3,
            "main_heading": "Identifying Negative Online Behavior",
            "body": [
                {
                    "title": "Recognizing Poor Digital Citizenship",
                    "content": "Engage in discussions about negative online behavior. Children can share experiences they have witnessed, like cyberbullying or inappropriate language, anonymously to promote comfort."
                },
                {
                    "title": "Discussing Personal Experiences",
                    "content": "Creating a safe space allows students to share their thoughts on negative behaviors and learn from each other’s experiences, fostering a culture of awareness."
                },
                {
                    "title": "Collective Responsibility Online",
                    "content": "Everyone plays a role in making online spaces safe. Each individual’s actions contribute to a culture of respect and kindness."
                }
            ]
        },
        {
            "id": 4,
            "main_heading": "Promoting Positive Online Interactions",
            "body": [
                {
                    "title": "Ways to Demonstrate Good Digital Citizenship",
                    "content": "Students can actively engage by sharing accurate information, being respectful, and standing against cyberbullying. Each action contributes positively to the digital community."
                },
                {
                    "title": "Importance of Consent and Respect",
                    "content": "Always seek consent before sharing images and respect others’ privacy and feelings in all online interactions. This builds trust and respect."
                },
                {
                    "title": "Building Respectful Online Relationships",
                    "content": "Encourage open communication and understanding among peers. Respectful interactions lead to lasting positive connections."
                }
            ]
        },
        {
            "id": 5,
            "main_heading": "Exploring Global Citizenship",
            "body": [
                {
                    "title": "Understanding Global Connection",
                    "content": "Being a digital citizen means recognizing the interconnectedness of our online actions. Respecting cultural differences enriches our global community."
                },
                {
                    "title": "Tolerance and Respect for Diversity",
                    "content": "Embracing diversity allows us to build bridges across cultures. Understanding varied perspectives fosters empathy and cooperation in online spaces."
                },
                {
                    "title": "Encouraging Active Participation",
                    "content": "Every voice matters in the digital world. Engaging respectfully in discussions and advocating for social change helps cultivate a vibrant, inclusive community."
                }
            ]
        }
    ],
    "DQ6.1": [
        {
            "id": 1,
            "main_heading": "Understanding Digital Footprints",
            "body": [
                {
                    "title": "What is a Digital Footprint?",
                    "content": "A digital footprint is the sum of all your online activities, including photos, comments, and messages. Every time you use the internet, you create data that generates your unique online identity."
                },
                {
                    "title": "Active vs. Passive Digital Footprints",
                    "content": "Active footprints occur from intentional actions like posting or commenting. Passive footprints are created without direct intent, such as browsing sites or location data collected by apps."
                },
                {
                    "title": "Why Digital Footprints Matter",
                    "content": "Digital footprints can affect your reputation and future opportunities. Your online presence is often viewed by colleges and employers, impacting their impressions of you."
                }
            ]
        },
        {
            "id": 2,
            "main_heading": "The Permanence of Online Actions",
            "body": [
                {
                    "title": "Once It's Online, It's There Forever",
                    "content": "Anything you post online can be saved, shared, or copied by others, making it potentially permanent. Deleting posts does not guarantee they are erased entirely."
                },
                {
                    "title": "Think Before You Post",
                    "content": "Always evaluate your content with questions: Is it true? Is it helpful? Is it necessary? Asking these will guide your decisions on what to share."
                },
                {
                    "title": "Protecting Your Online Reputation",
                    "content": "Maintaining a positive online presence is vital, as it can influence college admissions and job prospects in your future. Thoughtful sharing can safeguard your opportunities."
                }
            ]
        },
        {
            "id": 3,
            "main_heading": "What Contributes to My Digital Footprint?",
            "body": [
                {
                    "title": "Types of Online Actions",
                    "content": "Your digital footprint is influenced by social media posts, comments, online purchases, and emails. Each action creates a small part of your digital identity."
                },
                {
                    "title": "Unintentional Digital Footprints",
                    "content": "Many online actions leave behind passive footprints. Even browsing or using certain apps generates data that reflects your online behavior and preferences."
                },
                {
                    "title": "Metadata and Geolocation",
                    "content": "Metadata is additional information attached to files, like location data in photos. This hidden data can expand your digital footprint without your direct awareness."
                }
            ]
        },
        {
            "id": 4,
            "main_heading": "Managing Your Digital Footprint",
            "body": [
                {
                    "title": "Checking Your Digital Presence",
                    "content": "Regularly searching your name online can show you what digital traces exist. This helps understand how others may perceive you based on available information."
                },
                {
                    "title": "Curating Your Online Identity",
                    "content": "Be mindful of the content you share; it shapes how people view you. Aim to reflect the persona you want others to see."
                },
                {
                    "title": "Creating a Positive Legacy",
                    "content": "Consider long-term implications of your online actions. Strive for a digital presence that represents your best self, helping to build a positive legacy for the future."
                }
            ]
        }
    ],
    "DQ6.2": [
        {
            "id": 1,
            "main_heading": "Understanding Digital Footprints",
            "body": [
                {
                    "title": "What is a Digital Footprint?",
                    "content": "A digital footprint is the unique trail of information we leave behind while using the internet, including posts, photos, and comments. This information can be collected, stored, and viewed by others, influencing how we are perceived online."
                },
                {
                    "title": "Types of Digital Footprints",
                    "content": "There are two main types: active and passive. Active footprints involve information we intentionally share, while passive footprints consist of data collected about our online activities without our knowledge."
                },
                {
                    "title": "Why Digital Footprints Are Important",
                    "content": "Our digital footprint affects our reputation, job opportunities, and relationships. Understanding it helps us make safer, more informed choices about what we share online."
                }
            ]
        },
        {
            "id": 2,
            "main_heading": "Permanence Online",
            "body": [
                {
                    "title": "Once It's Posted, It's Hard to Remove",
                    "content": "Anything shared online can be difficult to erase entirely. Even if deleted, screenshots and third-party posts can keep that information alive."
                },
                {
                    "title": "Consequences of Online Posts",
                    "content": "Posts can affect how others perceive us, including potential employers and college admittance committees. A single regrettable post may haunt us years later."
                },
                {
                    "title": "The Ripple Effect of Sharing",
                    "content": "Content can easily go viral, reaching a wider audience than intended. A post meant for friends can become public quickly, so think carefully before sharing."
                }
            ]
        },
        {
            "id": 3,
            "main_heading": "Your Online Reputation",
            "body": [
                {
                    "title": "Building a Positive Digital Footprint",
                    "content": "Share positive content and think about how others might view your posts. Highlight achievements, creativity, and kindness online to build a strong reputation."
                },
                {
                    "title": "Assessing Risks Before Posting",
                    "content": "Always consider your audience. If you wouldn’t want a family member or future boss to see it, don’t post it. What seems harmless today may lead to unwanted consequences tomorrow."
                },
                {
                    "title": "Seeking Permission and Respecting Privacy",
                    "content": "Before posting photos of others, ask for their consent. Respecting others' digital privacy contributes to a positive online community and prevents misunderstandings."
                }
            ]
        },
        {
            "id": 4,
            "main_heading": "Privacy Settings and Controls",
            "body": [
                {
                    "title": "Using Privacy Settings Wisely",
                    "content": "Social media platforms have privacy settings that allow you to control who sees your posts. Familiarize yourself with these features to enhance your digital safety."
                },
                {
                    "title": "Understanding Audience Reach",
                    "content": "Remember that even with privacy settings, friends can share your content beyond your original audience. Always be cautious about what you share."
                },
                {
                    "title": "Regularly Review Your Digital Footprint",
                    "content": "Regularly check what information about you is available online. Cleaning up old posts and adjusting privacy settings can help maintain a positive online presence."
                }
            ]
        }
    ],
    "DQ2.1": [
        {
            "id": 1,
            "main_heading": "Understanding Healthy Digital Habits",
            "body": [
                {
                    "title": "What Are Healthy Digital Habits?",
                    "content": "Healthy digital habits involve managing screen time and using technology wisely. This includes being aware of why, when, and how we use devices each day."
                },
                {
                    "title": "The Importance of Balance",
                    "content": "Finding a balance between screen time and offline activities is key to overall well-being. Engaging in physical activities, social interactions, and hobbies enhances our daily lives."
                },
                {
                    "title": "Identifying Excessive Screen Time",
                    "content": "Signs of too much screen time can include fatigue, mood swings, or less interest in activities. Reflection on daily tech usage helps in recognizing any unhealthy patterns."
                }
            ]
        },
        {
            "id": 2,
            "main_heading": "Evaluating Your Screen Time",
            "body": [
                {
                    "title": "Understanding Your Daily Use",
                    "content": "Take time to track the amount and types of screen time you engage in daily. Identify activities you enjoy, and consider how they affect your life and health."
                },
                {
                    "title": "Reflecting on Device Usage",
                    "content": "Think about your device usage in various contexts. Note if you typically use devices during meals, socializing, or physical activities, and understand its implications."
                },
                {
                    "title": "Shifting to Healthy Behavior",
                    "content": "Use your reflections to set personalized boundaries. Engage in discussions with family to establish healthy limits and promote balanced technology use."
                }
            ]
        },
        {
            "id": 3,
            "main_heading": "The Role of Parents and Caregivers",
            "body": [
                {
                    "title": "Modeling Healthy Usage",
                    "content": "Adults play a crucial role as role models in tech use. Demonstrating balanced screen time encourages children to develop similar habits and understand limits."
                },
                {
                    "title": "Setting Boundaries Together",
                    "content": "Collaborating with children to create screen time rules can foster mutual understanding about the need for balance. Flexibility allows guidelines to evolve as kids grow."
                },
                {
                    "title": "Engaging in Shared Activities",
                    "content": "Participating in screen-time activities with children can enhance their learning. This creates opportunities for meaningful interaction and discussion about content quality."
                }
            ]
        },
        {
            "id": 4,
            "main_heading": "Understanding Screen Time Quality",
            "body": [
                {
                    "title": "Not All Screen Time is Equal",
                    "content": "Different types of screen time serve various purposes. Educational screen time is beneficial, while excessive passive screen time may lead to negative outcomes."
                },
                {
                    "title": "Evaluating Content Quality",
                    "content": "Ensure that the media and apps children use are age-appropriate and enriching. Selecting high-quality content helps make screen time more productive and enjoyable."
                },
                {
                    "title": "Promoting Active Engagement",
                    "content": "Encourage children to engage actively with media, such as asking questions about content. This promotes critical thinking and improves learning outcomes."
                }
            ]
        },
        {
            "id": 5,
            "main_heading": "Strategies for Balancing Technology Use",
            "body": [
                {
                    "title": "Daily Screen Time Tracking",
                    "content": "Establish a habit of recording daily screen time use. This helps identify patterns and allows adjustments to promote healthier habits and lifestyles."
                },
                {
                    "title": "Encouraging Tech-Free Time",
                    "content": "Designate specific times for family activities without screens, like meals or outdoor play. This strengthens connections and promotes overall well-being."
                },
                {
                    "title": "Creating a Balanced Schedule",
                    "content": "Balance screen time with designated periods for physical activity, homework, and social interaction. A well-structured routine helps children manage their time effectively."
                }
            ]
        }
    ],
    "DQ2.2": [
        {
            "id": 1,
            "main_heading": "Understanding Technology Addiction",
            "body": [
                {
                    "title": "What is Technology Addiction?",
                    "content": "Technology addiction occurs when individuals lose control over their device use, prioritizing it over essential activities and relationships. This behavior can lead to physical and emotional issues that disrupt daily life and well-being."
                },
                {
                    "title": "Signs of Technology Addiction",
                    "content": "Common signs include neglecting responsibilities, experiencing mood swings, and feeling anxious or irritable when away from devices. Identifying these symptoms is crucial for recognizing when usage becomes problematic."
                },
                {
                    "title": "Balancing Digital and Non-Digital Activities",
                    "content": "Maintaining a healthy balance involves setting boundaries for screen time, engaging in offline activities, and being mindful of how technology affects our mood and relationships."
                }
            ]
        },
        {
            "id": 2,
            "main_heading": "Impact of Excessive Screen Time",
            "body": [
                {
                    "title": "Physical Health Effects",
                    "content": "Excessive screen time can lead to sleep disturbances, eye strain, and decreased physical activity. Recognizing signs like tiredness and headaches is key in addressing these issues."
                },
                {
                    "title": "Mental Health Implications",
                    "content": "Overuse of technology is linked to depression, anxiety, and social withdrawal. It's essential to monitor how digital habits affect overall mental well-being and social interactions."
                },
                {
                    "title": "Creating a Balanced Routine",
                    "content": "To ensure a healthy lifestyle, combine digital activities with exercise, face-to-face interaction, and hobbies. Creating a balanced routine can significantly improve both physical and mental health."
                }
            ]
        },
        {
            "id": 3,
            "main_heading": "Self-Reflection and Planning",
            "body": [
                {
                    "title": "Monitor Your Usage",
                    "content": "Keeping a journal of daily digital activities helps you understand your interaction with technology. Reflecting on this can reveal patterns and inform better choices moving forward."
                },
                {
                    "title": "Setting Goals for Improvement",
                    "content": "Establish specific goals to reduce screen time and enhance offline activities. Focus on areas like hobbies, family interactions, and physical exercise to create a more balanced routine."
                },
                {
                    "title": "Share and Discuss Your Goals",
                    "content": "Engaging with family or friends about technology use can lead to meaningful discussions and support. Sharing strategies and experiences helps strengthen commitments to balanced digital habits."
                }
            ]
        },
        {
            "id": 4,
            "main_heading": "Seeking Support and Guidance",
            "body": [
                {
                    "title": "Talk to Trusted Adults",
                    "content": "If technology use begins to interfere with daily life, discussing concerns with trusted adults can provide valuable insights and strategies for improvement. Open communication fosters understanding and support."
                },
                {
                    "title": "Involving Peers and Community",
                    "content": "Building a supportive community enhances accountability. Collaborating with peers to promote healthy tech habits helps create an environment conducive to maintaining balance."
                },
                {
                    "title": "Professional Help When Necessary",
                    "content": "In extreme cases, seeking professional guidance can be beneficial. Therapists or counselors specialize in helping individuals develop healthier relationships with technology."
                }
            ]
        }
    ],
    "DQ2.3": [
        {
            "id": 1,
            "main_heading": "Understanding Digital Balance",
            "body": [
                {
                    "title": "What is Digital Balance?",
                    "content": "Digital balance refers to finding a healthy mix between online and offline activities. It considers both the quantity and quality of time spent using digital devices for various purposes, including learning and leisure."
                },
                {
                    "title": "Why Digital Balance is Important",
                    "content": "Maintaining digital balance is essential for mental and physical health. Overuse of screens can lead to eye strain, poor sleep, and hindered social interactions."
                },
                {
                    "title": "Strategies for Achieving Digital Balance",
                    "content": "To achieve balance, set clear boundaries on screen time and include offline activities like exercise, family meals, and hobbies in daily routines. This ensures a well-rounded lifestyle."
                }
            ]
        },
        {
            "id": 2,
            "main_heading": "Effects of Excessive Screen Time",
            "body": [
                {
                    "title": "Physical Health Impacts",
                    "content": "Too much screen time can cause eye strain, poor posture, and headaches. It’s important to take breaks and maintain ergonomic positions while using devices."
                },
                {
                    "title": "Mental Health Consequences",
                    "content": "Excessive digital consumption may lead to anxiety, loneliness, and decreased social skills. Regular check-ins on emotional well-being can help offset these effects."
                },
                {
                    "title": "Promoting Positive Digital Habits",
                    "content": "Encouraging activities like outdoor play and family interactions can mitigate the negative impacts of screen time while reinforcing healthy habits from an early age."
                }
            ]
        },
        {
            "id": 3,
            "main_heading": "Creating Healthy Digital Routines",
            "body": [
                {
                    "title": "Establishing Screen Time Limits",
                    "content": "Set specific limits for daily screen time based on age and activity, following recommended guidelines. This helps ensure that screen use does not interfere with important activities and routines."
                },
                {
                    "title": "Designating Tech-Free Times",
                    "content": "Implement ’tech-free’ periods, especially during meals and before bed, to promote healthy family connections and better sleep hygiene. Use this time to engage in conversation or read together."
                },
                {
                    "title": "Encouraging Active Engagement",
                    "content": "Use digital devices for educational purposes or interactive games that stimulate creativity and problem-solving skills. Seek out quality content that contributes positively to learners’ growth."
                }
            ]
        },
        {
            "id": 4,
            "main_heading": "Involving Parents and Caregivers",
            "body": [
                {
                    "title": "Role Modeling Healthy Use",
                    "content": "Adults should exemplify balanced digital use. Being mindful of how often you use devices around kids helps set a good example. Show them that real-life interactions matter."
                },
                {
                    "title": "Collaborative Content Exploration",
                    "content": "Engage with children when using digital devices, discussing what they learn or watch online. This involvement can guide them toward positive habits and informed content selections."
                },
                {
                    "title": "Open Discussions About Digital Use",
                    "content": "Creating an open forum for discussions about technology’s benefits and drawbacks is crucial. Empower children to voice their experiences and challenges with digital usage."
                }
            ]
        }
    ],
    "DQ2.5": [
        {
            "id": 1,
            "main_heading": "Balancing Online and Offline Time",
            "body": [
                {
                    "title": "What is Digital Citizenship?",
                    "content": "Digital citizenship refers to the responsible use of technology and the internet. It covers the skills, behaviors, and knowledge needed to safely navigate online environments and contribute positively."
                },
                {
                    "title": "Why Balance is Essential",
                    "content": "Balancing online and offline activities is crucial for well-being. Too much screen time can negatively affect mental and physical health, leading to issues like poor sleep and reduced social skills."
                },
                {
                    "title": "Strategies for Self-Regulation",
                    "content": "To manage screen time effectively, set personal limits, prioritize offline activities, and engage in hobbies like sports or art that foster creativity and physical interaction."
                }
            ]
        },
        {
            "id": 2,
            "main_heading": "Understanding Screen Time Contracts",
            "body": [
                {
                    "title": "What is a Screen Time Contract?",
                    "content": "A screen time contract is an agreement between parents and children outlining acceptable screen use. It specifies time limits, appropriate content, and device-free zones within the home."
                },
                {
                    "title": "Benefits of a Screen Time Contract",
                    "content": "These contracts help foster responsibility and independence in children. They encourage discussions about technology use and establish clear expectations for both parties."
                },
                {
                    "title": "How to Create One",
                    "content": "To create a screen time contract, involve the child in setting rules, discussing consequences for breaking them, and revisit the contract regularly to adapt to changing needs and maturity levels."
                }
            ]
        },
        {
            "id": 3,
            "main_heading": "Tracking and Reflecting on Activities",
            "body": [
                {
                    "title": "The Importance of Journaling",
                    "content": "Keeping a journal of daily digital and non-digital activities can help individuals reflect on their time management. It provides awareness of how much time is spent online versus engaging in offline hobbies."
                },
                {
                    "title": "Documenting Experiences",
                    "content": "While journaling, note down who you were with, what you were doing, and how you felt about the activities. This can help highlight preferences and inform future balance decisions."
                },
                {
                    "title": "Using Apps for Tracking",
                    "content": "Consider using apps that track screen usage and set boundaries. Some apps allow you to set limits and block access after a certain time, promoting healthier habits."
                }
            ]
        },
        {
            "id": 4,
            "main_heading": "Engaging in Offline Activities",
            "body": [
                {
                    "title": "Why Engagement Matters",
                    "content": "Participating in offline activities like sports, reading, or family time helps develop essential skills. These activities enhance creativity, critical thinking, and physical fitness, all vital for overall well-being."
                },
                {
                    "title": "Connecting Online to Offline",
                    "content": "Encourage children to relate online interests to offline activities. For instance, after watching a sports game, physically practicing the moves can make the experience enjoyable and memorable."
                },
                {
                    "title": "Family Involvement: A Key Factor",
                    "content": "Families should engage together in offline activities. Whether it's cooking, walking, or playing games, shared experiences strengthen relationships and help build a balanced lifestyle."
                }
            ]
        },
        {
            "id": 5,
            "main_heading": "Seeking Support and Communication",
            "body": [
                {
                    "title": "Importance of Conversations",
                    "content": "Openly discussing screen time and digital habits with children encourages responsibility. Create a supportive environment where children feel safe to express their thoughts and concerns."
                },
                {
                    "title": "Involving Parents and Caregivers",
                    "content": "Parents should regularly check in with their children about their online experiences. This helps build trust and provides opportunities to guide children in managing their digital lives effectively."
                },
                {
                    "title": "Creating a Support System",
                    "content": "Encourage children to seek assistance from trusted adults and their peers when facing digital challenges. Establishing this support network empowers them to navigate situations safely."
                }
            ]
        }
    ],
    "DQ3.4": [
        {
            "id": 1,
            "main_heading": "Understanding Cyberbullying",
            "body": [
                {
                    "title": "What is Cyberbullying?",
                    "content": "Cyberbullying refers to bullying that takes place over digital devices like computers, smartphones, and tablets. It can include hurtful messages, impersonation, and spreading rumors online."
                },
                {
                    "title": "How it Happens",
                    "content": "Cyberbullying can occur through social media, texting, and online gaming. It's often repeated behavior that targets a specific individual, making them feel unsafe or humiliated."
                },
                {
                    "title": "Impact of Cyberbullying",
                    "content": "The effects of cyberbullying can be severe, leading to anxiety, depression, and lowered self-esteem. Victims may feel isolated and struggle with mental health issues over time."
                }
            ]
        },
        {
            "id": 2,
            "main_heading": "Recognizing Cyberbullying",
            "body": [
                {
                    "title": "Signs of Cyberbullying",
                    "content": "Signs your child may be experiencing cyberbullying include becoming withdrawn, avoiding school, and being upset after online activities. They may also stop using their devices altogether."
                },
                {
                    "title": "Types of Cyberbullying",
                    "content": "Common forms of cyberbullying include harassment, exclusion from groups, spreading false information, and impersonation. Each type can deeply affect the victim's emotional well-being."
                },
                {
                    "title": "Why People Cyberbully",
                    "content": "People may bully others online due to feeling insecure, wanting attention, or trying to fit in with peers. Understanding these motivations can help address the behavior effectively."
                }
            ]
        },
        {
            "id": 3,
            "main_heading": "How to Respond to Cyberbullying",
            "body": [
                {
                    "title": "Talk to Someone",
                    "content": "If you or someone you know is being bullied online, it's important to speak to a trusted adult. Sharing the experience can provide support and assistance."
                },
                {
                    "title": "Report and Block",
                    "content": "Most platforms have tools to report cyberbullying and block offenders. Use these features to stop abusive interactions effectively."
                },
                {
                    "title": "Practice Kindness",
                    "content": "Encourage empathy and kindness online. Remind others that treating people with respect, both online and offline, is essential for a healthy community."
                }
            ]
        },
        {
            "id": 4,
            "main_heading": "Being an Upstander",
            "body": [
                {
                    "title": "Support Victims",
                    "content": "Stand up for those being bullied. Offer words of encouragement and support; sometimes, just being there for someone can make a difference."
                },
                {
                    "title": "Speak Out Against Bullying",
                    "content": "If you witness bullying, don’t be a bystander. Speak out against hurtful behavior and encourage others to do the same."
                },
                {
                    "title": "Promote Positive Behavior",
                    "content": "Setting a positive example in your own online interactions can help create a culture of kindness. Share uplifting messages that counter negativity."
                }
            ]
        },
        {
            "id": 5,
            "main_heading": "Helpful Resources",
            "body": [
                {
                    "title": "Online Safety Tools",
                    "content": "Familiarize yourself with online safety resources, such as reporting tools for social media and gaming platforms, so you know what to do in case of cyberbullying."
                },
                {
                    "title": "Counseling Services",
                    "content": "Professional help from counselors can effectively support victims of cyberbullying, helping them address emotional impacts and develop coping strategies."
                },
                {
                    "title": "Educational Programs",
                    "content": "Schools often provide programs on digital citizenship and cyberbullying awareness to help educate students about safe online behaviors that promote respect and empathy."
                }
            ]
        }
    ],
    "DQ3.1": [
        {
            "id": 1,
            "main_heading": "Understanding Cyberbullying",
            "body": [
                {
                    "title": "What is Cyberbullying?",
                    "content": "Cyberbullying is using technology to intentionally harm someone through repeated negative actions online. This can include sending hurtful messages, spreading rumors, or sharing embarrassing photos without consent."
                },
                {
                    "title": "Forms of Cyberbullying",
                    "content": "Different forms include harassment, exclusion from online groups, creating fake profiles, and impersonating someone. Each form can deeply affect the emotional well-being of the victims."
                },
                {
                    "title": "Effects of Cyberbullying",
                    "content": "Victims often experience anxiety, depression, and changes in behavior. It can affect their school performance and social life, leading to feelings of isolation."
                }
            ]
        },
        {
            "id": 2,
            "main_heading": "Recognizing Cyberbullying",
            "body": [
                {
                    "title": "Signs of Cyberbullying",
                    "content": "Look for signs such as avoidance of school, changes in friendships, secretive online behavior, and mood swings. These can indicate someone might be experiencing cyberbullying."
                },
                {
                    "title": "Long-term Effects",
                    "content": "Cyberbullying can lead to serious issues like low self-esteem, anxiety, and depression. Understanding these effects is crucial for supporting victims."
                },
                {
                    "title": "The Role of Bystanders",
                    "content": "Bystanders can either support the bullying by remaining silent or become upstanders who intervene and help the victim. Choosing to speak up can make a big difference."
                }
            ]
        },
        {
            "id": 3,
            "main_heading": "Combating Cyberbullying",
            "body": [
                {
                    "title": "How to Respond",
                    "content": "If you witness cyberbullying, report it to a trusted adult or use reporting features on social platforms. It's important to encourage victims to talk about their experiences."
                },
                {
                    "title": "Empathy as a Tool",
                    "content": "Building empathy among peers can help prevent cyberbullying. Understanding others' feelings promotes kindness and discourages harmful behavior."
                },
                {
                    "title": "Importance of Digital Citizenship",
                    "content": "Teaching responsible online behavior helps everyone understand the impact of their words and actions. A positive digital footprint can foster a safe online community."
                }
            ]
        },
        {
            "id": 4,
            "main_heading": "Legal and Social Implications",
            "body": [
                {
                    "title": "Understanding Legal Consequences",
                    "content": "Cyberbullying can lead to serious consequences, including legal action. Knowledge of laws can help deter bullying behavior online."
                },
                {
                    "title": "Support Networks",
                    "content": "Schools and communities should create support systems for victims. Access to counseling and resources is vital for recovery and resilience."
                },
                {
                    "title": "The Role of Education",
                    "content": "Educating young people about the dangers and impacts of cyberbullying can empower them to act against it and support peers in need."
                }
            ]
        }
    ],
    "DQ3.2": [
        {
            "id": 1,
            "main_heading": "Understanding Cyberbullying and Response Strategies",
            "body": [
                {
                    "title": "What is Cyberbullying?",
                    "content": "Cyberbullying is when someone is bullied through digital devices like phones or computers. It can involve mean messages, rumors, or public humiliation online, and it often hurts feelings deeply."
                },
                {
                    "title": "Why is Reporting Important?",
                    "content": "Reporting cyberbullying helps protect not just the victim, but also others from future harassment. It is a way to stop bullying and promote a safer online environment for everyone."
                },
                {
                    "title": "How to Respond to Cyberbullying",
                    "content": "If you encounter cyberbullying, do not reply, save the evidence, and tell a trusted adult. Engaging can escalate the situation and make it worse."
                }
            ]
        },
        {
            "id": 2,
            "main_heading": "Making Healthy Online Choices",
            "body": [
                {
                    "title": "Think Before You Post",
                    "content": "Every piece of information shared online can last forever. Ask yourself if you'd be comfortable seeing that post in a few years when applying for jobs or schools."
                },
                {
                    "title": "Understanding Your Feelings",
                    "content": "It's normal to feel upset or angry about negative comments, but remember that you can control your reactions. Choose to respond positively or take a break from the situation."
                },
                {
                    "title": "The Power of Asking for Help",
                    "content": "Seeking help is a sign of strength, not weakness. Reach out to friends, family, or trusted adults when you're feeling overwhelmed or confused about online interactions."
                }
            ]
        },
        {
            "id": 3,
            "main_heading": "Supporting Friends and Peers",
            "body": [
                {
                    "title": "Respecting Your Friend’s Wishes",
                    "content": "If a friend doesn’t want help with bullying, you should respect their choice but remain observant. If they seem upset, consider discussing it with a trusted adult."
                },
                {
                    "title": "Being a Good Ally",
                    "content": "If you witness bullying, support the victim by encouraging them to talk about their feelings. Offer to help them report the bullying to keep them safe."
                },
                {
                    "title": "Building a Positive Online Community",
                    "content": "Foster kindness online by promoting respect and compassion. Challenge negative behaviors when you see them and uplift those who are struggling."
                }
            ]
        },
        {
            "id": 4,
            "main_heading": "Digital Footprint Awareness",
            "body": [
                {
                    "title": "Permanent Nature of Online Actions",
                    "content": "Always remember that once something is shared online, it can be difficult to erase completely. Protect your digital footprint by being cautious about what you post."
                },
                {
                    "title": "Privacy Settings are Important",
                    "content": "Regularly review and adjust your privacy settings on social media. Controlling who sees your posts can help limit unwanted interactions and bullying."
                },
                {
                    "title": "Taking Breaks is Healthy",
                    "content": "It's essential to take breaks from screens to maintain mental well-being. Take time to recharge and engage in offline activities that you enjoy."
                }
            ]
        }
    ],
    "DQ3.3": [
        {
            "id": 1,
            "main_heading": "Understanding Cyberbullying",
            "body": [
                {
                    "title": "What is Cyberbullying?",
                    "content": "Cyberbullying is when someone uses electronic communication to hurt, embarrass, or intimidate another person. It can happen through social media, text messages, or online gaming platforms and can have serious effects on the victim."
                },
                {
                    "title": "Why is Cyberbullying Harmful?",
                    "content": "Cyberbullying can cause emotional pain, anxiety, and depression to those who are targeted. Unlike traditional bullying, it can happen 24/7 and can feel relentless, making it harder for victims to escape."
                },
                {
                    "title": "How to Identify Cyberbullying",
                    "content": "Signs of cyberbullying include receiving hurtful messages, being excluded from online groups, or seeing negative comments about oneself. Recognizing these signs is crucial for taking action."
                }
            ]
        },
        {
            "id": 2,
            "main_heading": "The Role of Bystanders",
            "body": [
                {
                    "title": "Influence of Bystanders",
                    "content": "Bystanders can either encourage cyberbullying or help stop it. Standing up against bullying can deter the bully and show support for the victim, creating a safer online environment for everyone."
                },
                {
                    "title": "Taking Action as a Bystander",
                    "content": "If you witness cyberbullying, don’t just stand by. You can support the victim by reporting the bullying or standing up for them in a kind way."
                },
                {
                    "title": "The Importance of Peer Support",
                    "content": "Talking to friends and peers about cyberbullying can foster a supportive community. Encouraging open discussions about online behavior can lead to a problem-solving approach."
                }
            ]
        },
        {
            "id": 3,
            "main_heading": "Consequences of Cyberbullying",
            "body": [
                {
                    "title": "Short-term Effects",
                    "content": "Victims often experience anxiety, fear, and isolation. Cyberbullying can impact their social life, mental health, and self-esteem, leading to a decline in their overall well-being."
                },
                {
                    "title": "Long-term Effects",
                    "content": "The consequences of cyberbullying can linger, resulting in lasting emotional distress and challenges in building relationships in the future. Victims may struggle in settings like schools or workplaces."
                },
                {
                    "title": "Responsibility of the Bully",
                    "content": "Those who engage in cyberbullying can face serious repercussions, including disciplinary action from schools, loss of online privileges, or even legal consequences."
                }
            ]
        },
        {
            "id": 4,
            "main_heading": "Creating a Safe Online Environment",
            "body": [
                {
                    "title": "Establishing Open Communication",
                    "content": "It's important to talk openly with friends and family about online experiences. Sharing can help victims feel supported and empower bystanders to take action against bullying."
                },
                {
                    "title": "Using Privacy Settings",
                    "content": "Adjusting privacy settings on social media can help protect against unwanted interactions. Consider who can see your posts and who can contact you online to stay safe."
                },
                {
                    "title": "Reporting Inappropriate Behavior",
                    "content": "Most platforms have options to report abusive behavior. Reporting helps hold bullies accountable and can create a safer space for all users."
                }
            ]
        },
        {
            "id": 5,
            "main_heading": "Building Resilience Against Cyberbullying",
            "body": [
                {
                    "title": "Recognizing Your Value",
                    "content": "Understand that you are valuable and deserve respect online and offline. Building confidence can help prevent negative comments from affecting you deeply."
                },
                {
                    "title": "Practicing Empathy",
                    "content": "Always try to consider how others might feel before posting or commenting. Empathy promotes kindness and discourages negative behavior online."
                },
                {
                    "title": "Seeking Help When Needed",
                    "content": "If you or someone you know is struggling with cyberbullying, don’t hesitate to ask for help from trusted adults. Support is available, and it’s important to take action."
                }
            ]
        }
    ],
    "DQ11.3": [
        {
            "id": 1,
            "main_heading": "Understanding Online Safety",
            "body": [
                {
                    "title": "What is Online Grooming?",
                    "content": "Online grooming is when an adult builds a relationship with a young person to exploit them sexually. Groomers often pretend to be friendly and may use fake identities to gain trust."
                },
                {
                    "title": "The Dangers of Unwanted Contact",
                    "content": "Unwanted contact from strangers or acquaintances can make young people feel uncomfortable and unsafe. It's important to recognize and reject these communications promptly."
                },
                {
                    "title": "Signs of Grooming Behavior",
                    "content": "Common signs include asking personal questions, requesting secrecy, and pushing for an online to offline meeting. If something feels off, it's crucial to speak up."
                }
            ]
        },
        {
            "id": 2,
            "main_heading": "How to Respond to Unwanted Contact",
            "body": [
                {
                    "title": "Trust Your Instincts",
                    "content": "Always trust your feelings if you sense something isn't right. If a conversation feels uncomfortable, it's okay to stop communicating with that person."
                },
                {
                    "title": "What to Do Next",
                    "content": "If you experience unwanted contact, immediately block the person and report the behavior on the platform. Always talk to a trusted adult for support."
                },
                {
                    "title": "Setting Boundaries Online",
                    "content": "Establishing boundaries about what is acceptable in online interactions is vital. Be clear about what you're comfortable with and communicate those limits to others."
                }
            ]
        },
        {
            "id": 3,
            "main_heading": "Meeting Online Friends",
            "body": [
                {
                    "title": "Staying Safe with Meetings",
                    "content": "Never meet someone face-to-face without adult supervision. Always inform a trusted adult of your plans, including where you will go and whom you will meet."
                },
                {
                    "title": "Choosing Safe Locations",
                    "content": "If you must meet someone, pick a busy, public place. Avoid secluded areas to ensure you can get help if needed."
                },
                {
                    "title": "Bringing a Friend",
                    "content": "Always take someone with you when meeting an online friend. This adds a layer of safety and can help reduce pressure during the meeting."
                }
            ]
        },
        {
            "id": 4,
            "main_heading": "Recognizing and Reporting Abuse",
            "body": [
                {
                    "title": "How to Recognize Abuse",
                    "content": "Pay attention to any body language or verbal cues that suggest someone might be being pressured or feeling unsafe. Visibly distressed individuals need support."
                },
                {
                    "title": "Reporting Inappropriate Behavior",
                    "content": "If you suspect grooming or abuse, it’s essential to report it to the proper authorities right away, like local law enforcement or child protection services."
                },
                {
                    "title": "Accessing Help Resources",
                    "content": "Numerous organizations and hotlines provide support and advice regarding online safety. Familiarize yourself with local resources and helplines."
                }
            ]
        }
    ],
    "DQ27.3": [
        {
            "id": 1,
            "main_heading": "Understanding Online Safety",
            "body": [
                {
                    "title": "What Are Online Risks?",
                    "content": "Online platforms can expose users to a variety of risks including inappropriate content, harmful interactions, and cyberbullying. It's essential to understand these risks to ensure a safer online experience."
                },
                {
                    "title": "Types of Harmful Content",
                    "content": "Harmful content includes violent images, sexual material, and hate speech. Recognizing such content can help users avoid exposure and protect their well-being."
                },
                {
                    "title": "The Importance of Age Restrictions",
                    "content": "Many online platforms have age restrictions to protect younger audiences from inappropriate content. Following these guidelines ensures a safer digital environment."
                }
            ]
        },
        {
            "id": 2,
            "main_heading": "Identifying Inappropriate Material",
            "body": [
                {
                    "title": "Recognizing Inappropriate Images",
                    "content": "Inappropriate images can include graphic violence, nudity, or sexual content. If something feels wrong, it's important to avoid it and seek help."
                },
                {
                    "title": "Understanding Sexting and Its Risks",
                    "content": "Sexting involves sending sexual images or messages, often leading to significant emotional harm. Being pressured to send such content can have serious long-term consequences."
                },
                {
                    "title": "Privacy Concerns with Sharing Content",
                    "content": "Sharing personal images online carries risks of exposure and misuse. It’s crucial to think carefully before sending any images to ensure your privacy is protected."
                }
            ]
        },
        {
            "id": 3,
            "main_heading": "The Role of Online Communities",
            "body": [
                {
                    "title": "Online Communities and Their Impact",
                    "content": "Online gaming and social platforms connect individuals but can also foster negative behavior, such as bullying and harassment. Personal safety should always be prioritized."
                },
                {
                    "title": "Grooming and Exploitation Risks",
                    "content": "Grooming can occur when individuals pose as peers to gain trust. Awareness of this behavior is key to preventing exploitation."
                },
                {
                    "title": "Creating a Positive Online Environment",
                    "content": "Encouraging kindness and respect in online communities helps everyone feel safe and supported. This culture can significantly reduce toxic behaviors."
                }
            ]
        },
        {
            "id": 4,
            "main_heading": "Managing Digital Experiences",
            "body": [
                {
                    "title": "Monitoring Online Interactions",
                    "content": "Regularly discussing online experiences with trusted adults helps identify potential dangers. Open conversations allow for honest sharing without fear of judgment."
                },
                {
                    "title": "Setting Boundaries for Device Use",
                    "content": "Establishing rules for device use can help manage exposure to harmful content. Limit access to certain apps or set time limits for increased safety."
                },
                {
                    "title": "Utilizing Parental Controls",
                    "content": "Parental controls can limit access to risky content. Setting up these features ensures a safer digital playground for younger users."
                }
            ]
        }
    ],
    "DQ11.1": [
        {
            "id": 1,
            "main_heading": "Understanding Risky Online Contacts",
            "body": [
                {
                    "title": "What are Risky Online Contacts?",
                    "content": "Risky online contacts are individuals who you meet on the internet that may not have your best interests at heart. They can include strangers on social media, chat rooms, or gaming platforms who could pose dangers to your safety and well-being."
                },
                {
                    "title": "Signs of Risky Contacts",
                    "content": "Risky online contacts often exhibit behaviors such as secrecy, asking personal questions, or trying to meet in real life. Recognizing these signs early can help you avoid potentially harmful situations."
                },
                {
                    "title": "Why They Matter for Your Well-Being",
                    "content": "Interacting with risky contacts can lead to emotional harm, cyberbullying, or even dangerous encounters. It is vital to prioritize your safety when deciding whom to connect with online."
                }
            ]
        },
        {
            "id": 2,
            "main_heading": "Identifying Red Flags",
            "body": [
                {
                    "title": "Inappropriate Communication",
                    "content": "If someone is sending unsolicited messages, sharing explicit content, or making you uncomfortable, they are likely a risky contact. Trust your instincts and disengage from such conversations."
                },
                {
                    "title": "Excessive Flattery or Pressure",
                    "content": "Risky contacts may overwhelm you with compliments or pressure you for personal information. Such tactics may seem flattering but often indicate ulterior motives."
                },
                {
                    "title": "Desire to Meet Offline",
                    "content": "Any contact that insists on meeting you in person, especially if you’ve just met online, should be treated with suspicion. Your safety should always come first, so maintain boundaries."
                }
            ]
        },
        {
            "id": 3,
            "main_heading": "Maintaining Your Safety",
            "body": [
                {
                    "title": "Set Strong Privacy Settings",
                    "content": "Adjust your social media accounts to limit who can see your information. Strong privacy settings help protect you from unwanted contacts and exposure to risky situations."
                },
                {
                    "title": "Trust Your Instincts",
                    "content": "If something feels off about an online interaction, listen to your gut. It's always better to be safe and stop engaging with someone who makes you uncomfortable."
                },
                {
                    "title": "Talk to Trusted Adults",
                    "content": "If you encounter risky contacts, talk to a trusted adult for advice. They can provide support and help you navigate the situation wisely."
                }
            ]
        },
        {
            "id": 4,
            "main_heading": "The Impact on Well-Being",
            "body": [
                {
                    "title": "Emotional Effects",
                    "content": "Engaging with risky contacts can lead to anxiety, stress, and feelings of fear. Such experiences can greatly impact your emotional health and well-being."
                },
                {
                    "title": "Credibility of Information",
                    "content": "Risky online contacts may share misleading or harmful information. This can affect your beliefs and perceptions negatively, leading to poor life choices."
                },
                {
                    "title": "Building Healthy Connections",
                    "content": "By identifying and avoiding risky contacts, you can foster positive online relationships that contribute to a healthier emotional life. Focus on connecting with supportive and trustworthy individuals."
                }
            ]
        }
    ],
    "DQ11.2": [
        {
            "id": 1,
            "main_heading": "Understanding Online Friend Requests",
            "body": [
                {
                    "title": "What is an Online Friend Request?",
                    "content": "An online friend request is a message asking to connect with someone on social media or gaming platforms. This can come from people you know or strangers who are unknown to you."
                },
                {
                    "title": "The Risks of Accepting Unknown Requests",
                    "content": "Accepting requests from strangers can lead to dangerous situations, as online profiles can be fake. These individuals might not have good intentions, similar to accepting a stranger's friendship in real life."
                },
                {
                    "title": "Identifying Fake Profiles",
                    "content": "Scammers often create fake profiles using stolen images and information. Look for warning signs like minimal mutual friends or generic pictures that seem suspicious."
                }
            ]
        },
        {
            "id": 2,
            "main_heading": "Keeping Your Information Private",
            "body": [
                {
                    "title": "Why Privacy Matters Online",
                    "content": "Privacy is essential to protect your personal information from unwanted attention and potential harm. Keeping profiles private ensures only trusted people see your data."
                },
                {
                    "title": "Managing Privacy Settings",
                    "content": "Adjust privacy settings on social media to limit who can send friend requests or view your profile. This adds an extra layer of safety when navigating online."
                },
                {
                    "title": "What to Share Online?",
                    "content": "Be cautious about sharing personal details such as your full name, address, school, or routine. This information could put you at risk if it falls into the wrong hands."
                }
            ]
        },
        {
            "id": 3,
            "main_heading": "Recognizing Inappropriate Contact",
            "body": [
                {
                    "title": "Signs of Inappropriate Online Behavior",
                    "content": "Look for signs like someone asking too many personal questions or making you uncomfortable. Such behavior could mean that their intentions are questionable."
                },
                {
                    "title": "Dealing with Unwanted Contacts",
                    "content": "If you feel unsafe or bothered by someone, stop communicating with them immediately. Report and block such users to protect your online space."
                },
                {
                    "title": "Talking to Adults about Concerns",
                    "content": "Always inform a trusted adult if someone is behaving suspiciously online. This helps ensure your safety and supports you in managing the situation properly."
                }
            ]
        },
        {
            "id": 4,
            "main_heading": "Setting Boundaries Online",
            "body": [
                {
                    "title": "Establishing Boundaries with Online Friends",
                    "content": "Boundaries protect your personal space and feelings. Decide what information you are comfortable sharing and stick to these limits when interacting online."
                },
                {
                    "title": "Why Only Add Known Contacts?",
                    "content": "Only adding friends you know in real life minimizes risks. It helps you maintain a safer online environment by connecting with trustworthy individuals."
                },
                {
                    "title": "Communication is Key",
                    "content": "Talk with your family about your online friends and activities. This opens lines for support and guidance when navigating online interactions."
                }
            ]
        }
    ],
    "DQ27.1": [
        {
            "id": 1,
            "main_heading": "Understanding Risky Online Content",
            "body": [
                {
                    "title": "What is Risky Online Content?",
                    "content": "Risky online content includes material that can harm young people, such as violence, hate speech, and sexual exploitation. Understanding these dangers helps individuals navigate the digital space safely."
                },
                {
                    "title": "Types of Risky Content",
                    "content": "Risky content can be categorized into three main types: violent content, hate speech, and sexually explicit imagery. Each type poses unique risks and can impact mental health and well-being."
                },
                {
                    "title": "Consequences of Engaging with Such Content",
                    "content": "Engaging with risky content can lead to emotional distress, confusion, and even behavioral issues. It’s essential to recognize the long-term effects of exposure to harmful material."
                }
            ]
        },
        {
            "id": 2,
            "main_heading": "Age Ratings for Online Content",
            "body": [
                {
                    "title": "Why Age Ratings Matter",
                    "content": "Age ratings help guide viewers about the suitability of content based on age groups. Understanding these ratings can help young people make informed choices about what to view online."
                },
                {
                    "title": "Categories of Age Ratings",
                    "content": "Age ratings typically range from 'Any Age' to '18+'. Each category signifies the appropriateness based on potential exposure to risky content. Recognizing these categories is crucial in assessing content suitability."
                },
                {
                    "title": "Parental Guidance and Rules",
                    "content": "Different parents may have varying rules regarding online content. Discussing these differences allows children to understand diverse perspectives and make better choices about what they view."
                }
            ]
        },
        {
            "id": 3,
            "main_heading": "Impact of Inappropriate Online Content",
            "body": [
                {
                    "title": "Emotional Effects of Inappropriate Content",
                    "content": "Encountering inappropriate content can lead to anxiety, confusion, and feelings of fear. It's vital to discuss these feelings openly with trusted adults to find support."
                },
                {
                    "title": "Creating a Chain Reaction",
                    "content": "Sharing inappropriate content can create a domino effect, affecting not only the viewer but also their peers. Recognizing this chain reaction can encourage responsibility in online interactions."
                },
                {
                    "title": "How to Cope with Inappropriate Exposure",
                    "content": "If someone feels overwhelmed after viewing something disturbing, it’s important to talk about it. Sharing experiences with friends or adults can help alleviate those feelings and provide clarity."
                }
            ]
        },
        {
            "id": 4,
            "main_heading": "Strategies for Staying Safe Online",
            "body": [
                {
                    "title": "Identifying Safe Spaces Online",
                    "content": "Understanding trusted websites and platforms is essential. Choose sites with proper age ratings and content moderation to minimize exposure to risky material."
                },
                {
                    "title": "Communicating About Online Experiences",
                    "content": "Sharing online experiences with friends and family fosters awareness of what’s appropriate. Open communication can help identify potential risks before they escalate."
                },
                {
                    "title": "Keeping Personal Information Private",
                    "content": "Protecting personal information is vital when using social media. Limit what you share publicly, as sharing too much can lead to unwanted attention or risky interactions."
                }
            ]
        }
    ],
    "DQ27.2": [
        {
            "id": 1,
            "main_heading": "Understanding Online Safety and Support",
            "body": [
                {
                    "title": "What is Inappropriate Content?",
                    "content": "Inappropriate content includes anything that can upset or harm someone. Examples include violent videos, adult material, or offensive comments that are not suitable for children."
                },
                {
                    "title": "Why Is It Important to Recognize Inappropriate Content?",
                    "content": "Recognizing inappropriate content helps protect you from harmful experiences. It allows you to avoid material that can cause distress or confusion and ensures safer online interactions."
                },
                {
                    "title": "How to Respond When You Encounter Inappropriate Content?",
                    "content": "Always tell a trusted adult if you see something upsetting online. Reporting helps keep the internet safer for everyone, and there are supportive resources available to help."
                }
            ]
        },
        {
            "id": 2,
            "main_heading": "Strategies for Handling Online Challenges",
            "body": [
                {
                    "title": "Ask for Help When Needed",
                    "content": "It’s important to ask for help if you encounter concerning content. Contact trusted adults, like teachers or parents, to get the necessary support and guidance."
                },
                {
                    "title": "What to Do After Reporting Issues?",
                    "content": "After reporting, talk about your experience with someone you trust. It helps process feelings and prevents isolation after encountering distressing content."
                },
                {
                    "title": "Understanding Your Role as a Digital Citizen",
                    "content": "Being a responsible digital citizen also means helping others. If you see someone struggling online, encourage them to talk to someone, and assist in reporting issues."
                }
            ]
        },
        {
            "id": 3,
            "main_heading": "Importance of Being a Gatekeeper",
            "body": [
                {
                    "title": "What Does it Mean to be a Gatekeeper?",
                    "content": "Being a gatekeeper of your mind means controlling what content you choose to view. It’s about making safe online choices for your emotional well-being."
                },
                {
                    "title": "Tips for Protecting Yourself Online",
                    "content": "Only visit trusted websites, avoid unknown links or pop-ups, and always consult with an adult before downloading or clicking on anything suspicious."
                },
                {
                    "title": "Building a Positive Digital Footprint",
                    "content": "Your online actions shape your digital footprint. Sharing positive content and supporting others helps create a safe and welcoming online environment."
                }
            ]
        },
        {
            "id": 4,
            "main_heading": "Reporting and Conflict Management",
            "body": [
                {
                    "title": "Why Report Misbehavior Online?",
                    "content": "Reporting harmful content is crucial because it helps platforms maintain a safe environment. It protects you and others from ongoing online harm and can lead to necessary interventions."
                },
                {
                    "title": "Ways to Report Inappropriate Content",
                    "content": "Each platform has its own reporting tools. Familiarize yourself with these options so you can report issues quickly and effectively when they arise."
                },
                {
                    "title": "Engaging in Conflict Management Techniques",
                    "content": "If you witness online conflict, support those involved by encouraging open communication. Help them report inappropriate behavior and promote a healthy discussion about resolving issues."
                }
            ]
        }
    ],
    "DQ0.0": [
        {
            "id": 1,
            "main_heading": "Understanding Digital Footprints",
            "body": [
                {
                    "title": "What is a Digital Footprint?",
                    "content": "A digital footprint is the collection of all the information you share and actions you take online."
                },
                {
                    "title": "Why is it important?",
                    "content": "It is important because it can be used to track your online activities and can be used to identify you."
                },
                {
                    "title": "How to manage it?",
                    "content": "Be mindful of what you post online, use privacy settings, and regularly check your digital footprint."
                }
            ]
        },
        {
            "id": 2,
            "main_heading": "Digital Footprint Management",
            "body": [
                {
                    "title": "Why is it important?",
                    "content": "It is important because it can be used to track your online activities and can be used to identify you."
                },
                {
                    "title": "How to manage it?",
                    "content": "Be mindful of what you post online, use privacy settings, and regularly check your digital footprint."
                },
                {
                    "title": "What is a Digital Footprint?",
                    "content": "A digital footprint is the collection of all the information you share and actions you take online."
                }
            ]
        },
        {
            "id": 3,
            "main_heading": "Digital Footprint Management",
            "body": [
                {
                    "title": "Why is it important?",
                    "content": "It is important because it can be used to track your online activities and can be used to identify you."
                },
                {
                    "title": "How to manage it?",
                    "content": "Be mindful of what you post online, use privacy settings, and regularly check your digital footprint."
                },
                {
                    "title": "What is a Digital Footprint?",
                    "content": "A digital footprint is the collection of all the information you share and actions you take online."
                }
            ]
        }
    ]
}

export const getLearningSlidesByGSM = (gsm_no) => {
    return LEARNING_SLIDES_BY_GSM[gsm_no] || LEARNING_SLIDES_BY_GSM["DQ0.0"];
}