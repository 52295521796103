import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { withRouter } from "react-router";
import { Redirect } from "react-router-dom";
import {Copyright} from '../../../components';
import { useFormik } from 'formik';
import {loginUser} from '../../../services/auth.service';
import { useAuth } from "../../../context/auth";
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import PoweredbyDQ from "../../../assets/images/PoweredByDQ_Default.png"
import 'aos/dist/aos.css'
import AOS from 'aos'
import Box from "@material-ui/core/Box";
import {LOCAL_STORAGE_KEYS} from "../../../utils/constants";

const useStyles = makeStyles((theme) => ({
    textField: {
        "& .MuiFilledInput-root": {
            backgroundColor: '#a5a5a5',
            borderRadius: '30px !important',
            "& fieldset": {
                borderRadius: '30px !important',
            },
        },
        "& .MuiFilledInput-root.Mui-focused": {
            backgroundColor: '#a5a5a5',
        },
        '& .MuiInputLabel-root': {
            color: '#a5a5a5',
        },
        '& .MuiInputLabel-root.Mui-focused': {
            color: '#a5a5a5',
        },
        '& .MuiOutlinedInput-input': {
            color: '#a5a5a5',
        },
    },
    checkbox: {
        '& .MuiSvgIcon-root': {
            color: '#a5a5a5',
        },
        '& .Mui-checked': {
            color: '#ed6628',
        },
    },
    paper: {
        background: theme.palette.background.default,
        color: theme.palette.loginPage.text,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent:'center',
        textAlign:'center',
        fontFamily:'Surt',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '80%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        color:theme.palette.background.default,
        fontFamily:'Surt',
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        background: '#fff',
        border:'2px solid #ed6628',
        color:  '#ed6628',
        width:'100%',
        fontSize: '21px',
        '&:hover':{
            background: '#ed6628',
            color: '#fff',
        }
    },
    section1: {
        height:'100vh',
        fontFamily:'Surt',
        '@media (min-width: 768px) and (max-width: 960px)': {
            height:'75%',
        },
        '@media (min-width: 960px) and (max-width: 1024px)': {
            height:'40%',
        },
    },

    formBackground: {
        background: theme.palette.loginPage.text,
        padding: '20px',
    },
}));

const Login = (props) => {
    // const [isLoggedIn, setLoggedIn] = useState(false);
    const classes = useStyles();
    const { setAuthToken, setAuthUser } = useAuth();
    const referer = props.location.referer || '/select_packages';
    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            remember_me: false
        },
        onSubmit: async (values) => {
            console.log('Form data => ', values);
            try {
                setLoading(true);
                const result = await loginUser(values.email, values.password, values.remember_me);
                console.log('result.data -> ', result.data);
                if (result.data && result.data.user) {
                    await setAuthToken(result.data.auth_token);
                    await setAuthUser(result.data.user);
                    localStorage.setItem(LOCAL_STORAGE_KEYS.refresh_token, JSON.stringify(result.data.refresh_token));
                    return <Redirect to={referer} />;
                    // setLoggedIn(true);
                }
                setLoading(false);
            } catch (e) {
                // already toasted the error
                setLoading(false);
            }
        }
    });

    useEffect(() => {
        AOS.init({
            duration: 500,
        });
    }, []);


    return (
        <>
            <div>
                <Grid container className={classes.section1} data-aos="fade-in" data-aos-delay='100'>
                    <Grid item xs={12} md={12} style={{backgroundColor:'white', marginTop:'auto'}}>
                        <Container component="main" maxWidth="sm">
                            <div className={classes.paper} style={{
                                marginTop: '40px',
                                paddingBottom: '50px',
                            }}>
                                <img src={PoweredbyDQ} alt="Image"
                                     style={{
                                         width:'300px',
                                         cursor: 'pointer'
                                     }}/>
                                <form className={classes.form} noValidate onSubmit={formik.handleSubmit}
                                      style={{fontFamily: 'Surt'}}>
                                    <TextField
                                        className={classes.textField}
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="email"
                                        label="Email Address"
                                        name="email"
                                        autoComplete="email"
                                        autoFocus
                                        onChange={formik.handleChange}
                                        value={formik.values.email}
                                    />
                                    <TextField
                                        className={classes.textField}
                                        style={{borderRadius:'30px'}}
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="password"
                                        label="Password"
                                        type="password"
                                        id="password"
                                        autoComplete="current-password"
                                        onChange={formik.handleChange}
                                        value={formik.values.password}
                                    />
                                    <Grid container>
                                        <FormControlLabel
                                            control={<Checkbox
                                                className={classes.checkbox}
                                                name="remember_me"
                                                id="remember_me"
                                                value={formik.values.remember_me}
                                                onChange={formik.handleChange}
                                            />}
                                            label="Remember me"
                                            style={{color: '#000', fontFamily: 'Surt'}}
                                        />
                                        <Grid item xs style={{
                                            textAlign: 'right',
                                            paddingTop: '2%',
                                            fontFamily: 'Surt',
                                            color: '#000'
                                        }}>
                                            <Link href="/account/recover" variant="body2">
                                                Forgot password?
                                            </Link>
                                        </Grid>
                                    </Grid>
                                    <Typography align='center'>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            className={classes.submit}
                                        >
                                            Sign In
                                        </Button>
                                    </Typography>

                                    <Grid item style={{textAlign: 'center', fontFamily: 'Surt', color: '#000'}}>
                                        <Link href="/register" variant="body2">
                                            {"Don't have an account? Sign Up"}
                                        </Link>
                                    </Grid>

                                </form>
                            </div>

                            <LoaderWithBackDrop loading={loading}/>
                        </Container>
                    </Grid>
                    <Box mt={7} style={{display:'flex', alignItems:'center', justifyContent:'center', width:'100%',}}>
                        <Copyright />
                    </Box>
                </Grid>
            </div>
        </>
    );
}

export default withRouter(Login);