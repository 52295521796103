import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from '@material-ui/icons/Home';
import SearchIcon from '@material-ui/icons/Search';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import UndoIcon from '@material-ui/icons/Undo';
import List from '@material-ui/core/List';
import ExistToAppIcon from '@material-ui/icons/ExitToApp';
import { withRouter } from "react-router";
import {toastWarning} from "../../../utils/utils";


const useStyles = makeStyles((theme) => ({
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));


const MainList = (props) => {

    const { history, path, logoutAction } = props;

    return (
        <List style={{paddingTop:'30px'}}>

            <ListItem selected={path === 'home'} button onClick={()=>history.push('/select_packages')}>
                <ListItemIcon>
                    <HomeIcon style={{color: '#F4F4F4'}}/>
                </ListItemIcon>
                <ListItemText primary="Home" />
            </ListItem>

            <ListItem selected={path === 'company__view'} button onClick={()=>history.push('/program_creation')}>
                <ListItemIcon>
                    <EditIcon style={{color: '#F4F4F4'}}/>
                </ListItemIcon>
                <ListItemText primary="New Program"/>
            </ListItem>

            <ListItem selected={path === 'company__view'} button onClick={()=>{toastWarning('Not Implemented !!');}}>
                <ListItemIcon>
                    <SearchIcon style={{color: '#F4F4F4'}}/>
                </ListItemIcon>
                <ListItemText primary="Search Definitions" />
            </ListItem>

            <ListItem selected={path === 'company__view'} button onClick={()=>{toastWarning('Not Implemented !!');}}>
                <ListItemIcon>
                    <FaceIcon style={{color: '#F4F4F4'}}/>
                </ListItemIcon>
                <ListItemText primary="AI-Chat Bot" />
            </ListItem>

            <ListItem selected={path === 'company__view'} button onClick={()=>{toastWarning('Not Implemented !!');}}>
                <ListItemIcon>
                    <UndoIcon style={{color: '#F4F4F4'}}/>
                </ListItemIcon>
                <ListItemText primary="Undo" />
            </ListItem>

            {/*<ListItem selected={path === 'application__list'} button onClick={()=>history.push('/application/list')}>*/}
            {/*    <ListItemIcon>*/}
            {/*        <AllInboxIcon style={{color: '#F4F4F4'}}/>*/}
            {/*    </ListItemIcon>*/}
            {/*    <ListItemText primary="Applications" />*/}
            {/*</ListItem>*/}

            {/*<ListItem button onClick={logoutAction}>*/}
            {/*    <ListItemIcon>*/}
            {/*        <ExistToAppIcon style={{color: '#F4F4F4'}}/>*/}
            {/*    </ListItemIcon>*/}
            {/*    <ListItemText primary="Logout" />*/}
            {/*</ListItem>*/}
        </List>
    );
}

export default withRouter(MainList);