
const INSTRUMENTS_BY_GSM = {
    "DQ1.1": [
        {
            "gsm_no": "DQ1.1",
            "id": "dqworld.q33",
            "question": "Which of these things affect how people perceive you online?",
            "options": [
                "Your profile - the choice of avatar",
                "Pictures you share online",
                "Comments you make online",
                "How old or new the model of your mobile phone is"
            ],
            "goal": "This question evaluates the student's understanding about..."
        },
        {
            "gsm_no": "DQ1.1",
            "id": "dqworld.q36",
            "question": "What are the possible consequences of having a negative online persona?",
            "options": [
                "People will think this is how you are in the real world and may not like you.",
                "If you face problems later on, people may not give you the benefit of the doubt because you don’t seem to be a nice person.",
                "The negativity can damage your computer equipment.",
                "It may affect how people will assess you for sports, university, or job opportunities.",
                "It can be difficult to change how people perceive you later on because the online evidence is hard to erase."
            ],
            "goal": "This question evaluates the student's understanding about..."
        },
        {
            "gsm_no": "DQ1.1",
            "id": "dqworld.q37",
            "question": "Is it okay to bully people online if I use an anonymous ID that nobody can trace back to me?",
            "options": [
                "Yes, but only if the person really deserves it.",
                "No, because I'm still making the online world an unhappy, unsafe place which is bad for everyone.",
                "No, I still feel uncomfortable to be part of cyberbullying.",
                "No, because someone might still figure out who I am."
            ],
            "goal": "This question evaluates the student's understanding about..."
        }
    ],
    "DQ26.1": [
        {
            "gsm_no": "DQ26.1",
            "id": "dqtest.q2a",
            "question": "What do you usually do when you access the Internet?",
            "options": [
                "Using a search engine (like Google or Yahoo)",
                "Watching a video online (like Youtube or Vimeo)",
                "Listening to music (like Spotify, Melon)",
                "Posting a comment online",
                "Sending a chat message through an app (like WhatsApp, WeChat, Kakao)",
                "Sending an email through a website (like Gmail or Hotmail)",
                "Playing video games (like Minecraft or Roblox)",
                "Creating a profile on a social network site (like Instagram, Facebook, Twitter)",
                "Sharing videos or pictures with others on a social network site (like Instagram, Facebook, Twitter)",
                "Sharing videos or pictures to friends through a website (like Gmail or Hotmail)",
                "Buying or selling things on an e-commerce site (like Amazon, e-bay, G-market)",
                "Accessing free information portals, news sites, and blogs (like Yahoo, Huffington Post)",
                "Access paid/subscription-based information and news sites (like New York Times, Wall Street Journal, Financial Times)",
                "Accessing educational sites (like Coursera, Udemy)",
                "Professional Networking (like LinkedIn)",
                "Browsing/participating in online communities and forums (e.g., Reddit, TripAdvisor)",
                "Others (please specify)"
            ],
            "goal": "This question evaluates the student's understanding about..."
        },
        {
            "gsm_no": "DQ26.1",
            "id": "dqworld.q114",
            "question": "Which social networking or chatting apps do you use?",
            "options": [
                "Snapchat",
                "WhatsApp",
                "WeChat",
                "Kakao",
                "Line",
                "TikTok",
                "Instagram",
                "Twitter",
                "Tumblr",
                "LinkedIn",
                "Facebook",
                "YouTube",
                "Others (name them)",
                "I don't use any social media"
            ],
            "goal": "This question evaluates the student's understanding about..."
        }
    ],
    "DQ1.3": [
        {
            "gsm_no": "DQ1.3",
            "id": "dqworld.q53",
            "question": "Check the statements that you agree with:",
            "options": [
                "I'm interested in learning about the many people and countries around the world.",
                "I would describe myself as a global citizen.",
                "I think I'm connected to people in other countries, and my online actions can affect them.",
                "None of the above"
            ],
            "goal": "This question evaluates the student's understanding about..."
        }
    ],
    "DQ6.1": [
        {
            "gsm_no": "DQ6.1",
            "id": "dqworld.q259",
            "question": "Which of these activities can leave a digital footprint?",
            "options": [
                "Sending photos to a friend over email privately",
                "Downloading a video file",
                "Chatting on an online forum",
                "Filling out a form online to register for a new account",
                "Posting a photo online",
                "Sending a snap on Snapchat",
                "Writing a story on a piece of paper",
                "Asking Siri to find a restaurant near you"
            ],
            "goal": "This question evaluates the student's understanding about..."
        }
    ],
    "DQ6.2": [
        {
            "gsm_no": "DQ6.2",
            "id": "dqworld.q269",
            "question": "Why should you care about your digital footprint?",
            "options": [
                "Your digital footprint can affect how other people see you.",
                "Your digital footprint can be a security risk because people can easily gather information from you.",
                "You might regret the information posted online at a later stage.",
                "Having a lot of digital footprints can use up storage space on mobile devices."
            ],
            "goal": "This question evaluates the student's understanding about..."
        }
    ],
    "DQ2.1": [
        {
            "gsm_no": "DQ2.1",
            "id": "dqtest.q15",
            "question": "On a typical weekday, (Monday through Friday), how many hours do you use digital media between when you wake up and until lunch time?",
            "options": [
                "Never",
                "less than 1 hour",
                "1 - 2 hours",
                "2 - 3 hours",
                "3 - 4 hours",
                "4 hours or more"
            ],
            "goal": "This question evaluates the student's understanding about..."
        },
        {
            "gsm_no": "DQ2.1",
            "id": "dqtest.q14",
            "question": "On a typical weekday, (Monday through Friday), how many hours do you use digital media between lunch and dinner?",
            "options": [
                "Never",
                "less than 1 hour",
                "1 - 2 hours",
                "2 - 3 hours",
                "3 - 4 hours",
                "4 hours or more"
            ],
            "goal": "This question evaluates the student's understanding about..."
        },
        {
            "gsm_no": "DQ2.1",
            "id": "dqtest.q13",
            "question": "On a typical weekday, (Monday through Friday), how many hours do you use digital media between dinner and bedtime?",
            "options": [
                "Never",
                "less than 1 hour",
                "1 - 2 hours",
                "2 - 3 hours",
                "3 - 4 hours",
                "4 hours or more"
            ],
            "goal": "This question evaluates the student's understanding about..."
        },
        {
            "gsm_no": "DQ2.1",
            "id": "dqtest.q18",
            "question": "On a typical weekend day, (Saturday and Sunday), how many hours do you use digital media between when you wake up and until lunch time?",
            "options": [
                "Never",
                "less than 1 hour",
                "1 - 2 hours",
                "2 - 3 hours",
                "3 - 4 hours",
                "4 hours or more"
            ],
            "goal": "This question evaluates the student's understanding about..."
        },
        {
            "gsm_no": "DQ2.1",
            "id": "dqtest.q17",
            "question": "On a typical weekend day, (Saturday and Sunday), how many hours do you use digital media between lunch and dinner?",
            "options": [
                "Never",
                "less than 1 hour",
                "1 - 2 hours",
                "2 - 3 hours",
                "3 - 4 hours",
                "4 hours or more"
            ],
            "goal": "This question evaluates the student's understanding about..."
        },
        {
            "gsm_no": "DQ2.1",
            "id": "dqtest.q16",
            "question": "On a typical weekend day, (Saturday and Sunday), how many hours do you use digital media between dinner and bedtime?",
            "options": [
                "Never",
                "less than 1 hour",
                "1 - 2 hours",
                "2 - 3 hours",
                "3 - 4 hours",
                "4 hours or more"
            ],
            "goal": "This question evaluates the student's understanding about..."
        }
    ],
    "DQ2.2": [
        {
            "gsm_no": "DQ2.2",
            "id": "dqpro.q365",
            "question": "Within the PAST ONE YEAR, which of the following have you experienced?",
            "options": [
                "There have been periods when I was constantly thinking about playing digital media while at school or work.",
                "I have experienced serious conflicts with family, friends or others because of playing digital media.",
                "I have received a bad grade on an assignment or test because I spent too much time playing digital media.",
                "I have spent more and more time and/or money on playing digital media in order to feel the same amount of excitement.",
                "I have felt I could not stop playing digital media.",
                "I have played digital media so that I would not have to think about annoying things.",
                "I have lied to others about how much I play digital media.",
                "I have lost interest in hobbies or other activities because playing digital media is all I wanted to do.",
                "I have felt miserable when I was unable to play digital media.",
                "None of the above"
            ],
            "goal": "This question evaluates the student's understanding about..."
        }
    ],
    "DQ2.3": [
        {
            "gsm_no": "DQ2.3",
            "id": "dqworld.q100",
            "question": "Why is it better not to be online while you are doing your work?",
            "options": [
                "It uses bandwidth inefficiently.",
                "It’s hard to concentrate on your work, it takes longer to get it done and you can end up doing a worse job.",
                "You may be bugging other people trying to do their own work.",
                "It’s good to give your fingers a rest from messaging."
            ],
            "goal": "This question evaluates the student's understanding about..."
        }
    ],
    "DQ2.5": [
        {
            "gsm_no": "DQ2.5",
            "id": "amf.q26",
            "question": "Do you currently make any of the following efforts to manage your screen time?",
            "options": [
                "I have no devices in my bedroom.",
                "I don't use devices during family meals.",
                "I use the night mode setting.",
                "I charge my device overnight in a family area, outside my bedroom.",
                "I set a timer/alarm when playing online.",
                "I use apps to keep track of screen time.",
                "My family has some technology rules for our home - e.g. when, how much and how to use digital media.",
                "None of the above"
            ],
            "goal": "This question evaluates the student's understanding about..."
        },
        {
            "gsm_no": "DQ2.5",
            "id": "dqworld.q348",
            "question": "Which statement is/are true about you?",
            "options": [
                "I’m good at managing time so that I can finish my work before going online for entertainment.",
                "I believe I can stop going online if I need to.",
                "I plan my day so that I have enough time to finish my school work at home before going online.",
                "When I go online for entertainment, I have an idea of when I'm going to stop playing.",
                "None of the above"
            ],
            "goal": "This question evaluates the student's understanding about..."
        }
    ],
    "DQ3.4": [
        {
            "gsm_no": "DQ3.4",
            "id": "dqtest.q64",
            "question": "In the PAST ONE YEAR, have you done any of the following while online?",
            "options": [
                "I made fun of someone by sending or posting stories, jokes or pictures about him/her.",
                "I purposely left someone out in an online group.",
                "I sent or posted cruel and untrue things about someone.",
                "I entered or used someone’s email, website or computer without his/her permission then made changes or sent something to make them look bad.",
                "I sent or posted messages to someone saying that I will hurt them.",
                "I pretended to be someone else and sent or posted messages to get that person into trouble or make the person look bad.",
                "I kept on sending someone rude and insulting messages.",
                "I spread rumours or gossip about someone online.",
                "I tricked someone to get them to provide me secret or embarrassing information and then I sent or posted it online to others.",
                "I have not done any unkind behaviour."
            ],
            "goal": "This question evaluates the student's understanding about..."
        },
        {
            "gsm_no": "DQ3.4",
            "id": "dqtest.q66",
            "question": "During the PAST ONE YEAR, have you ever been hurt or offended by someone who was treating you unkindly online?",
            "options": [
                "Yes",
                "No",
                "Not Sure"
            ],
            "goal": "This question evaluates the student's understanding about..."
        }
    ],
    "DQ3.1": [
        {
            "gsm_no": "DQ3.1",
            "id": "dqworld.q166",
            "question": "Which of the following comments is true?",
            "options": [
                "I am not part of cyberbullying if I just share an embarrassing video about a classmate to my friends. I just want to make my friends laugh.",
                "I am not part of cyberbullying if I just forward a mean message about someone to other friends through mobile.",
                "You can’t get cyberbullied on Instagram.",
                "None of the above"
            ],
            "goal": "This question evaluates the student's understanding about..."
        }
    ],
    "DQ3.2": [
        {
            "gsm_no": "DQ3.2",
            "id": "dqpro.q498",
            "question": "In the PAST ONE YEAR, if you were treated in an unkind manner by another person (or group of people) online, what did you do?",
            "options": [
                "Ignore the problem and delete the message(s) right away.",
                "Fight back to the person who was unkind to me.",
                "Ask the person to leave me alone.",
                "Don’t respond to the person.",
                "Screenshot the evidence.",
                "Change my privacy/contact setting.",
                "Report and/or block the person in the digital media sites/apps.",
                "Reduce or stop using some of the digital media sites/apps.",
                "Talk to someone (e.g. friends, families, or teachers).",
                "Report to the police or the government agency responsible for online safety.",
                "Use a helpline.",
                "None of above"
            ],
            "goal": "This question evaluates the student's understanding about..."
        }
    ],
    "DQ3.3": [
        {
            "gsm_no": "DQ3.3",
            "id": "dqworld.q133",
            "question": "Check the statement that you agree with.",
            "options": [
                "I think sending mean messages/pictures to others is funny.",
                "Sending mean messages to someone who deserves it is not cyberbullying.",
                "If I receive messages that I think are mean, I should send some mean messages of my own back to them.",
                "I think it is ok to send nasty or mean things to them when they deserve it.",
                "None of the above"
            ],
            "goal": "This question evaluates the student's understanding about..."
        }
    ],
    "DQ11.3": [
        {
            "gsm_no": "DQ11.3",
            "id": "dqworld.q344",
            "question": "Have you chatted with people you have never met before?",
            "options": [
                "Yes",
                "No"
            ],
            "goal": "This question evaluates the student's understanding about..."
        },
        {
            "gsm_no": "DQ11.3",
            "id": "dqworld.q346",
            "question": "Have you met someone in real life who you only knew online?",
            "options": [
                "Yes",
                "No"
            ],
            "goal": "This question evaluates the student's understanding about..."
        },
        {
            "gsm_no": "DQ11.3",
            "id": "dqtest.q79",
            "question": "In the PAST ONE YEAR, have you experienced any of these situations whilst online?",
            "options": [
                "I received an unwelcome request to send an intimate image of myself or others",
                "I received an unwelcome request for sexual favours",
                "I received unwanted sexual messages or images",
                "I received persistent unwelcome demands to develop a romantic or sexual relationship",
                "I received repeated and unwelcome sexually-oriented teasing, joking, or flirting",
                "Someone demanded that I send them sexual images and threatened to distribute my personal information if/when I refused",
                "None of the above"
            ],
            "goal": "This question evaluates the student's understanding about..."
        }
    ],
    "DQ27.3": [
        {
            "gsm_no": "DQ27.3",
            "id": "dqworld.q319",
            "question": "Have you done any of these things while online?",
            "options": [
                "Come across sexual content online without actively searching for it",
                "Searched for sexual content",
                "Went to websites with sexual content after a search",
                "Lied about age to access websites with sexual content",
                "Downloaded sexual content on purpose",
                "Received sexual content from someone",
                "Sent someone sexual content",
                "Talked about sexual content with someone you’ve never met in real life",
                "None of the above"
            ],
            "goal": "This question evaluates the student's understanding about..."
        },
        {
            "gsm_no": "DQ27.3",
            "id": "amf.before.q226",
            "question": "In the PAST ONE YEAR, what type of offensive content have you come across online?",
            "options": [
                "An intimate image taken and shared without consent",
                "A fake or modified intimate image",
                "Hateful or discriminatory comments posted on social media",
                "Online streaming video spouting hate or discrimination",
                "Online songs with hateful or discriminatory lyrics",
                "Hateful or discriminatory memes",
                "I have NOT come across offensive content."
            ],
            "goal": "This question evaluates the student's understanding about..."
        }
    ],
    "DQ11.2": [
        {
            "gsm_no": "DQ11.2",
            "id": "dqworld.q290",
            "question": "Which of these are signs you should stop communicating with an online friend?",
            "options": [
                "They make you feel bad.",
                "They are always asking for personal info.",
                "They tell you not to trust your friends or parents.",
                "All of the above"
            ],
            "goal": "This question evaluates the student's understanding about..."
        },
        {
            "gsm_no": "DQ11.2",
            "id": "dqworld.q343",
            "question": "What would you do if a stranger contacted with you online?",
            "options": [
                "Ignore him/her.",
                "Ask my friends, or family members if they know this person and decide if I want to block him/her or reply to his/her request.",
                "Reply to his/her request but only allow him/her to see a limited profile.",
                "Close the page or turn off the computer.",
                "Reply his/her request or message.",
                "Temporarily respond yes to his/her request and check his/her profiles and then delete it shortly after.",
                "Don’t know.",
                "I usually connect with new people I meet online."
            ],
            "goal": "This question evaluates the student's understanding about..."
        }
    ],
    "DQ27.2": [
        {
            "gsm_no": "DQ27.2",
            "id": "dqpro.q756",
            "question": "What do you do when you come across with something that you don’t want to see.\nI would..",
            "options": [
                "Get rid of it immediately by closing the page or deleting the file",
                "Block the website",
                "Use filters and software that prevent this from happening again",
                "Talk about it with a trusted adult",
                "Look away and close my eyes!",
                "It does not happen to me. I am extra careful which links or video I click",
                "Just watch and check it out",
                "Share it with your friend to warn them about this"
            ],
            "goal": "This question evaluates the student's understanding about..."
        }
    ],
    "DQ0.0": [
        {
            "id": 1,
            "question": "What is a Digital Footprint?",
            "options": [
                "Information you share and actions you take online.",
                "Information you share and actions you take offline.",
                "Information you share and actions you take on phone.",
                "Information you share and actions you take on computer."
            ],
            "goal": "This question evaluates the student's understanding of what a digital footprint is."
        },
        {
            "id": 2,
            "question": "Why is it important?",
            "options": [
                "It can be used to track your online activities and can be used to identify you.",
                "It can be used to track your offline activities and can be used to identify you.",
                "It can be used to track your phone activities and can be used to identify you.",
                "It can be used to track your computer activities and can be used to identify you."
            ],
            "goal": "This question evaluates the student's understanding of why digital footprints are important."
        },
        {
            "id": 3,
            "question": "How to manage it?",
            "options": [
                "Be mindful of what you post online, use privacy settings, and regularly check your digital footprint.",
                "Be mindful of what you post offline, use privacy settings, and regularly check your digital footprint.",
                "Be mindful of what you post on phone, use privacy settings, and regularly check your digital footprint.",
                "Be mindful of what you post on computer, use privacy settings, and regularly check your digital footprint."
            ],
            "goal": "This question evaluates the student's understanding of how to manage their digital footprint."
        }
    ]
}

export const getInstrumentByGSM = (gsm_no) => {
    return INSTRUMENTS_BY_GSM[gsm_no] || INSTRUMENTS_BY_GSM["DQ0.0"];
}