import React, {useEffect} from 'react';
import {MainList} from './components';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';


import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ExistToAppIcon from '@material-ui/icons/ExitToApp';

import {Copyright} from '../../components'

import { withRouter } from "react-router";

import { logoutUser } from '../../services/auth.service';
import { useAuth } from "../../context/auth";
import {LOCAL_STORAGE_KEYS} from "../../utils/constants";
import Avatar from "@material-ui/core/Avatar";
import {AccountCircle} from "@material-ui/icons";
import PoweredbyDQ from "../../assets/images/PoweredByDQ_Default.png";

const drawerWidth = 275;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    paper: {
        marginRight: theme.spacing(2),
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: "#fff",
    },
    appBarShift: {
        boxShadow:'none !important',
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
        color: theme.palette.primary.main,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
        color: theme.palette.primary.main,
    },
    user_email: {
        flexGrow: 1,
        textAlign: "right",
        cursor: 'pointer'
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        background: "linear-gradient(to bottom, #EE6D2C, #FECE60)",
        color: "white"
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        // paddingTop: theme.spacing(4),
        padding:'0px !important',
        display:'flex',
        flexDirection:'column',
        minHeight: '90vh',

    },
    footer: {
        marginTop: 'auto',
        textAlign: 'center',
        width:'full-width',
      },

}));

// - List
// - Accounts
// - Config
// - Subscriptions
// - Module UI Conf
// - Badge UI Conf
// - All Modules (Areas)
// - All Badges

const pathToLabelHeadMap = {
    'home': 'DQ Packages',
    'password__change': 'Change Password',
    'ui_config': 'UI Configs',

    'overview': 'Applications Overview',

    'company__view': 'Organization Information',
    'company__update': 'Update Organization Information',

    'application__list': 'Submitted Applications',
    'application__create': 'Create Application',
};


const Main = props => {
    const { children, history, location } = props;
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [labelHeading, setLabelHeading] = React.useState('');
    const { setAuthToken, setAuthUser, authUser } = useAuth();

    const [pathKey, setPathKey] = React.useState('home');

    const anchorRefUserMenu = React.useRef(null);
    const [openUserMenu, setOpenUserMenu] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleUserMenuToggle = () => {
        setOpenUserMenu((prevOpen) => !prevOpen);
    };

    const handleUserMenuClose = (event) => {
        if (anchorRefUserMenu.current && anchorRefUserMenu.current.contains(event.target)) {
            return;
        }

        setOpenUserMenu(false);
    };

    const handleChangePassword = (event) => {
        handleUserMenuClose(event);
        history.push('/password/change');
    }

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpenUserMenu(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevUserMenuOpen = React.useRef(openUserMenu);
    React.useEffect(() => {
        if (prevUserMenuOpen.current === true && openUserMenu === false) {
            anchorRefUserMenu.current.focus();
        }

        prevUserMenuOpen.current = openUserMenu;
    }, [openUserMenu]);

    const handleLogout = async () => {
        try {
            await logoutUser();
            setAuthToken(null);
            setAuthUser(null);
            localStorage.removeItem(LOCAL_STORAGE_KEYS.auth_token);
            localStorage.removeItem(LOCAL_STORAGE_KEYS.auth_user);
            localStorage.removeItem(LOCAL_STORAGE_KEYS.refresh_token);
            // history.push('/login');
        } catch (e) {
            // already toasted the error
        }
    };

    useEffect( ()=>{

        const path_parts = location.pathname.split('/');
        console.log('path_parts --> ', path_parts)
        if (path_parts.length > 1) {
            if (path_parts.length < 3) {
                const path_key = path_parts[1];
                if (pathToLabelHeadMap[path_key]) {
                    setLabelHeading(pathToLabelHeadMap[path_key]);
                    setPathKey(path_key);
                }
            } else {
                const path_key = `${path_parts[1]}__${path_parts[2]}`;
                if (pathToLabelHeadMap[path_key]) {
                    setLabelHeading(pathToLabelHeadMap[path_key]);
                    setPathKey(path_key);
                }
            }
        }

    },[location]);

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
                <Toolbar className={classes.toolbar}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                        {labelHeading}
                    </Typography>

                    <div>
                        {/*<IconButton*/}
                        {/*    style={{marginRight:'30px'}}*/}
                        {/*    ref={anchorRefUserMenu}*/}
                        {/*    aria-controls={openUserMenu ? 'menu-list-grow' : undefined}*/}
                        {/*    aria-haspopup="true"*/}
                        {/*    onClick={handleUserMenuToggle}>*/}
                        {/*    <Avatar style={{color:'white', backgroundColor:'#052F44', height:'55px', width: '55px', }}>*/}
                        {/*        <AccountCircle style={{ height:'35px', width:'35px', }}/>*/}
                        {/*    </Avatar>*/}
                        {/*</IconButton>*/}
                        <img src={PoweredbyDQ} alt="Image"
                             style={{
                                 width: '200px',
                                 cursor: 'pointer'
                             }}/>
                        <Popper open={openUserMenu} anchorEl={anchorRefUserMenu.current} role={undefined} transition
                                disablePortal>
                            {({TransitionProps, placement}) => (
                                <Grow
                                    {...TransitionProps}
                                    style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}
                                >
                                    {/*<Typography*/}
                                    {/*    color="inherit" noWrap className={classes.user_email}>{authUser.email}*/}
                                    {/*</Typography>*/}
                                    <Paper style={{border: '1px solid #052F44'}}>
                                        <ClickAwayListener onClickAway={handleUserMenuClose}>
                                            <MenuList autoFocusItem={openUserMenu} id="menu-list-grow"
                                                      onKeyDown={handleListKeyDown}>
                                                <MenuItem onClick={handleChangePassword}>Change Password</MenuItem>
                                                <br/>
                                                <Divider style={{backgroundColor: '#052F44'}}/>
                                                <MenuItem style={{position: 'bottom', right: '0'}}
                                                          onClick={handleLogout}>Logout</MenuItem>
                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    </div>

                    {/*<IconButton color="inherit" onClick={handleLogout}>*/}
                    {/*    <ExistToAppIcon/>*/}
                    {/*</IconButton>*/}
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                classes={{
                    paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                }}
                open={open}
            >
                <div className={classes.toolbarIcon}>
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon style={{color: 'white'}}/>
                    </IconButton>
                </div>
                <Divider />
                <MainList path={pathKey} logoutAction={handleLogout} />
            </Drawer>
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container maxWidth={false} className={classes.container}>
                    {children}
                    <footer className={classes.footer}>
                    <Box pt={4}>
                        <Copyright />
                    </Box>
                    </footer>
                </Container>
            </main>
        </div>
    );
}

export default withRouter(Main);