import {store} from "react-notifications-component";
import {TOAST_TYPES} from "./constants";

export const toast = (type, title, message, duration = 10000) => {
    store.addNotification({
        title,
        message,
        type,
        insert: "bottom",
        container: "top-center",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
            duration,
            showIcon: true
        }
    });
}

export const toastWarning = (message) => {
    toast(TOAST_TYPES.WARN, "Server Response!", message);
}