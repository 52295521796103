import {ALL_CURRICULA, CURRICULUM_SKILLS_BY_CODE} from "../data/curriculums.data";
import {ALL_DIGITAL_SKILLS} from "../data/digital_skills.data";
import {ALL_GSM_BY_DQC, ALL_GSM_BY_GSM} from "../data/learning_objectives_gsm_data";
import {getOutcomeByGSMNo} from "../data/student_outcomes.data";
import {getLearningGoalsByGSM} from "../data/learning_content_gsm.goals.data";
import {getLearningSlidesByGSM} from "../data/learning_content_gsm.slides.data";
import {
    gen_theme_store_construct, THEME_BACKGROUND_COLOR,
    THEME_HEADING_COLOR,
    THEME_HEADING_FONT,
    THEME_HEADING_SIZE,
    THEME_LAYOUT, THEME_PARAGRAPH_COLOR,
    THEME_PARAGRAPH_FONT,
    THEME_PARAGRAPH_SIZE,
    THEME_SUB_HEADING_COLOR,
    THEME_SUB_HEADING_FONT,
    THEME_SUB_HEADING_SIZE
} from "../pages/Packages/CustomDigitalProgram/theme_store";
import {getInstrumentByGSM} from "../data/instruments_gsm.data";

let localUserDataStore = {}
const persistLocalUserDataStore = () => {
    localStorage.setItem("localUserDataStore", JSON.stringify(localUserDataStore));
}
const retrieveLocalUserDataStore = () => {
    return JSON.parse(localStorage.getItem("localUserDataStore"));
}

const slide_theme_init_state = gen_theme_store_construct(
    {
        layout: THEME_LAYOUT.LAYOUT_A,
        heading_color: THEME_HEADING_COLOR.COLOR_A,
        heading_font: THEME_HEADING_FONT.FONT_A,
        heading_size: THEME_HEADING_SIZE.MEDIUM,
        sub_heading_color: THEME_SUB_HEADING_COLOR.COLOR_A,
        sub_heading_font: THEME_SUB_HEADING_FONT.FONT_A,
        sub_heading_size: THEME_SUB_HEADING_SIZE.MEDIUM,
        paragraph_color: THEME_PARAGRAPH_COLOR.COLOR_A,
        paragraph_font: THEME_PARAGRAPH_FONT.FONT_A,
        paragraph_size: THEME_PARAGRAPH_SIZE.MEDIUM,
        background_color: THEME_BACKGROUND_COLOR.COLOR_A,
        image_url: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTD2iBWZ78SGuKicSUjrZE2jr85W8Wu3ecxcA&s'
    }
)

export const getAllCurriculums = async () => {
    // TODO - call the api server here, for now fetching from constant data

    return  {
        data: ALL_CURRICULA
    };
}

export const getAllDigitalSkills = async () => {
    // TODO - call the api server here, for now fetching from constant data

    return  {
        data: ALL_DIGITAL_SKILLS
    };
}

export const selectCurriculum = async (curriculum_code) => {
    // TODO - call the api server here, for now storing data to local store
    const curriculum = ALL_CURRICULA.find(curriculum => curriculum.code === curriculum_code);

    const curriculum_skills = CURRICULUM_SKILLS_BY_CODE[curriculum_code];
    let user_program_units = [];
    for (const unit of curriculum_skills)  {
        let learning_unit = {}
        learning_unit["verbose_name"] = unit.unit_name;
        learning_unit["learning_objectives"] = unit.gsm_list.map(gsm_no =>
            ALL_GSM_BY_GSM.get(gsm_no) || `No data for ${gsm_no}`
        );

        for (const [index, lo] of learning_unit["learning_objectives"].entries()) {
            lo["verbose_name"] = `Lesson ${index+1}: ${lo.full_name}`;
            lo["lesson_no"] = index + 1;
            lo["student_outcomes"] = getOutcomeByGSMNo(lo.gsm_no);

            lo["pre_assessment"] = getInstrumentByGSM(lo.gsm_no);
            lo["post_assessment"] = getInstrumentByGSM(lo.gsm_no);

            lo["learning_content"] = getLearningSlidesByGSM(lo.gsm_no);
            for (const slide of lo["learning_content"]) {
                slide["theme"] = slide_theme_init_state
            }
            lo["learning_goals"] = getLearningGoalsByGSM(lo.gsm_no);
        }

        user_program_units.push(learning_unit);
    }


    localUserDataStore = {
        ...localUserDataStore,
        curriculum,
        user_program_units
    }
    persistLocalUserDataStore();

    return {
        data: {
            success: true,
            programState: localUserDataStore
        }
    };
}

export const selectDigitalSkills = async (digital_skill_codes) => {
    // TODO - call the api server here, for now storing data to local store

    // populate the GSM based on selected skills, and store it in localUserDataStore
    let user_program_units = []
    for (const [index, code] of digital_skill_codes.entries()) {
        let learning_unit = {}
        learning_unit["verbose_name"] = `Unit ${index+1}: ${ALL_DIGITAL_SKILLS.find(skill => skill.dqc_no === code).full_name}`;
        learning_unit["learning_objectives"] = ALL_GSM_BY_DQC.get(code) || [];

        for (const [index, lo] of learning_unit["learning_objectives"].entries()) {
            lo["verbose_name"] = `${lo.gsm_no.split("DQ")[1]} ${lo.full_name}`;
            lo["lesson_no"] = index + 1;
            lo["student_outcomes"] = getOutcomeByGSMNo(lo.gsm_no);

            lo["pre_assessment"] = getInstrumentByGSM(lo.gsm_no);
            lo["post_assessment"] = getInstrumentByGSM(lo.gsm_no);

            lo["learning_content"] = getLearningSlidesByGSM(lo.gsm_no);
            for (const slide of lo["learning_content"]) {
                slide["theme"] = slide_theme_init_state
            }
            lo["learning_goals"] = getLearningGoalsByGSM(lo.gsm_no);
        }

        user_program_units.push(learning_unit);
    }

    localUserDataStore = {
        ...localUserDataStore,
        user_program_units
    }
    persistLocalUserDataStore();

    return {
        data: {
            success: true,
            programState: localUserDataStore
        }
    };
}

export const updateProgramState = async (user_program_units) => {
    localUserDataStore = {
        ...localUserDataStore,
        user_program_units
    }
    return {
        data: {
            success: true,
            programState: localUserDataStore
        }
    };
}

export const getProgramState = async () => {
    // check if localUserDataStore is empty, then fetch from local storage
    if (Object.keys(localUserDataStore).length === 0) {
        localUserDataStore = retrieveLocalUserDataStore();
    }
    // if still empty, or undefined, then return empty object
    if (!localUserDataStore) {
        return {}
    }
    return {
        data: {
            programState: localUserDataStore
        }
    };
}