
export const ALL_DIGITAL_SKILLS = [
    {
        "dq_no": "DQ1",
        "dqc_no": "DQC1",
        "full_name": "Digital Citizen Identity",
        "definition": "The ability to build and manage a healthy identity as a digital citizen with integrity."
    },
    {
        "dq_no": "DQ2",
        "dqc_no": "DQC2",
        "full_name": "Balanced Use of Technology",
        "definition": "The ability to manage one’s life both online and offline in a balanced manner by exercising self-control to regulate screen time, multitasking, and engagement with digital media and devices."
    },
    {
        "dq_no": "DQ3",
        "dqc_no": "DQC3",
        "full_name": "Conduct Cyber-Risk Management",
        "definition": "The ability to identify, mitigate, and manage cyber-risks (e.g., cyberbullying, harassment, and stalking) related to personal online behaviors."
    },
    {
        "dq_no": "DQ4",
        "dqc_no": "DQC4",
        "full_name": "Personal Cyber Security Management",
        "definition": "The ability to detect cyber threats (e.g., hacking, scams, and malware) against personal data and devices, and employ appropriate security strategies and tools for protection."
    },
    {
        "dq_no": "DQ5",
        "dqc_no": "DQC5",
        "full_name": "Digital Empathy",
        "definition": "The ability to be aware of, sensitive to, and supportive of one’s own and others' feelings, needs, and concerns online."
    },
    {
        "dq_no": "DQ6",
        "dqc_no": "DQC6",
        "full_name": "Digital Footprint Management",
        "definition": "The ability to understand the nature of digital footprints and their real-life consequences, manage them responsibly, and actively build a positive digital reputation."
    },
    {
        "dq_no": "DQ7",
        "dqc_no": "DQC7",
        "full_name": "Media and Information Literacy",
        "definition": "The ability to find, organize, analyze, and evaluate media and information using critical reasoning."
    },
    {
        "dq_no": "DQ8",
        "dqc_no": "DQC8",
        "full_name": "Privacy Management",
        "definition": "The ability to handle all personal information shared online with discretion to protect one’s own and others’ privacy."
    },
    {
        "dq_no": "DQ9",
        "dqc_no": "DQC9",
        "full_name": "Digital Co-Creator Identity",
        "definition": "The ability to identify and develop oneself as a co-creator within the digital ecosystem."
    },
    {
        "dq_no": "DQ10",
        "dqc_no": "DQC10",
        "full_name": "Healthy Use of Technology",
        "definition": "The ability to understand the benefits and harms of technology on one’s mental and physical health and manage technology use while prioritizing health and well-being."
    },
    {
        "dq_no": "DQ11",
        "dqc_no": "DQC11",
        "full_name": "Contact Cyber-Risk Management",
        "definition": "The ability to identify, mitigate, and manage risky online contact (e.g., unwanted sexual contact, offline meetings, sexual exploitation)."
    },
    {
        "dq_no": "DQ12",
        "dqc_no": "DQC12",
        "full_name": "Network Security Management",
        "definition": "The ability to detect, avoid, and manage cyber threats to cloud-based collaborative digital environments."
    },
    {
        "dq_no": "DQ13",
        "dqc_no": "DQC13",
        "full_name": "Emotional Regulation and Relationship Management",
        "definition": "The ability to recognize and manage one's emotions in digital environments and to skillfully navigate online relationships through cooperation, conflict management, and persuasion."
    },
    {
        "dq_no": "DQ14",
        "dqc_no": "DQC14",
        "full_name": "Online Collaboration",
        "definition": "The ability to establish clear and effective communication modes that allow expression through technology to collaborate collectively and achieve intended goals."
    },
    {
        "dq_no": "DQ15",
        "dqc_no": "DQC15",
        "full_name": "Computational, Data, and AI Literacy",
        "definition": "The ability to synthesize, create, and produce information, media, and technology in innovative and creative ways. Additionally, the ability to generate, process, analyze, and present meaningful data, and to develop and apply artificial intelligence (AI) and related algorithmic tools and strategies for informed, optimized, and contextually relevant decision-making."
    },
    {
        "dq_no": "DQ16",
        "dqc_no": "DQC16",
        "full_name": "Intellectual Property Rights Management",
        "definition": "The ability to understand and manage intellectual property rights (e.g., copyrights, trademarks, and patents) when using and creating content and technology."
    },
    {
        "dq_no": "DQ17",
        "dqc_no": "DQC17",
        "full_name": "Digital Changemaker Identity",
        "definition": "The ability to identify and develop oneself as a competent and influential changemaker in the digital economy."
    },
    {
        "dq_no": "DQ18",
        "dqc_no": "DQC18",
        "full_name": "Commercial and Community Use of Technology",
        "definition": "The ability to engage in commercial, civic, and/or political participation for the well-being and growth of local, national, and global communities using technology."
    },
    {
        "dq_no": "DQ19",
        "dqc_no": "DQC19",
        "full_name": "Contract Cyber-Risk Management",
        "definition": "The ability to identify, mitigate, and manage contractual, commercial, or community cyber-risks online, including organizational attempts (e.g., embedded marketing, online propaganda, and gambling) to exploit individuals financially or through ideological persuasion."
    },
    {
        "dq_no": "DQ20",
        "dqc_no": "DQC20",
        "full_name": "Organizational Cyber Security Management",
        "definition": "The ability to recognize, plan, and implement organizational cybersecurity defenses."
    },
    {
        "dq_no": "DQ21",
        "dqc_no": "DQC21",
        "full_name": "Digital Leadership",
        "definition": "The ability to identify and realize opportunities for growth and value through the effective, efficient, and acceptable use of digital technologies."
    },
    {
        "dq_no": "DQ22",
        "dqc_no": "DQC22",
        "full_name": "Public and Mass Communication",
        "definition": "The ability to communicate effectively with an online audience to exchange messages, ideas, and opinions that reflect broader business or societal discourses."
    },
    {
        "dq_no": "DQ23",
        "dqc_no": "DQC23",
        "full_name": "Emerging Technology and Innovation Literacy",
        "definition": "The ability to identify, use, and create emerging technology and acquire innovative competencies to enhance professional life and contribute to the global economy."
    },
    {
        "dq_no": "DQ24",
        "dqc_no": "DQC24",
        "full_name": "Participatory Rights Management",
        "definition": "The ability to understand and exercise one’s powers and rights to online participation (e.g., rights to personal data protection, freedom of expression, or the right to be forgotten) while respecting the rights of other online users."
    },
    {
        "dq_no": "DQ25",
        "dqc_no": "DQC25",
        "full_name": "Digital User Identity",
        "definition": "The ability to have a foundational understanding of one's identity as a digital user, including basic internet and digital media literacy."
    },
    {
        "dq_no": "DQ26",
        "dqc_no": "DQC26",
        "full_name": "Active Use of Technology",
        "definition": "The ability to actively use various types of digital media and technology effectively."
    },
    {
        "dq_no": "DQ27",
        "dqc_no": "DQC27",
        "full_name": "Content Cyber-Risk Management",
        "definition": "The ability to identify, mitigate, and manage risky content (e.g., harmful user-generated content, racist/hateful content, image-based abuse)."
    },
    {
        "dq_no": "DQ28",
        "dqc_no": "DQC28",
        "full_name": "Personal Device Security Management",
        "definition": "The ability to efficiently use strategies to protect personal digital devices."
    },
    {
        "dq_no": "DQ29",
        "dqc_no": "DQC29",
        "full_name": "Emotional Awareness",
        "definition": "The ability to critically recognize, evaluate, and express one’s emotions in digital environments."
    },
    {
        "dq_no": "DQ30",
        "dqc_no": "DQC30",
        "full_name": "Online Communication",
        "definition": "The ability to effectively use technology to communicate with others online."
    },
    {
        "dq_no": "DQ31",
        "dqc_no": "DQC31",
        "full_name": "ICT Literacy",
        "definition": "The ability to efficiently use the basic functions of information and communication technologies (such as word/image/data processing productivity software, internet use, and e-mail)."
    },
    {
        "dq_no": "DQ32",
        "dqc_no": "DQC32",
        "full_name": "Digital Inclusion Rights",
        "definition": "The ability to understand one’s rights to have quality digital access across all sectors, socio-economic statuses, genders, regions, and other factors that may influence digital equity and inclusion."
    }
]