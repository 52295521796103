import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import {
    RouteWithLayout,
    PrivateRouteWithLayout,
    PreAuthRouteWithLayout
} from './components';

import {
    Main as MainLayout,
    Minimal as MinimalLayout
} from './layouts';

import {
    Login as LoginPage,
    Logout as LogoutPage,
    Register as RegisterPage,

    InitPasswordReset as InitPasswordResetPage,
    PasswordReset as PasswordResetPage,
    ChangePassword as ChangePasswordPage,
    ConfirmEmail as ConfirmEmailPage,
    NotFound as NotFoundPage,
} from './pages';
import SelectPackagesPage from "./pages/Packages/SelectPackages.page";
import SelectPackages from "./pages/Packages/SelectPackages.page";
import ProgramCreation from "./pages/Packages/ProgramCreation.page";
import ProgramSelection from "./pages/Packages/ProgramSelection.page";
import Loader from "./components/Loader/Loader.comp";
import LearningContent from "./pages/Packages/CustomDigitalProgram/LearningContent.comp";
import SuggestedCourse from "./pages/Packages/CustomDigitalProgram/SuggestedCourse.page";
import ProgramName from "./pages/Packages/ProgramName.page";
import AccessProgram from "./pages/Packages/AccessProgram.page";

const Routes = () => {
    return (
        <Switch>
            <Redirect
                exact
                from="/"
                to="/select_packages"
            />
            <PreAuthRouteWithLayout
                component={LoginPage}
                exact
                layout={MinimalLayout}
                path="/login"
            />
            <PreAuthRouteWithLayout
                component={RegisterPage}
                exact
                layout={MinimalLayout}
                path="/register"
            />

            <PreAuthRouteWithLayout
                component={InitPasswordResetPage}
                exact
                layout={MinimalLayout}
                path="/account/recover"
            />
            <PreAuthRouteWithLayout
                component={PasswordResetPage}
                exact
                layout={MinimalLayout}
                no_redirect={true}
                path="/auth/reset/password"
            />

            <PrivateRouteWithLayout
                component={ConfirmEmailPage}
                exact
                layout={MinimalLayout}
                path="/auth/confirm/email"
            />

            <PrivateRouteWithLayout
                component={ChangePasswordPage}
                exact
                layout={MainLayout}
                path="/password/change"
            />
            <PrivateRouteWithLayout
                component={LogoutPage}
                exact
                layout={MainLayout}
                path="/logout"
            />


            {/*########################*/}
            {/*### Powered by DQ-V2 -- ROUTES ###*/}
            {/*########################*/}


            <PrivateRouteWithLayout
                component={SelectPackagesPage}
                exact
                layout={MainLayout}
                path="/select_packages"
            />
            <PrivateRouteWithLayout
                component={ProgramCreation}
                exact
                layout={MainLayout}
                path="/program_creation"
            />
            <PrivateRouteWithLayout
                component={ProgramSelection}
                exact
                layout={MainLayout}
                path="/program_selection"
            />
            <PrivateRouteWithLayout
                component={LearningContent}
                exact
                layout={MainLayout}
                path="/learning_content"
            />
            <PrivateRouteWithLayout
                component={SuggestedCourse}
                exact
                layout={MainLayout}
                path="/suggested_course"
            />
            <PrivateRouteWithLayout
                component={ProgramName}
                exact
                layout={MainLayout}
                path="/program_name"
            />
            <PrivateRouteWithLayout
                component={AccessProgram}
                exact
                layout={MainLayout}
                path="/program_access"
            />

            {/*########################*/}
            {/*### Miscellaneous -- ROUTES ###*/}
            {/*########################*/}

            <RouteWithLayout
                component={NotFoundPage}
                exact={false}
                layout={MinimalLayout}
                path="/"
            />

        </Switch>
    );
};

export default Routes;
