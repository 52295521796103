import React, { useState } from 'react';
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import LoaderWithBackDrop from "../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import { useHistory } from "react-router-dom";
import {LOCAL_STORAGE_KEYS} from "../../utils/constants";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    cardContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'stretch',
        marginTop: '40px',
    },
    card: {
        borderRadius: '10px',
        backgroundColor: "#fff",
        border: '1px solid #ee6628',
        position: 'relative',
        padding:'0px !important',
        flex: 1,
        margin: '0 13px',
        cursor: "pointer",
        height: '450px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
        '&:hover': {
            transform: "scale(1.05)",
            boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
        },
        '&:hover $cardTitle': {
            backgroundColor: "#0097a7",
            color: '#fff',
            border: '2px solid #0097a7',
        },
        '&:hover $cardTitleText': {
            color: '#fff',
        },
    },
    cardTitle: {
        height: '170px',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        backgroundColor: "#fff",
        padding: theme.spacing(2),
        transition: "background-color 0.2s ease-in-out, border 0.2s ease-in-out, color 0.2s ease-in-out",
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cardTitleText: {
        fontWeight: "400",
        color: "#ee6628",
        fontSize: '1.8rem',
    },
    cardDescription: {
        minHeight: '275px',
        backgroundColor: '#F3F1EC',
        padding: theme.spacing(2),
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '10px',
        flexGrow: 1,
        borderTop: 'none',
        textAlign: 'center',
        overflow: 'hidden',
    },
    disabledCard: {
        cursor: 'default',
        '& $cardTitle': {
            border: '1px solid #d8d8d8',
            backgroundColor: "#d8d8d8",
            color: '#fff',
        },
        '& $cardTitleText': {
            color: '#fff',
            backgroundColor: "#d8d8d8",
        },
        '&:hover $cardTitle': {
            backgroundColor: "#d8d8d8",
            borderColor:"#d8d8d8",
        },
        '& $cardDescription': {
            backgroundColor: '#d8d8d8',
            color: '#fff',
        },
        '&:hover': {
            transform: "none",
            boxShadow: "none",
        },
    },
}));

const SelectPackages = (theme_store_demo_inf) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const handleNavigate = (path) => {
        history.push(path);
    };

    const navigateToProgValidation = () => {
        const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_user));
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        const refresh_token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.refresh_token));
        const data = {
            user,
            token,
            refresh_token
        }
        const base64Data = btoa(JSON.stringify(data));
        console.log('mna:base64Data: ', base64Data);
        const url = `${process.env.REACT_APP_SYNC_SESSION_URL}?data=${base64Data}`;

        window.open(url, '_blank');
    }

    return (
        <>
            <Container className={classes.container} maxWidth="lg">
                <Typography color="inherit" variant="h4" style={{ textAlign: "center", marginTop: '40px' }}>
                    Select a Package!
                </Typography>
                <Grid container className={classes.cardContainer} spacing={5} justify="center" alignItems="center">
                    <Grid item xs={3} md={3} className={classes.card} align="center" onClick={navigateToProgValidation}>
                        <div className={classes.cardTitle}>
                            <Typography variant="h5" className={classes.cardTitleText}>
                                Program <br/> Validation
                            </Typography>
                        </div>
                        <div className={classes.cardDescription}>
                            <Typography style={{ fontSize: "18px" }}>
                                For program owners looking to check their program’s alignment with the IEEE DQ Global Standards (IEEE 3527.1<sup>TM</sup>).
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={3} md={3} className={classes.card} align="center" onClick={() => handleNavigate('/program_creation')}>
                        <div className={classes.cardTitle}>
                            <Typography variant="h5" className={classes.cardTitleText}>
                                Program <br/> Creation
                            </Typography>
                        </div>
                        <div className={classes.cardDescription}>
                            <Typography style={{ fontSize: "18px" }}>
                                For program providers and content creators looking to create and/or enhance digital skills programs.
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={3} md={3} className={`${classes.card} ${classes.disabledCard}`} align="center">
                        <div className={classes.cardTitle}>
                            <Typography variant="h5" className={classes.cardTitleText}>
                                Quality <br/> Management
                            </Typography>
                        </div>
                        <div className={classes.cardDescription}>
                            <Typography style={{ fontSize: "18px" }}>
                                For organizations looking to assess the quality of their programs across content, impact, and safety.
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={3} md={3} className={`${classes.card} ${classes.disabledCard}`} align="center">
                        <div className={classes.cardTitle}>
                            <Typography variant="h5" className={classes.cardTitleText}>
                                Ecosystem <br/> Building
                            </Typography>
                        </div>
                        <div className={classes.cardDescription}>
                            <Typography style={{ fontSize: "18px" }}>
                                For national, regional, and global stakeholders aiming to build a digital skills or digital well-being ecosystem by aggregating digital skills interventions and monitoring their impact on national, regional, or global progress.
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
                <LoaderWithBackDrop loading={loading} />
            </Container>
        </>
    );
}

export default SelectPackages;
