import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    layoutA: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
    },
    layoutAHeader: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: '20px',
        marginTop:'-50px',
    },
    layoutAImage: {
        maxWidth: '230px',
        height: 'auto',
    },
    layoutATitle: {
        fontSize: '50px',
        fontWeight: 'bold',
        margin: '10px 0',
    },
    layoutAContent: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        maxWidth: '1200px',
    },
    layoutAColumn: {
        width: '30%',
    },
    layoutASubtitle: {
        fontSize: '18px',
        fontWeight: 'bold',
    },
    layoutB: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
    },
    layoutBLeft: {
        width: '40%',
        textAlign: 'center',
    },
    layoutBImage: {
        maxWidth: '230px',
        height: 'auto',
    },
    layoutBTitle: {
        fontSize: '35px',
        fontWeight: 'bold',
        marginTop: '10px',
    },
    layoutBRight: {
        width: '70%',
        marginTop:'60px',
        display: 'flex',
        flexDirection: 'column',
    },
    layoutBStep: {
        display: 'flex',
        alignItems: 'flex-start',
        marginBottom: '20px',
        marginLeft:'30px',
    },
    layoutBStepIcon: {
        backgroundColor: '#ffffff',
        borderRadius: '50%',
        width: '50px',
        height: '50px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 'bold',
        marginRight: '10px',
    },
    layoutBStepContent: {
        flex: 1,
    },
    layoutBStepTitle: {
        fontSize: '18px',
        fontWeight: 'bold',
    },
    layoutC: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(2),
        borderRadius: theme.shape.borderRadius,
    },
    layoutCImageSection: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginBottom: theme.spacing(2),
    },
    layoutCImage: {
        maxWidth: 'auto',
        height: '350px',
        marginTop:'30px',
        borderRadius: theme.shape.borderRadius,
    },
    layoutCTextSection: {
        width: '100%',
        textAlign: 'center',
    },
    layoutCTitle: {
        fontSize: '35px',
        fontWeight: 'bold',
        marginBottom: theme.spacing(1),
    },
    layoutCContent: {
        width: '100%',
        textAlign: 'left',
    },
    layoutCList: {
        listStyleType: 'disc',
        margin: 0,
    },
    layoutCListItem: {
        marginBottom: theme.spacing(1),
    },
}));

// Layout A: Image and Title on Top with Content in Three Columns
const LayoutA = ({ imageSrc, title, content = [], slideStyles, currentSlide }) => {
    const classes = useStyles();
    const titleStyle = {
        fontSize: slideStyles[currentSlide]?.fontSize?.h1 || 'inherit',
        fontFamily: slideStyles[currentSlide]?.fontFamily?.h1 || 'inherit',
        color: slideStyles[currentSlide]?.color?.h1 || 'inherit',
    };

    const contentStyle = {
        fontSize: slideStyles[currentSlide]?.fontSize?.body || 'inherit',
        fontFamily: slideStyles[currentSlide]?.fontFamily?.body || 'inherit',
        color: slideStyles[currentSlide]?.color?.body || 'inherit',
    };
    return (
        <div className={classes.layoutA}>
            <div className={classes.layoutAHeader}>
                <img src={imageSrc} alt="Slide" className={classes.layoutAImage} />
                <h1 className={classes.layoutATitle} style={titleStyle}>{title}</h1>
            </div>
            <div className={classes.layoutAContent}>
                {content.map((point, index) => (
                    <div key={index} className={classes.layoutAColumn} style={contentStyle}>
                        <div>{point}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};

// Layout B: Image and Title on Left, Steps on Right
const LayoutB = ({ imageSrc, title, content = [], slideStyles, currentSlide }) => {
    const classes = useStyles();
    const titleStyle = {
        fontSize: slideStyles[currentSlide]?.fontSize?.h1 || 'inherit',
        fontFamily: slideStyles[currentSlide]?.fontFamily?.h1 || 'inherit',
        color: slideStyles[currentSlide]?.color?.h1 || 'inherit',
    };

    const contentStyle = {
        fontSize: slideStyles[currentSlide]?.fontSize?.body || 'inherit',
        fontFamily: slideStyles[currentSlide]?.fontFamily?.body || 'inherit',
        color: slideStyles[currentSlide]?.color?.body || 'inherit',
    };
    return (
        <div className={classes.layoutB}>
            <div className={classes.layoutBLeft}>
                <img src={imageSrc} alt="Slide" className={classes.layoutBImage} />
                <h2 className={classes.layoutBTitle} style={titleStyle}>{title}</h2>
            </div>
            <div className={classes.layoutBRight}>
                {content.map((item, index) => (
                    <div key={index} className={classes.layoutBStep}>
                        <div className={classes.layoutBStepIcon}>{index + 1}</div>
                        <div className={classes.layoutBStepContent}>
                            <div style={contentStyle}>{item}</div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

// Layout C: Image on Left with Title on top and Content on right side
const LayoutC = ({ imageSrc, title, content = [], slideStyles, currentSlide }) => {
    const classes = useStyles();
    const titleStyle = {
        fontSize: slideStyles[currentSlide]?.fontSize?.h1 || 'inherit',
        fontFamily: slideStyles[currentSlide]?.fontFamily?.h1 || 'inherit',
        color: slideStyles[currentSlide]?.color?.h1 || 'inherit',
    };

    const contentStyle = {
        fontSize: slideStyles[currentSlide]?.fontSize?.body || 'inherit',
        fontFamily: slideStyles[currentSlide]?.fontFamily?.body || 'inherit',
        color: slideStyles[currentSlide]?.color?.body || 'inherit',
    };
    return (
        <div className={classes.layoutC}>
            <h2 className={classes.layoutCTitle} style={titleStyle}>{title}</h2>
            <div style={{display:'flex', flexDirection:'row', marginTop:'50px'}}>
                <div className={classes.layoutCImageSection}>
                    <img src={imageSrc} alt="Slide" className={classes.layoutCImage}/>
                </div>
                <div className={classes.layoutCTextSection}>
                    <div className={classes.layoutCContent}>
                        <ul className={classes.layoutCList}>
                            {content.map((point, index) => (
                                <li key={index} className={classes.layoutCListItem} style={contentStyle}>{point}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};
export {LayoutA, LayoutB, LayoutC};
