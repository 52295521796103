import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import { useHistory } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
    container: {
        textAlign: 'center',
        padding: theme.spacing(4),
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        minHeight:'80vh',
        justifyContent:'center'
    },
    title: {
        marginBottom: '20px',
        fontWeight: 'bold',
        color:'#ee6628',
    },
    optionsContainer: {
        display: 'flex',
        justifyContent: 'center',
        gap: theme.spacing(4),
        width: '800px',
        marginBottom: theme.spacing(3),
    },
    option: {
        padding: theme.spacing(2),
        borderRadius: '15px',
        cursor: 'pointer',
        fontSize: '20px',
        backgroundColor: '#0097a7',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: '#fff',
        '&:hover': {
            borderColor: '#f0f0f0',
        },
        border: '2px solid transparent',
        transition: 'background-color 0.3s, border-color 0.3s',
    },
    optionSelected: {
        backgroundColor: '#06215B',
        borderColor: '#06215B',
    },
    optionWithEdit: {
        display: 'flex',
        alignItems: 'center',
    },
    editIcon: {
        marginLeft: theme.spacing(1),
    },
    button: {
        marginTop: theme.spacing(2),
        color:'#ee6628',
        border:'2px solid #ee6628',
        backgroundColor:'#fff',
        padding:'10px',
        '&:hover': {
            backgroundColor:'#fff',
        },
        '&:disabled': {
            borderColor: '#D8D8D8',
            backgroundColor: '#D8D8D8',
            color: theme.palette.grey[600],
        },
    },
    dialogPaper: {
        minWidth: 600,
        maxWidth: '80%',
    },
    textField: {
        width: '100%',
        fontSize: '1.2rem',
        padding: theme.spacing(1),
    },
}));

const ProgramName = () => {
    const classes = useStyles();
    const history = useHistory();
    const [open, setOpen] = useState(false);
    const [customOptions, setCustomOptions] = useState([]);
    const [customName, setCustomName] = useState('');
    const [selectedOption, setSelectedOption] = useState(null);
    const [editIndex, setEditIndex] = useState(null);
    const [defaultOptions] = useState([
        'DigiCitizen for Success',
        'Digital Leaders of Tomorrow',
        'Be Reputable',
        'Digital Citizenship 101',
        'Online You Vs Offline You',
        'Future-Ready Citizen',
    ]);

    const handleClickOpen = () => {
        setCustomName('');
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSave = () => {
        if (editIndex !== null) {
            const updatedOptions = [...customOptions];
            updatedOptions[editIndex] = customName;
            setCustomOptions(updatedOptions);
            setEditIndex(null);
        } else {
            if (customOptions.length === 0) {
                setCustomOptions([customName]);
            }
        }
        setOpen(false);
    };


    const handleEdit = (index) => {
        setCustomName(customOptions[index]);
        setEditIndex(index);
        setOpen(true);
    };

    const handleOptionSelect = (index, type) => {
        setSelectedOption({ index, type });
        history.push('/program_access');
    };

    return (
        <div className={classes.container}>
            <Typography variant="h5" className={classes.title}>
                Let’s select a name for your program or you can create one for yourself:
            </Typography>
            <div className={classes.optionsContainer}>
                <Grid container spacing={3}>
                    {[...defaultOptions, ...customOptions].map((option, index) => {
                        const isCustom = index >= defaultOptions.length;
                        const isSelected = selectedOption?.index === index && selectedOption?.type === (isCustom ? 'custom' : 'default');
                        return (
                            <Grid item xs={6} sm={6} md={6} key={index}>
                                <div
                                    className={`${classes.option} ${isSelected ? classes.optionSelected : ''}`}
                                    onClick={() => handleOptionSelect(index, isCustom ? 'custom' : 'default')}
                                >
                                    <span>{option}</span>
                                    {isCustom && (
                                        <IconButton
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                handleEdit(index - defaultOptions.length);
                                            }}
                                            style={{color: '#fff'}}
                                        >
                                            <EditIcon/>
                                        </IconButton>
                                    )}
                                </div>
                            </Grid>
                        );
                    })}
                </Grid>
            </div>
            <Button
                variant="contained"
                color="primary"
                onClick={handleClickOpen}
                className={classes.button}
                disabled={customOptions.length > 0}
            >
                I want to rename it myself
            </Button>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title"
                    PaperProps={{className: classes.dialogPaper}}>
                <DialogTitle id="form-dialog-title">Rename Program</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Program Name"
                        type="text"
                        fullWidth
                        className={classes.textField}
                        value={customName}
                        onChange={(e) => setCustomName(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSave} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ProgramName;
