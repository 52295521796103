
export const THEME_LAYOUT = {
    LAYOUT_A: 'layout_a',       //Image and Title on Top with Content in Three Columns
    LAYOUT_B: 'layout_b',       //Image and Title on Left, Steps on Right
    LAYOUT_C: 'layout_c',       //Image on Left with Title on top and Content on right side
}

export const THEME_HEADING_COLOR = {
    COLOR_A: 'red',
    COLOR_B: 'black',
}

export const THEME_HEADING_FONT = {
    FONT_A: 'arial',
    FONT_B: 'times new roman',
}

export const THEME_HEADING_SIZE = {
    SMALL: 'small', // 20px
    MEDIUM: 'medium', //24px
    LARGE: 'large', //28px
}

export const THEME_SUB_HEADING_COLOR = {
    COLOR_A: 'red',
    COLOR_B: 'black',
}

export const THEME_SUB_HEADING_FONT = {
    FONT_A: 'arial',
    FONT_B: 'times new roman',
}

export const THEME_SUB_HEADING_SIZE = {
    SMALL: 'small', //16px
    MEDIUM: 'medium', //18px
    LARGE: 'large', //20px
}

export const THEME_PARAGRAPH_COLOR = {
    COLOR_A: 'red',
    COLOR_B: 'black',
}

export const THEME_PARAGRAPH_FONT = {
    FONT_A: 'arial',
    FONT_B: 'times new roman',
}

export const THEME_PARAGRAPH_SIZE = {
    SMALL: 'small', //12px
    MEDIUM: 'medium', //14px
    LARGE: 'large', //16px
}

export const THEME_BACKGROUND_COLOR = {
    COLOR_A: 'white',
    COLOR_B: 'blue',
    COLOR_C: 'orange',
}

export const gen_theme_store_construct = (
    {
        layout,
        heading_color,
        heading_font,
        heading_size,
        sub_heading_color,
        sub_heading_font,
        sub_heading_size,
        paragraph_color,
        paragraph_font,
        paragraph_size,
        background_color,
        image_url
    }
) => {
    return {
        layout: layout,
        background_color: background_color,
        heading: {
            color: heading_color,
            font: heading_font,
            size: heading_size,
        },
        sub_heading: {
            color: sub_heading_color,
            font: sub_heading_font,
            size: sub_heading_size,
        },
        paragraph: {
            color: paragraph_color,
            font: paragraph_font,
            size: paragraph_size,
        },
        image_url
    }
}


