import React, { useState } from'react'
import { Card, CardContent, Typography, Box, Divider } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
    Title: {
        width: '100%',
        padding: '4px',
        borderRadius:'10px',
        marginBottom: '16px',
        textAlign: 'center',
        backgroundColor: '#F17632',
        color: '#fff',
    },
    objectiveCard: {
        marginTop: theme.spacing(3),
        boxShadow:'none !important',
        borderRadius:'0px !important',
    },
    objectiveContent: {
        padding: theme.spacing(2),
    },
    sectionTitle: {
        color: '#ef6528',
        marginBottom: '10px !important',
    },
    sectionSubtitle: {
        color: '#000',
        fontWeight:'bold',
        marginBottom: theme.spacing(2),
    },
    divider: {
        margin: theme.spacing(1, 0),
    },
    list: {
        marginTop:'10px',
        fontSize:'17px',
        paddingLeft: theme.spacing(4),
        '& li': {
            marginBottom: theme.spacing(1),
        },
    },
    questionCard: {
        padding: '20px',
        display: 'flex',
        marginBottom: theme.spacing(3),
        boxShadow:'none !important',
        borderRadius:'10px !important',
    },
    questionLeft: {
        border: '2px dotted #0097a7',
        borderRadius:'30px',
        paddingTop:'50px',
        position: 'relative',
        marginRight:'20px',
        flex: 2,
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    questionRight: {
        flex: 1,
        margin:'23px',
        padding: theme.spacing(2),
        backgroundColor: '#f5f5f5',
    },
    questionNumber: {
        color: '#ef6528',
        fontWeight: 'bold',
        marginBottom: theme.spacing(1),
    },
    questionText: {
        color: '#4294A5',
        marginBottom: theme.spacing(1),
    },
    option: {
        color: '#000',
        marginBottom: theme.spacing(0.5),
    },
    questionObjective: {
        color: '#ef6528',
        marginTop: '14px !important',
        padding:'20px'
    },
    tag:{
        position: 'absolute',
        top: '-2px',
        left: '-2px',
        backgroundColor: '#06215B',
        color: '#fff',
        padding: '5px 10px',
        borderRadius: '5px',
        fontSize: '18px',
    },
}));
const ProgramPreAssessment = ({ selectedLesson, assignmentData,setAssignmentData  }) => {
    const classes = useStyles();
    if (!assignmentData || assignmentData.length === 0) {
        console.log("no assignment data");
        return null;
    }

    const handleSwitch = (qIndex) => {
        if (qIndex < assignmentData.length - 1) {
            const updatedQuestions = [...assignmentData];
            [updatedQuestions[qIndex], updatedQuestions[qIndex + 1]] =
                [updatedQuestions[qIndex + 1], updatedQuestions[qIndex]];
            setAssignmentData(updatedQuestions);
        }
    };

    const handleDelete = (qIndex) => {
        const updatedQuestions = assignmentData.filter((_, index) => index !== qIndex);
        setAssignmentData(updatedQuestions);
    };

    return (
        <Box style={{width:'100%'}}>
            <Typography variant="h5" className={classes.Title}>
                {selectedLesson.verbose_name}
            </Typography>

            {/* 1st Card: Displaying Title and Content */}
            <Card className={classes.objectiveCard}>
                <CardContent className={classes.objectiveContent}>
                    <Typography variant="h6" className={classes.sectionTitle}>
                        Learning Objectives:
                    </Typography>
                    <Typography variant="h7" className={classes.sectionSubtitle}>
                        The following assessment assesses a user's:
                    </Typography>
                        <ul className={classes.list}>
                            {selectedLesson.learning_goals?.map((goal, goalIndex) => (
                                <li key={goalIndex}>
                                    <Typography variant="body2" style={{fontSize:'17px'}}>{goal.description}</Typography>
                                </li>
                            ))}
                        </ul>
                </CardContent>
            </Card>

            {assignmentData.map((question, qIndex) => (
                <Card key={qIndex} className={classes.questionCard}>
                    <Box className={classes.questionLeft}>
                        <Typography variant="h6" style={{ color: '#06215B', marginBottom: '10px' }}>
                            {question.question}
                        </Typography>
                        <ul className={classes.list}>
                            {question.options.map((option, optIndex) => (
                                <li key={optIndex} className={classes.option} style={{ color: '#4294A5', fontWeight:'bold' }}>
                                    {option}
                                </li>
                            ))}
                        </ul>
                        <Box className={classes.tag}>
                            {`Question ${qIndex + 1}`}
                        </Box>
                    </Box>

                    <Box style={{ display: 'flex', alignItems: 'center',width:'38%' }}>
                        <Box style={{ display: 'flex', flexDirection: 'column', marginRight: '20px' }}>
                            <Button
                                variant="outlined"
                                style={{ marginBottom: '10px', borderColor: '#F17632', color: '#F17632' }}
                                onClick={() => handleSwitch(qIndex)}
                            >
                                Switch
                            </Button>
                            <Button
                                variant="outlined"
                                style={{ borderColor: '#F17632', color: '#F17632' }}
                                onClick={() => handleDelete(qIndex)}
                            >
                                Delete
                            </Button>
                        </Box>

                        <Typography variant="body1" style={{ color: '#333' }}>
                            {question.goal}
                        </Typography>
                    </Box>
                </Card>
            ))}
        </Box>
    );
};

export default ProgramPreAssessment;